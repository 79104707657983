import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroup} from "@angular/forms";
import {Activity, OldboysService} from "../oldboys.service";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {UserService} from "../user.service";


@Component({
  selector: 'app-unlink-dialog',
  templateUrl: './unlink-dialog.component.html',
  styleUrls: ['./unlink-dialog.component.css']
})
export class UnlinkDialogComponent implements OnInit {
  loading = false;

  constructor(public dialogRef: MatDialogRef<UnlinkDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oldBoysService: OldboysService,
    private userService: UserService) {

    }

  ngOnInit() {
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    const result = this.data.result;
    this.loading = true;
    console.log('unlink result data')
    console.log(result);
    this.oldBoysService.unlinkOldboy(result.id,result).subscribe(response => {
      if (response.success === true){
        console.log('account unlinked');
        this.dialogRef.close(true);
      } else {
        console.log(response);
        this.loading = false;
      }
    });
  }
}
