import { Component, ElementRef, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatPaginator, MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material';
import { PublicService, Student } from "../public.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { map, startWith } from 'rxjs/operators';
import { MembershipRequestService } from '../membership-request.service';
import { OldboysService } from '../oldboys.service';
import { UserService } from '../user.service';
import {SubmitInfoDialogComponent} from '../submit-info-dialog/submit-info-dialog.component';

@Component({
  selector: 'app-years',
  templateUrl: './years.component.html',
  styleUrls: ['./years.component.css']
})
export class YearsComponent implements OnInit {
  loading = false;
  search = new FormControl();
  filteredOptions: Observable<string[]>;
  displayedColumns: string[] = [
    'name',
    'years',
    'validated',
    'wbhsOldBoy',
    'deceased',
    'emailOnFile',
    //'contact',
    'fill',];
  years = [];
  subTitle = '';
  isUserAwaitingClaim = false;
  dataSource = new MatTableDataSource<Student>();

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  @ViewChild('contactDialogRef') contactDialogRef: TemplateRef<any>;
  @ViewChild('updateDialogRef') updateDialogRef: TemplateRef<any>;

  constructor(private publicService: PublicService,
    public snackBar: MatSnackBar,
    private dialog: MatDialog,
    private contactDialog: MatDialog,
    private membershipRequestService: MembershipRequestService,
    private oldBoyService: OldboysService,
    private userService: UserService) { }

  ngOnInit() {
    this.publicService.getYears().subscribe(years => {
      this.years = years;
      this.filteredOptions = this.search.valueChanges.pipe(
        startWith(''),
        map(value => this.filterYears(value))
      );
      const currUser = this.userService.getCurrentUser();
      if (currUser && !this.userService.isContributorOrAdmin()) {
        this.userService.retrieveCurrentUser().subscribe(cu => {
          if (!cu.prospectId) {
            this.membershipRequestService.getUserActiveClaimRequests().subscribe(cr => {
              this.isUserAwaitingClaim = this.membershipRequestService.isUserAwaitingClaim(cr);
              if (this.isUserAwaitingClaim) {
                this.subTitle = "Your request for claiming your school record is in progress."
              } else {
                let startYear = (currUser.data && currUser.data.fromYear ? currUser.data.fromYear : '');
                if (this.years.includes(startYear)) {
                  this.search.setValue(startYear);
                  this.subTitle = `We are displaying all school records for you start year (${startYear}). If you can find your record in the results please claim it, else add a new one by clicking the blue button in the upper right hand corner.`
                  this.onRefresh();
                } else {
                  this.subTitle = `There are no records from the school for your starting year (${startYear}). Please add a new record by clicking the blue button in the upper right hand corner.`
                }
              }
            });
          }
        });
      }
    });
  }

  private async checkIfUserAwaitingClaim() {
    let cr = await this.membershipRequestService.getUserActiveClaimRequests();
    this.isUserAwaitingClaim = this.membershipRequestService.isUserAwaitingClaim(cr);
  }

  private filterYears(value: string): string[] {
    if (value) {
      const filterValue = value.toString();
      return this.years.filter(option => option && option.toString().indexOf(filterValue) === 0);
    } else {
      return this.years;
    }
  }

  onYearChange(event) {
    this.publicService.getYearStudents(event.value).subscribe(students => {
      this.dataSource.data = students;
    });
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.loading = true;
      this.publicService.getYearStudents(this.search.value).subscribe(students => {
        this.dataSource.data = students;
        this.loading = false;
      });
      this.searchInput.nativeElement.blur();
    }
  }

  onRefresh() {
    this.loading = true;
    this.publicService.getYearStudents(this.search.value).subscribe(students => {
      this.dataSource.data = students;
      this.loading = false;
    });
    this.searchInput.nativeElement.blur();
  }

  hitTest() {
    return this.years.filter(option => option && this.search.value && (option.toString() === this.search.value.toString())).length > 0;
  }

  canProvideUpdate(currRow) {
    const currUser = this.userService.getCurrentUser();
    return (currUser && currUser.prospectId && currUser.prospectId !== currRow.id);
  }

  addNewRecord() {
    let data = {};
    const currUser = this.userService.getCurrentUser();
    if (!this.userService.isContributorOrAdmin() && !currUser.prospectId) {
      data = {
        emailaddress: currUser.email,
        firstname : currUser.data.firstName,
        lastname : currUser.data.lastName,
        birthdate : currUser.data.dateOfBirth,
        firstyear : currUser.data.fromYear,
        lastyear : currUser.data.toYear,
        country: currUser.data.country,
        town_city: currUser.data.town,
        housename: currUser.data.house,
        suburb: currUser.data.suburb,
        postalcode: currUser.data.postcode,
        province_state: currUser.data.province,
        hometelephone: currUser.data.homePhone,
        worktelephone: currUser.data.workPhone,
        mobiletelephone: currUser.data.mobilePhone,
        address1: currUser.data.streetAddress,
      };
    }
    const dialogRef = this.dialog.open(SubmitInfoDialogComponent, {
      maxHeight: '100vh',
      height: '100%',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.membershipRequestService.createInformationSubmissionRequest(result)
          .subscribe((res) => {
            this.snackBar.open(res.msg, 'Dismiss', {
              duration: 100000,
            });
          });
      }
    });
  }

  provideUpdate(row) {
    this.dialog.open(this.updateDialogRef).afterClosed()
      .subscribe(result => {
        if (result) {
          const dialogOldBoy = this.dialog.open(SubmitInfoDialogComponent, {
            maxHeight: '100vh',
            height: '100%',
            data: row
          });

          dialogOldBoy.afterClosed().subscribe(result1 => {
            if (result1) {
              this.membershipRequestService.createInformationSubmissionRequest(result1)
                .subscribe((res) => {
                  this.snackBar.open(res.msg, 'Dismiss', {
                    duration: 100000,
                  });
                });
            }
          });
        }
      });
  }

  contactMember(row) {
    this.contactDialog.open(this.contactDialogRef, {
      data: {
        prospectName : row.firstname + ' ' + row.lastname
      }
    }).afterClosed()
      .subscribe(result => {
        if (result) {
          this.membershipRequestService.createContactRequest(row)
            .subscribe((res) => {
              this.snackBar.open(res.msg, 'Dismiss', {
                duration: 100000,
              });
            });
        }
      });
  }

  canRequestContact(currRow) {
    const currUser = this.userService.getCurrentUser();
    return (currUser && currUser.prospectId && currUser.prospectId !== currRow.id);
  }

  canAddNewRecord() {
    let currUser = this.userService.getCurrentUser();
    if (currUser) {
      if (this.userService.isContributorOrAdmin() || currUser.prospectId) {
        return true;
      } else {
        if (this.isUserAwaitingClaim) {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return false;
    }
  }
}
