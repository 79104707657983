import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

@Component({
  selector: 'app-note-dialog',
  templateUrl: './note-dialog.component.html',
  styleUrls: ['./note-dialog.component.css']
})
export class NoteDialogComponent implements OnInit {

  noteForm = new FormGroup({
    noteText: new FormControl()
  })

  constructor(
    public dialogRef: MatDialogRef<NoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public snackBar: MatSnackBar,
    ) {
    if (data.noteText){
      this.noteForm.patchValue(data);
    }
    console.log(this)
  }

  ngOnInit() {

  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    const result = this.noteForm.value;
    console.log(result)
    if(result.noteText == ''){
      this.snackBar.open('You have submitted an empty note.\nIf you want to delete this note, please cancel and delete it from the table.', 'Ok', {
        duration: 100000,
      });
    } else if (result.noteText.length > 255) {
      this.snackBar.open('This note is too long. The max note length is 255 characters.', 'Ok', {
        duration: 100000,
      });
    } else {
      this.dialogRef.close(result);
    }
  }


}