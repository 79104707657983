import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-payment-project-edit-dialog',
  templateUrl: './payment-project-edit-dialog.component.html',
  styleUrls: ['./payment-project-edit-dialog.component.css']
})
export class PaymentProjectEditDialogComponent implements OnInit {
  id = null;
  oldName = null;
  paymentProject = new FormGroup({
    name: new FormControl(),
    allowSendReceipt: new FormControl(),
    active: new FormControl()
  });

  constructor(public dialogRef: MatDialogRef<PaymentProjectEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.project) {
      this.id = data.project.id;
      this.paymentProject.patchValue(data.project);
      this.oldName = data.project.name;
    }
  }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitDialog(remove): void {
    if (remove) {
      const result = this.paymentProject.value;
      if (this.id) {
        result.id = this.id;
      }
      result.deleted = true;
      this.dialogRef.close(result);
    } else {
      const result = this.paymentProject.value;
      if (this.id) {
        result.id = this.id;
      }
      this.dialogRef.close(result);
    }

    
  }

  isValid() {
    return this.paymentProject.get('name').value;
  }
}
