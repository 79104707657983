import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import { Service } from './service';
import { catchError, tap } from 'rxjs/operators';
import { error } from 'protractor';

@Injectable({
  providedIn: 'root'
})
export class MembershipRequestService extends Service {
  endpoint = this.getEndpoint('requests');

  constructor(
    private http: HttpClient) {
    super();
   }

  public getRequests(type, status): Observable<MembershipRequest[]> {
    let params = new HttpParams();
    if (status !== 'All') {
      if (status === 'New') {
        params = params.set('status', 'NEW');
      } else if (status === 'Approved') {
        params = params.set('status', 'APPROVED');
      } else if (status === 'Awaiting Input') {
        params = params.set('status', 'AWAITING_INPUT');
      } else if (status === 'Rejected') {
        params = params.set('status', 'REJECTED');
      }
    }
    if (type !== 'All') {
      if (type === 'Record Claim') {
        params = params.set('type', 'IDENTITY_CLAIM');
      } else if (type === 'Contact Request') {
        params = params.set('type', 'CONTACT_REQUEST');
      } else if (type === 'Information Submission') {
        params = params.set('type', 'INFORMATION_SUBMISSION');
      }
    }
    return this.http.get(this.endpoint, { params: params }).pipe(
      catchError(this.handleError('requests', [])));
  }

  public createContactRequest(recordData: any) {
    const body = {
      data: recordData,
    };

    return this.http.post(this.endpoint + '/contact', body).pipe(
      catchError(this.handleError('contact', [])));
  }

  public createIdentityClaimRequest(data: any) {
    return this.http.post(this.endpoint + '/identity', data).pipe(
      catchError(this.handleError('identity', [])));
  }

  public updateRequest(request: MembershipRequest) {
    return this.http.put(this.endpoint + `/${request.id}`, request).pipe(
      catchError(this.handleError('update', [])));
  }

  public createInformationSubmissionRequest(data: any) {
    return this.http.post(this.endpoint + '/submission', data).pipe(
      catchError(this.handleError('submission', [])));
  }

  public getUserActiveClaimRequests() {
    return this.http.get(this.endpoint + '/claiming').pipe(
      catchError(this.handleError('claiming', [])));
  }

  public isUserAwaitingSubmission(userRequests): boolean {
    const reqs = userRequests.filter(r => r.type === 'INFORMATION_SUBMISSION' && r.status === 'NEW');
    if (reqs.length === 0) {
      return false;
    } else {
      return true;
    }
  }

  public isUserAwaitingClaim(userRequests): boolean {
    const reqs = userRequests.filter(r => r.type === 'IDENTITY_CLAIM' && r.status === 'NEW');
    if (reqs.length === 0) {
      return false;
    } else {
      return true;
    }
  }
}

export interface MembershipRequest {
  id: string;
  type: string;
  status: string;
  fromUser: string;
  createdAt: Date;
  updatedAt: Date;
  data: object;
  notes: string;
}
