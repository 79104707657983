import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatAutocompleteModule } from "@angular/material";
import { MatButtonModule } from "@angular/material";
import { MatCardModule } from "@angular/material";
import { MatChipsModule } from "@angular/material";
import { MatDatepickerModule } from "@angular/material";
import { MatDialogModule } from "@angular/material";
import { MatExpansionModule } from "@angular/material";
import { MatFormFieldModule } from "@angular/material";
import { MatIconModule } from "@angular/material";
import { MatInputModule } from "@angular/material";
import { MatNativeDateModule } from "@angular/material";
import { MatOptionModule } from "@angular/material";
import { MatProgressSpinnerModule } from "@angular/material";
import { MatSelectModule } from "@angular/material";
import { MatSortModule } from "@angular/material";
import { MatTableModule } from "@angular/material";

import { DonationsReportComponent } from './donations-report.component';

@NgModule({
  declarations: [DonationsReportComponent],
  imports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatOptionModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule
  ],
  exports: [DonationsReportComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class DonationsReportModule { }
