import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubscriptionsService } from '../reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionFilterComponent } from '../subscription-filter/subscription-filter.component';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { Membership, OldboysService } from '../oldboys.service';
import { FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { FormatDatePipe } from '../formatDate';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-subscriptions-report',
  templateUrl: './subscriptions-report.component.html',
  styleUrls: ['./subscriptions-report.component.css']
})
export class SubscriptionsReportComponent implements OnInit {
  displayedColumns: string[] = ['name', 'year', 'startDate', 'renewed_to', 'receiptNumber', 'category', 'paymentMethod', 'amount', 'note'];
  dataSource = new MatTableDataSource<Membership>();
  subscriptionCategories: string[] = [];
  paymentMethods: string[] = [];
  name: string;
  payment_method: any;
  paymentFromDate: any;
  paymentToDate: any;
  renewedFromDate: any;
  renewedToDate: any;
  subscription_category: any;
  sortName: string;
  currentDate: string;
  sortDirection: string = 'asc';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table', { read: ElementRef }) table!: ElementRef<HTMLTableElement>;
  loading = false;

  private isFirstRefresh = true;
  filterName = 'None';
  constructor(
    public dialog: MatDialog,
    private user: UserService,
    public snackBar: MatSnackBar,
    private subscriptionsService: SubscriptionsService,
    private oldboysService: OldboysService
  ) {
    this.currentDate = new Date().toDateString()
  }

  ngOnInit() {


    this.loadSubscriptionCategories();
    this.loadPaymentMethods();

    // this.subscriptionsService.getSubscriptions().subscribe(data => {
    //   this.dataSource.data = data;
    // });
    // this.dataSource.sort = this.sort;

    // // Set up the filter predicate
    // this.dataSource.filterPredicate = (data: Membership, filter: string) => {
    //   const filterObject = JSON.parse(filter);
    //   return (!filterObject.category || data.SubscriptionCategory['name'] === filterObject.category) &&
    //          (!filterObject.paymentMethod || data.PaymentMethod['name'] === filterObject.paymentMethod);
    // };

    // Set the default sorting by payment date
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getTableElement(): ElementRef {
    return this.table;
  }

  applyFilters(filters: any): void {
    // Apply the filters
    this.dataSource.filter = JSON.stringify(filters);
  }

  onFilter(): void {
    const dialogRef = this.dialog.open(SubscriptionFilterComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(selectedFilters => {
      if (selectedFilters) {
        // Pass the filters to the SubscriptionsReportComponent
        this.applyFilters(selectedFilters);
      }
    });
  }
  // onExport() {
  //   const dialogRef = this.dialog.open(ExportDialogComponent, {
  //     //width: '400px',
  //     data: { }
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       if (result.target === 'pdf') {
  //         console.log(this.getTableElement().nativeElement)
  //         html2canvas(this.getTableElement().nativeElement).then(canvas => {
  //           var imgWidth = 295;
  //           var imgHeight = canvas.height * imgWidth / canvas.width;
  //           const contentDataURL = canvas.toDataURL('image/png')
  //           let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
  //           const pageWidth = pdf.internal.pageSize.getWidth();
  //           var position = 0;
  //           pdf.addImage(contentDataURL, 'PNG', (pageWidth-imgWidth)/2, position, imgWidth, imgHeight)
  //           pdf.save(result.filename + '.pdf'); // Generated PDF
  //         });
  //       } else {
  //         this.exportTableAsExcelFile(result.filename + '.xlsx');
  //       }
  //     }
  //   });
  // }

  onExport() {
    let username = this.user.getCurrentFirstLastName();
  
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      //width: '400px',
      data: { }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const exportDate = new Date().toLocaleString();       
  
        if (result.target === 'pdf') {
          this.exportTableAsPDFFile(result.filename + '.pdf', username, exportDate);                       
        } else if (result.target === 'xlsx') {
          this.exportTableAsExcelFile(result.filename + '.xlsx', username, exportDate);
        }
      }
    });
  }

  exportTableAsPDFFile(pdfFileName: string, username: string, exportDate: string): void {
    const doc = new jsPDF({
      orientation: 'landscape', 
      unit: 'mm', 
      format: 'a4'
    });

    // Page header to add only on the first page
    const pageWidth = doc.internal.pageSize.getWidth();
    
    // Automatically generate headers from displayedColumns
    const headers = this.displayedColumns.map(column => {
      const title = column
        .replace(/([A-Z])/g, ' $1') // Add space before capital letters (camelCase to title case)
        .replace(/^./, str => str.toUpperCase()); // Capitalize the first letter
      return { title, dataKey: column };
    });

    // Extract data from table rows based on dynamically generated headers
    const dataRows = Array.from(this.table.nativeElement.querySelectorAll('tr:not(.mat-header-row)'));
    const data = dataRows.map(row => {
      const cells = row.querySelectorAll('td');
      const rowData: any = {};
      headers.forEach((header, index) => {
        rowData[header.dataKey] = cells[index] ? cells[index].innerText.trim() : '';
      });
      return rowData;
    });

    // Render the table using autoTable with manual headers
    (doc as any).autoTable({
      head: [headers.map(header => header.title)],
      body: data.map(row => Object.values(row)),
      startY: 35,  // Position below the header
      styles: { fontSize: 8 },
      theme: 'grid',
      didDrawPage: (data) => {
        if (data.pageNumber === 1) {
          // Add title, username, and date only on the first page
          doc.setFontSize(18);
          doc.text('Payment Report', pageWidth / 2, 20, { align: 'center' });
          
          doc.setFontSize(12);
          doc.text(`Username: ${username}`, 10, 30);
          
          const textWidth = doc.getTextWidth(`Date: ${exportDate}`);
          doc.text(`Date: ${exportDate}`, pageWidth - textWidth - 10, 30);
        }
      }
    });

    // Save the PDF with the specified filename
    doc.save(pdfFileName);
  }
  //  exportTableAsExcelFile(excelFileName: string, username: string, exportDate: string): void {
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.getTableElement().nativeElement);
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();

  //     // Add header information at the top of the sheet
  //     XLSX.utils.sheet_add_aoa(ws, [
  //       ['Payment Report'],
  //       [`Username: ${username}`, '', `Date: ${exportDate}`]
  //     ], { origin: 'A1' });

  //     // Merge cells for "Payment Report" header to center it
  //     ws['!merges'] = [
  //       { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }, // Merge cells for Payment Report title
  //       { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // Merge cells for Username
  //       { s: { r: 1, c: 2 }, e: { r: 1, c: 8 } }  // Merge cells for Date
  //     ];

  //     // Adjust column widths
  //     const wscols = [
  //       { wch: 20 }, // Name
  //       { wch: 15 }, // Payment Date
  //       { wch: 15 }, // Renewed Date
  //       { wch: 10 }, // Year
  //       { wch: 20 }, // Receipt Number
  //       { wch: 20 }, // Subscription Type
  //       { wch: 20 }, // Payment Method
  //       { wch: 10 }, // Amount
  //       { wch: 30 }  // Note
  //     ];
  //     ws['!cols'] = wscols;

  //     XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions Data');
  //     XLSX.writeFile(wb, excelFileName);
  //   }
  exportTableAsExcelFile(excelFileName: string, username: string, exportDate: string): void {  
    // Create worksheet from table element
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.getTableElement().nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
  
    // Shift the table data down to make space for the header
    const range = XLSX.utils.decode_range(ws['!ref']!);
    const shiftRows = 3;  // Number of rows to shift  

      for (let R = range.e.r; R >= 0; --R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const newCellRef = XLSX.utils.encode_cell({ r: R + shiftRows, c: C });
        ws[newCellRef] = ws[cellRef];
        delete ws[cellRef];
      }
    }
  
    // Update the range to account for shifted rows
    range.e.r += shiftRows;
    ws['!ref'] = XLSX.utils.encode_range(range);
  
    // Add header information at the top of the sheet
    XLSX.utils.sheet_add_aoa(ws, [
      ['                                                                                                                 Payment Report'],
      [`Username: ${username}`, '', `                                                                                    Date: ${exportDate}`]
    ], { origin: 'A1' });
  
        // Merge cells for "Payment Report" header to center it across the entire column
    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } }, // Merge cells for Payment Report title
      { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } }, // Merge cells for Username
      { s: { r: 1, c: 2 }, e: { r: 1, c: 8 } }  // Merge cells for Date
    ];
  
     // Apply styling to the title cell
    ws['A1'].s = {
      alignment: { horizontal: 'center', vertical: 'center' },
      font: { bold: true, sz: 14 }
    };
    
    // Apply styling to the merged title range
    for (let col = 0; col <= 8; col++) {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) {
        cell.s = {
          alignment: { horizontal: 'center', vertical: 'center' },
          font: { bold: true, sz: 14 }
        };
      }
    }
  
    // Adjust column widths
    const wscols = [
      { wch: 20 }, // Name
      { wch: 10 }, // Year
      { wch: 15 }, // Payment Date
      { wch: 15 }, // Renewed Date
      { wch: 20 }, // Receipt Number
      { wch: 20 }, // Subscription Type
      { wch: 20 }, // Payment Method
      { wch: 10 }, // Amount
      { wch: 30 }  // Note
    ];
    ws['!cols'] = wscols;  
    
    // Treat all cells in the "date" columns as text and adjust dates
    Object.keys(ws).forEach((key) => {
      if (key.startsWith('!')) return;
  
      const cell = ws[key];
      const cellRef = XLSX.utils.decode_cell(key);
  
      // If the column is a date column (e.g., column index 2 or 3), adjust date
      if (cellRef.r >= 4 && (cellRef.c === 2 || cellRef.c === 3)) {
        if (typeof cell.v === 'number') {          
           // Adjust the formatted date based on timezone   
           const formattedDate =   this.adjustDateForTimezone(cell.v);   
           cell.v =  formattedDate;
           cell.t = 's'; 
          
        }
      }
    });
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions Data');
  
    // Write the workbook to a file
    XLSX.writeFile(wb, excelFileName);
  }

  adjustDateForTimezone(serialNumber: number): string {
    // Convert the serial number to a Date object
    const originalDate = new Date(Math.round((serialNumber - 25569) * 86400000));
  
    // Get the local timezone offset in hours
    const timezoneOffset = -originalDate.getTimezoneOffset() / 60;
  
    // Determine the adjustment based on the specific UTC offset rules
    const adjustment = (() => {
      switch (true) {
        case timezoneOffset < 0 && timezoneOffset >= -12:
          return 1; // Add 1 day  
 
        default:
          return 0; // No adjustment
      }
    })();
  
    // Apply the adjustment
    originalDate.setDate(originalDate.getDate() + adjustment);
  
    // Format the adjusted date using FormatDatePipe
    const formatDate = new FormatDatePipe();
    return formatDate.transform(originalDate);
  }
  
  

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }



  setSorting(sortName: string): void {
    this.loading = true;
    // Ensure sort.active is initialized
    const activeSort = this.sort && this.sort.active ? this.sort.active : 'startDate'; // Fallback to an startDate if sort is undefined
    const activeDirection = this.sort && this.sort.direction ? this.sort.direction : 'asc'; // Default direction is 'asc'

    // Determine sort direction based on current state
    const sortDirection = (sortName === activeSort && activeDirection === 'asc') ? 'asc' : 'desc';

    debugger;
    let queryObject = {
      name: this.name || '',
      paymentFromDate: this.paymentFromDate ? `${this.paymentFromDate._i.year}-${this.paymentFromDate._i.month + 1}-${this.paymentFromDate._i.date}` : '',
      paymentToDate: this.paymentToDate ? `${this.paymentToDate._i.year}-${this.paymentToDate._i.month + 1}-${this.paymentToDate._i.date}` : '',
      renewedToDate: this.renewedToDate ? `${this.renewedToDate._i.year}-${this.renewedToDate._i.month + 1}-${this.renewedToDate._i.date}` : '',
      renewedFromDate: this.renewedFromDate ? `${this.renewedFromDate._i.year}-${this.renewedFromDate._i.month + 1}-${this.renewedFromDate._i.date}` : '',
      payment_method: this.payment_method || '',
      subscription_category: this.subscription_category || '',
      sortName: sortName,
      sortDirection: sortDirection
    };

    this.subscriptionsService.Search(queryObject).subscribe((res) => {
      this.dataSource.data = res;
      this.dataSource.sort = this.sort; // Set sorting to MatTableDataSource
      this.loading = false;
    }, error => {
      console.log(error);
    });
  }






  onRefresh() {
    if (this.isFirstRefresh) {
      // Apply sorting only on the first refresh
      this.sort.active = 'startDate';
      this.setSorting('startDate');
      this.isFirstRefresh = false; // Set the flag to false after applying sorting
    }


      // this.searchFirstYear = this.searchYear.value;
      this.loading = true;
      let queryObject = {
        name: this.name ? this.name : '',
        paymentFromDate: this.paymentFromDate ? `${this.paymentFromDate._i.year}-${this.paymentFromDate._i.month + 1}-${this.paymentFromDate._i.date}` : '',
        paymentToDate: this.paymentToDate ? `${this.paymentToDate._i.year}-${this.paymentToDate._i.month + 1}-${this.paymentToDate._i.date}` : '',
        renewedFromDate: this.renewedFromDate ? `${this.renewedFromDate._i.year}-${this.renewedFromDate._i.month + 1}-${this.renewedFromDate._i.date}` : '',
        renewedToDate: this.renewedToDate ? `${this.renewedToDate._i.year}-${this.renewedToDate._i.month + 1}-${this.renewedToDate._i.date}` : '',
        payment_method: this.payment_method || '',
        subscription_category: this.subscription_category || '',
        sortName: this.sort.active,   // Use the active sort column
        sortDirection: this.sort.direction,

      }
      this.subscriptionsService.Search(queryObject).subscribe((res) => {
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.loading = false;

      },
        error => {
          console.log(error);
        });
    
    // this.getData();
  }

  onFilterChanged() {
    // const user = this.userService.getCurrentUser();
    // if (user) {
    //   const data = Object.assign({}, user.data || {});
    //   this.filterNames = Object.keys(this.filters);
    //   data.filters = this.filters;
    //   data.filterName = this.filterName;
    //   this.userService.putUserData(data).subscribe(res => {
    //     this.searchFirstYear = this.searchYear.value;
    //     this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
    //   });
    // }
  }
  onKeydown(event) {
    console.log(event);
    // if (event.key === 'Enter') {
    //   //this.getData();
    //   this.searchInput.nativeElement.blur();
    // }
  }
  onYearKeydown(event) {
    // if (event.key === 'Enter') {
    //   //console.log(this.searchYear.value);
    //   this.searchFirstYear = this.searchYear.value;
    //   this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
    //   this.searchInputYear.nativeElement.blur();
    //   //this.getData();
    // }
  }
  loadSubscriptionCategories() {
    this.oldboysService.getSubscriptionCategories().subscribe(categories => {
      this.subscriptionCategories = categories.filter((data: any) => data.deleted != true);
    });
  }

  loadPaymentMethods() {
    this.oldboysService.getPaymentsMethods().subscribe(methods => {
      this.paymentMethods = methods.filter((data: any) => data.deleted != true);
    });
  }


}
