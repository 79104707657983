import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormGroup, FormControl, Validators} from '@angular/forms';

@Component({
  selector: 'app-create-user-dialog',
  templateUrl: './create-user-dialog.component.html',
  styleUrls: ['./create-user-dialog.component.css']
})
export class CreateUserDialogComponent implements OnInit {

  createUserForm = new FormGroup({
    name: new FormControl(null, [
      Validators.required,
      Validators.minLength(4)]),
    email: new FormControl(null, [
      Validators.required,
      Validators.email]),
    type: new FormControl('MEMBER')
  });

  constructor(public dialogRef: MatDialogRef<CreateUserDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CreateUserDialogComponent) { }

  ngOnInit() {
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    this.dialogRef.close(this.createUserForm.value);
  }
}
