import { AfterViewInit, ElementRef, OnInit } from '@angular/core';
import { MatDialog, MatSnackBar, MatTableDataSource, MatSort } from '@angular/material';
import { FormControl } from "@angular/forms";
import { OldboysService } from "../oldboys.service";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MessageBoxComponent } from "../message-box/message-box.component";
import { OldboyDialogComponent } from "../oldboy-dialog/oldboy-dialog.component";
import { ColumnsDialogComponent } from "../columns-dialog/columns-dialog.component";
import { FilterDialogComponent } from "../filter-dialog/filter-dialog.component";
import { ExportDialogComponent } from "../export-dialog/export-dialog.component";
import { UserService } from "../user.service";
import { map, startWith } from 'rxjs/operators';
import { PublicService } from "../public.service";
var EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
var EXCEL_EXTENSION = '.xlsx';
var OldboysComponent = /** @class */ (function () {
    function OldboysComponent(dialog, snackBar, oldboysService, userService, publicService) {
        this.dialog = dialog;
        this.snackBar = snackBar;
        this.oldboysService = oldboysService;
        this.userService = userService;
        this.publicService = publicService;
        this.filters = { 'None': {} };
        this.filterName = 'None';
        this.filterNames = ['None'];
        this.availableColumns = { id: 'ID',
            validated: 'Validated',
            deceased: 'Deceased',
            deceasedate: 'Decease date',
            alumnimember: 'OBA member',
            label: 'Label',
            capacitycategory: 'Capacity category',
            relationshipcategory: 'Relationship category',
            gender: 'Gender',
            title: 'Title',
            initials: 'Initials',
            firstname: 'First Name',
            middlenames: 'Middle Names',
            lastname: 'Last Name',
            salutation: 'Salutation',
            //maidenname: 'Maiden Name',
            birthdate: 'Birthdate',
            address1: 'Address Line 1',
            address2: 'Address Line 2',
            suburb: 'Suburb',
            town_city: 'Town/City',
            province_state: 'Province/State',
            postal_code: 'Postal Code',
            country: 'Country',
            hometelephone: 'Home Telephone',
            mobiletelephone: 'Mobile Telephone',
            //faxnumber: 'Fax Number',
            emailaddress: 'Email Address',
            emailaddress2: 'Email Address 2',
            employer: 'Employer',
            profession_jobtitle: 'Profession/Job Title',
            //qualificationlevel: 'Qualification Level',
            //careernotes: 'Career Notes',
            //worktelephone: 'Work Telephone',
            //preferredcontactmethod: '1st Contact Method',
            //secondcontactmethod: '2nd Contact Method',
            //workaddress1: 'Work Address 1',
            //workaddress2: 'Work Address 2',
            //worksuburb: 'Work Suburb',
            //worktown_city: 'Work Town/City',
            //workprovince_state: 'Work Province/State',
            //workpostalcode: 'Work Postal Code',
            //workcountry: 'Work Country',
            housename: 'House',
            firstyear: 'First Year',
            lastyear: 'Last Year',
            border: 'Boarder',
            fromyear: 'From Year',
            toyear: 'To Year',
            organisationalnotes: 'Organisational/School Notes',
            //maritalstatus: 'Marital Status',
            //parent_partnertitle: 'Parent or Partner Title',
            //parent_partnerinitials: 'Parent or Partner Initials',
            //parent_partnerfirstname: 'Parent or Partner First Name',
            //parent_partnerlastname: 'Parent or Partner Last Name',
            //partnerinterests: 'Partner Interests',
            //childrensnames: 'Childrens Names',
            //volunteer1id: 'Volunteer 1 ID',
            //volunteer2id: 'Volunteer 2 ID',
            //fundraisingnotes: 'Fundraising Notes',
            //firstclass: 'First Class',
            //lastclass: 'Last Class',
            //updated: 'Updated',
            //loaded: 'Loaded',
            //buddy: 'Buddy',
            createdAt: 'Created at',
            updatedAt: 'Updated at',
            actions: 'Actions',
            //    wbhsOldBoy: 'WBHS Old Boy',
            unsubscribed: 'Unsubscribed',
            isLinked: 'Linked to a Member',
            recordType: 'Record Type' };
        this.displayedColumns = [];
        this.search = '';
        this.searchFirstYear = '';
        this.searchYear = new FormControl();
        this.years = [];
        this.sortby = "";
        this.sortdirection = '';
        /*
        displayedColumns: string[] = [ 'id',
          'validated',
          'deceased',
          'deceasedate',
          'alumnimember',
          'label',
          'capacitycategory',
          'relationshipcategory',
          'gender',
          'title',
          'initials',
          'firstname',
          'middlenames',
          'lastname',
          'salutation',
          'maidenname',
          'birthdate',
          'address1',
          'address2',
          'suburb',
          'town_city',
          'province_state',
          'postal_code',
          'country',
          'hometelephone',
          'mobiletelephone',
          'faxnumber',
          'emailaddress',
          'employer',
          'profession_jobtitle',
          'qualificationlevel',
          'careernotes',
          'worktelephone',
          'preferredcontactmethod',
          'secondcontactmethod',
          'workaddress1',
          'workaddress2',
          'worksuburb',
          'worktown_city',
          'workprovince_state',
          'workpostalcode',
          'workcountry',
          'housename',
          'firstyear',
          'lastyear',
          'border',
          'fromyear',
          'toyear',
          'organisationalnotes',
          'maritalstatus',
          'parent_partnertitle',
          'parent_partnerinitials',
          'parent_partnerfirstname',
          'parent_partnerlastname',
          'partnerinterests',
          'childrensnames',
          'volunteer1id',
          'volunteer2id',
          'fundraisingnotes',
          'firstclass',
          'lastclass',
          'updated',
          'loaded',
          'buddy',
          'createdAt',
          'updatedAt',
          'actions'];
        */
        this.dataSource = new MatTableDataSource();
        this.mask = [/[^A-Za-z0-9]+/];
        this.length = 0;
        this.retrieved = 0;
        this.loading = false;
    }
    Object.defineProperty(OldboysComponent.prototype, "query", {
        get: function () { return this.search; },
        set: function (query) {
            this.search = (query && query.trim()) || '';
            this.userService.setCurrentSearch(this.search);
            //this.getData();
        },
        enumerable: true,
        configurable: true
    });
    OldboysComponent.prototype.ngOnInit = function () {
        var _this = this;
        var user = this.userService.getCurrentUser();
        console.log(user);
        this.filters = (user && user.data && user.data['filters']) || { 'None': {} };
        if (!('None' in this.filters)) {
            this.filters['None'] = {};
        }
        this.filterName = (user && user.data && user.data['filterName']) || 'None';
        this.filterNames = Object.keys(this.filters);
        //localStorage.setItem('oldboys_sortby', this.sortby);
        //localStorage.setItem('oldboys_sortdirection', this.sortdirection);
        var temp_sortby = localStorage.getItem('oldboys_sortby');
        if (temp_sortby) {
            this.sortby = temp_sortby;
        }
        else {
            this.sortby = '';
        }
        var temp_sortdirection = localStorage.getItem('oldboys_sortdirection');
        if (temp_sortdirection && (temp_sortdirection === "asc" || temp_sortdirection === "desc")) {
            this.sortdirection = temp_sortdirection;
        }
        else {
            this.sortdirection = '';
        }
        this.dataSource.sort = this.sort;
        this.publicService.getYears().subscribe(function (years) {
            _this.years = years;
            _this.filteredOptions = _this.searchYear.valueChanges.pipe(startWith(''), map(function (value) { return _this.filterYears(value); }));
            // const search = this.userService.getCurrentSearch();
            // if (search) {
            //   this.search = search;
            // }
            var searchFirstYear = _this.userService.getCurrentSearchFirstYear();
            if (searchFirstYear) {
                _this.searchFirstYear = searchFirstYear;
                _this.searchYear.patchValue(_this.searchFirstYear);
            }
            // if (search || searchFirstYear) {
            //   this.getData();
            // }
            _this.displayedColumns = _this.userService.getCurrentColumns();
        });
    };
    OldboysComponent.prototype.ngAfterViewInit = function () {
        this.dataSource.sort = this.sort;
    };
    OldboysComponent.prototype.isLinked = function (value) {
        if (value && value.recordOwner) {
            return true;
        }
        else {
            return false;
        }
    };
    OldboysComponent.prototype.isEmpty = function (value) {
        return (
        // null or undefined
        (value == null) ||
            // has length and it's zero
            (value.hasOwnProperty('length') && value.length === 0) ||
            // is an Object and has no keys
            (value.constructor === Object && Object.keys(value).length === 0));
    };
    OldboysComponent.prototype.getData = function () {
        var _this = this;
        var sort = this.sort;
        var str = this.search.replace(/\s/g, '');
        var firstYear = this.searchFirstYear;
        this.loading = true;
        this.oldboysService.getOldboysCount(str, firstYear, this.filters[this.filterName]).subscribe(function (count) {
            if (count.status) {
                if (count.error && count.error.msg) {
                    _this.openSnackBar(count.error.msg, 'Dismiss');
                    _this.dataSource.data = [];
                    _this.length = 0;
                    _this.loading = false;
                }
            }
            else {
                _this.length = count;
                _this.oldboysService.getOlboys(str, firstYear, _this.sortby, _this.sortdirection, _this.filters[_this.filterName]).subscribe(function (oldboys) {
                    if (oldboys) {
                        _this.retrieved = oldboys.length;
                    }
                    else {
                        _this.retrieved = 0;
                    }
                    _this.dataSource.data = oldboys;
                    _this.dataSource.sort = sort;
                    _this.loading = false;
                    console.log(_this.dataSource);
                });
            }
        });
    };
    OldboysComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 100000,
        });
    };
    OldboysComponent.prototype.tableRefresh = function (oldboys) {
        console.log('table refreshed');
        var sort = this.sort;
        var str = this.search.replace(/\s/g, '');
        var firstYear = this.searchFirstYear;
        this.loading = true;
        this.dataSource.data = oldboys;
        this.dataSource.sort = sort;
        this.loading = false;
    };
    OldboysComponent.prototype.onPageChange = function (event) {
        //console.log(event);
        //console.log(this.paginator.pageIndex);
        //console.log(this.paginator.pageSize);
        this.getData();
    };
    OldboysComponent.prototype.onAdd = function () {
        var _this = this;
        var dialogRef = this.dialog.open(OldboyDialogComponent, {
            maxWidth: '100vw',
            maxHeight: '100vh',
            height: '100%',
            width: '100%',
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.oldboysService.createOldboy(result.data).subscribe(function (r) {
                    _this.oldboysService.bulkUpdateOldboyCategory(r.id, result.categoryData, []).subscribe(function (u) {
                        _this.oldboysService.getOlboyCategories(r.id).subscribe(function (z) {
                            r.Categories = z;
                            var oldboys = _this.dataSource.data;
                            oldboys.push(r);
                            _this.tableRefresh(oldboys);
                        });
                    });
                });
            }
        });
    };
    OldboysComponent.prototype.contactEmail = function (email) {
        // console.log('contactEmail',option)
        // console.log(this.oldBoyForm.value.emailaddress)
        // console.log(this.oldBoyForm.value.emailaddress2)
        // console.log(email)
        if (email) {
            console.log("Attempted to contact user at " + email + ', if no pop up occured your browser may have blocked it.');
            document.location.href = "mailto:" + email + "?subject=Message from WBHSOBA";
        }
        else {
            console.log("There is no email address to contact!");
        }
    };
    OldboysComponent.prototype.canContactEmail = function (email) {
        //console.log('canContactEmail',option)
        if (email) {
            return true;
        }
        return false;
    };
    OldboysComponent.prototype.onEdit = function (oldboy) {
        var dialogRef = this.dialog.open(OldboyDialogComponent, {
            //width: '400px',
            data: { oldboy: oldboy }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                /*
                this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
                  if (this.refresh) {
                    this.refresh.emit();
                  }
                });
                */
            }
        });
    };
    OldboysComponent.prototype.onDelete = function (oldboy) {
        var dialogRef = this.dialog.open(MessageBoxComponent, {
            //width: '400px',
            data: { title: 'Warning!', text: 'Do you really want to delete ' + oldboy.name + ' ?', answers: ['No', 'Yes'] }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            console.log(result);
            if (result === 'Yes') {
                /*
                this.dataService.deleteItem(item.id).subscribe(i => {
                  if (this.refresh) {
                    this.refresh.emit();
                  }
                });
                */
            }
        });
    };
    OldboysComponent.prototype.onRefresh = function () {
        this.searchFirstYear = this.searchYear.value;
        this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
        this.getData();
    };
    OldboysComponent.prototype.onFilterChanged = function () {
        var _this = this;
        var user = this.userService.getCurrentUser();
        if (user) {
            var data = Object.assign({}, user.data || {});
            this.filterNames = Object.keys(this.filters);
            data.filters = this.filters;
            data.filterName = this.filterName;
            this.userService.putUserData(data).subscribe(function (res) {
                _this.searchFirstYear = _this.searchYear.value;
                _this.userService.setCurrentSearchFirstYear(_this.searchFirstYear);
            });
        }
    };
    OldboysComponent.prototype.onColumns = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ColumnsDialogComponent, {
            //height: '400px',
            //width: '600px',
            data: { availableColumns: this.availableColumns,
                selectedColumns: this.displayedColumns }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.displayedColumns = result;
                _this.userService.setCurrentColumns(result);
                //this.getData();
            }
        });
    };
    OldboysComponent.prototype.onFilter = function () {
        var _this = this;
        var filterName = this.filterName;
        var filter = this.filters[this.filterName];
        var dialogRef = this.dialog.open(FilterDialogComponent, {
            width: '600px',
            //maxHeight: '80%',
            data: { filterName: filterName, filter: filter }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                console.log(result);
                if (result.delete) {
                    if (result.filterName !== 'None') {
                        delete _this.filters[result.filterName];
                        _this.filterName = 'None';
                    }
                    else {
                        _this.filters['None'] = {};
                        _this.filterName = 'None';
                    }
                }
                else {
                    _this.filters[result.filterName] = result.filter;
                    _this.filterName = result.filterName;
                }
                _this.filterNames = Object.keys(_this.filters);
                _this.userService.putUserData({ filters: _this.filters, filterName: _this.filterName }).subscribe(function (res) {
                    //this.getData();
                });
                //console.log(this);
                /*
                this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
                  if (this.refresh) {
                    this.refresh.emit();
                  }
                });
                */
            }
        });
    };
    OldboysComponent.prototype.onPrint = function () {
        var newWin = window.open('');
        var css = Array.from(document.getElementsByTagName('style')).reduce(function (acc, current) { return acc + current.outerHTML; }, '');
        newWin.document.write('<html><head>' + css + '</head><body>' + this.table.nativeElement.outerHTML + '</body></html>');
        newWin.print();
        newWin.close();
    };
    OldboysComponent.prototype.onCellClicked = function (event) {
        var _this = this;
        console.log(event);
        var dialogRef = this.dialog.open(OldboyDialogComponent, {
            maxWidth: '100%',
            maxHeight: '100%',
            height: '100%',
            width: '100%',
            data: { oldboy: event }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result && result.delete) {
                var dialogRefDel = _this.dialog.open(MessageBoxComponent, {
                    //width: '400px',
                    data: { title: 'Warning!', text: 'Do you really want to delete ' + result.data.firstname + ' ' + result.data.lastname + ' ?', answers: ['No', 'Yes'] }
                });
                dialogRefDel.afterClosed().subscribe(function (resultDel) {
                    console.log(resultDel);
                    if (resultDel === 'Yes') {
                        console.log(result);
                        _this.oldboysService.deleteOldboy(result.id).subscribe(function (i) {
                            var oldboys = _this.dataSource.data;
                            var tempOldboys = [];
                            for (var value in oldboys) {
                                if (oldboys[value].id !== result.id) {
                                    tempOldboys.push(oldboys[value]);
                                }
                            }
                            _this.tableRefresh(tempOldboys);
                        });
                    }
                });
            }
            else if (result) {
                _this.oldboysService.updateOldboy(event.id, result.data).subscribe(function (r) {
                    _this.oldboysService.bulkUpdateOldboyCategory(r.id, result.categoryData, result.categoriesToDelete).subscribe(function (u) {
                        _this.oldboysService.getOlboyCategories(r.id).subscribe(function (z) {
                            r.Categories = z;
                            var oldboys = _this.dataSource.data;
                            for (var value in oldboys) {
                                if (oldboys[value].id === result.id) {
                                    oldboys[value] = r;
                                }
                            }
                            _this.tableRefresh(oldboys);
                        });
                    });
                });
            }
        });
    };
    OldboysComponent.prototype.sortData = function (event) {
        this.sortby = event.active;
        if (event.direction && event.direction.length > 0) {
            this.sortdirection = event.direction;
        }
        else {
            this.sortdirection = null;
        }
        localStorage.setItem('oldboys_sortby', this.sortby);
        localStorage.setItem('oldboys_sortdirection', this.sortdirection);
        this.getData();
    };
    OldboysComponent.prototype.onExport = function () {
        var _this = this;
        var dialogRef = this.dialog.open(ExportDialogComponent, {
            //width: '400px',
            data: {}
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                //this.exportAsExcelFile(this.dataSource.data, result.filename);
                if (result.target === 'pdf') {
                    html2canvas(_this.table.nativeElement).then(function (canvas) {
                        // Few necessary setting options
                        var imgWidth = 208;
                        var pageHeight = 295;
                        var imgHeight = canvas.height * imgWidth / canvas.width;
                        var heightLeft = imgHeight;
                        var contentDataURL = canvas.toDataURL('image/png');
                        var pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
                        var pageWidth = pdf.internal.pageSize.getWidth();
                        var position = 0;
                        pdf.addImage(contentDataURL, 'PNG', (pageWidth - imgWidth) / 2, position, imgWidth, imgHeight);
                        pdf.save(result.filename + '.pdf'); // Generated PDF
                    });
                }
                else {
                    _this.exportTableAsExcelFile(result.filename + '.xlsx');
                }
                /*
                this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
                  if (this.refresh) {
                    this.refresh.emit();
                  }
                });
                */
            }
        });
    };
    OldboysComponent.prototype.exportTableAsExcelFile = function (excelFileName) {
        console.log(this.table.nativeElement);
        var ws = XLSX.utils.table_to_sheet(this.table.nativeElement);
        var wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'WBHS Old Boys data');
        XLSX.writeFile(wb, excelFileName);
    };
    OldboysComponent.prototype.exportAsExcelFile = function (json, excelFileName) {
        var worksheet = XLSX.utils.json_to_sheet(json);
        var workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        var excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    };
    OldboysComponent.prototype.saveAsExcelFile = function (buffer, fileName) {
        var data = new Blob([buffer], { type: EXCEL_TYPE });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    };
    OldboysComponent.prototype.onKeydown = function (event) {
        if (event.key === 'Enter') {
            this.searchInput.nativeElement.blur();
            this.onRefresh(); // Trigger search when Enter is pressed
        }
    };
    OldboysComponent.prototype.onYearKeydown = function (event) {
        if (event.key === 'Enter') {
            //console.log(this.searchYear.value);
            this.searchFirstYear = this.searchYear.value;
            this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
            this.searchInputYear.nativeElement.blur();
            //this.getData();
        }
    };
    OldboysComponent.prototype.filterYears = function (value) {
        if (value) {
            var filterValue_1 = value.toString();
            return this.years.filter(function (option) { return option && option.toString().indexOf(filterValue_1) === 0; });
        }
        else {
            return this.years;
        }
    };
    OldboysComponent.prototype.getRecords = function (element) {
        var output_string = '';
        for (var sub_element in element.Categories) {
            if (output_string !== '') {
                output_string = output_string + ', ';
            }
            if (element.Categories[sub_element].name) {
                output_string = output_string + element.Categories[sub_element].name;
            }
            else if (element.Categories[sub_element].Category.name) {
                output_string = output_string + element.Categories[sub_element].Category.name;
            }
        }
        return output_string;
    };
    OldboysComponent.prototype.getBooleanDisplayValue = function (val) {
        return (val === 'true' || val === true) ? 'yes' : 'no';
    };
    return OldboysComponent;
}());
export { OldboysComponent };
