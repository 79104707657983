import {Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import { AccessComponent } from '../access/access.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';

import {MatDialog, MatPaginator, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatChipInputEvent} from '@angular/material';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import {Oldboy, Note, Activity, OldboysService} from "../oldboys.service";
import {UserService} from "../user.service";

@Component({
  selector: 'app-user-edit-dialog',
  templateUrl: './user-edit-dialog.component.html',
  styleUrls: ['./user-edit-dialog.component.css']
})
export class UserEditDialogComponent implements OnInit {

  id: string = null;

  idForm = new FormGroup({
    id: new FormControl(),
    prospect_id: new FormControl(),
    prospectName: new FormControl({disabled: true}),
    yearsAttended: new FormControl({disabled: true})
  })

  userForm = new FormGroup({
    username: new FormControl(),
    email: new FormControl(),
  });

  dataForm = new FormGroup({
    country: new FormControl('New Zealand'),
    dateofbirth: new FormControl(),
    firstName: new FormControl(),
    lastName: new FormControl(),
    middleName: new FormControl(),
    fromYear: new FormControl(),
    toYear: new FormControl(),
    homePhone: new FormControl(),
    workPhone: new FormControl(),
    mobilePhone: new FormControl(),
    house: new FormControl(),
    province: new FormControl(),
    streetAddress: new FormControl(),
    suburb: new FormControl(),
    town: new FormControl(),
    postCode: new FormControl(),
  });

  constructor(public dialogRef: MatDialogRef<AccessComponent>,
    private fb: FormBuilder,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oldboysService: OldboysService,
    private usersService: UserService) {
      if (data && data.user) {
        if (data.user.id){
          this.id = data.user.id
        }
        if(!data.user.data){
          data.user.data = {};
        }
        this.userForm.patchValue(data.user);
        this.dataForm.patchValue(data.user.data)
        var temp_years = ''
        if (data.user.prospect && data.user.prospect.firstyear && data.user.prospect.lastyear){
          temp_years = data.user.prospect.firstyear + ' - ' + data.user.prospect.lastyear
        } else {
          temp_years = 'No Linked Record'
        }
        var temp_name = ''
        if (data.user.prospect && data.user.prospect.firstname){
          temp_name = '' + data.user.prospect.firstname
          if (data.user.prospect.lastname){
            temp_name = temp_name + ' ' + data.user.prospect.lastname
          }
        } else {
          temp_name = 'No Linked Record'
        }
        if(data.user.prospect && data.user.prospect.id){
          this.idForm.patchValue({
            prospect_id: data.user.prospect.id,
          })
        }
        this.idForm.patchValue({
          id: this.id,
          prospectName: temp_name,
          yearsAttended: temp_years,
        })
        this.idForm.disable()
      }
    }

  ngOnInit() {
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (isDelete): void {
    var temp_value = {delete: isDelete, data: {user: this.userForm.value}}
    temp_value.data.user['data'] = this.dataForm.value
    this.dialogRef.close(temp_value);
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

}
