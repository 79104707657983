import { OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { SidebarService } from '../sidebar.service';
import { OldboysService } from '../oldboys.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { SubmitInfoDialogComponent } from '../submit-info-dialog/submit-info-dialog.component';
import { MembershipRequestService } from '../membership-request.service';
var LayoutComponent = /** @class */ (function () {
    function LayoutComponent(auth, user, sidebar, oldBoyService, snackBar, membershipRequestService, dialog, snackbar) {
        var _this = this;
        this.auth = auth;
        this.user = user;
        this.sidebar = sidebar;
        this.oldBoyService = oldBoyService;
        this.snackBar = snackBar;
        this.membershipRequestService = membershipRequestService;
        this.dialog = dialog;
        this.snackbar = snackbar;
        this.title = 'WBHS Alumni';
        this.subscription = sidebar.sidebarOpenState.subscribe(function (state) {
            if (state) {
                _this.navigation.open();
            }
            else {
                _this.navigation.close();
            }
        });
    }
    LayoutComponent.prototype.ngOnInit = function () {
        if (this.isSignedIn()) {
            this.navigation.open();
            this.current = this.user.getCurrentUser();
        }
        else {
            this.navigation.close();
        }
    };
    LayoutComponent.prototype.isSignedIn = function () {
        return this.auth.isSignedIn();
    };
    LayoutComponent.prototype.isContributor = function () {
        return this.user.isContributor();
    };
    LayoutComponent.prototype.isAdmin = function () {
        return this.user.isAdmin();
    };
    LayoutComponent.prototype.signOut = function () {
        this.auth.signOut();
    };
    Object.defineProperty(LayoutComponent.prototype, "name", {
        get: function () {
            return this.user.getUsername();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayoutComponent.prototype, "firstLastName", {
        get: function () {
            return this.user.getCurrentFirstLastName();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LayoutComponent.prototype, "initials", {
        get: function () {
            var names = this.user.getCurrentFirstLastName().split(" ");
            var outstring = "";
            for (var _i = 0, names_1 = names; _i < names_1.length; _i++) {
                var element = names_1[_i];
                outstring = outstring + element[0];
            }
            return outstring.toUpperCase();
        },
        enumerable: true,
        configurable: true
    });
    LayoutComponent.prototype.onLoginSuccess = function () {
        console.log('app on login success');
        if (this.isSignedIn()) {
            this.navigation.open();
        }
        else {
            this.navigation.close();
        }
    };
    LayoutComponent.prototype.manageDetails = function () {
        var _this = this;
        this.dialog.open(this.manageDetailsDialogRef).afterClosed()
            .subscribe(function (result) {
            if (result) {
                var currUser = _this.user.getCurrentUser();
                var dialogOldBoy = _this.dialog.open(SubmitInfoDialogComponent, {
                    maxHeight: '100vh',
                    height: '100%',
                    data: {
                        id: currUser.prospectId,
                        emailaddress: currUser.email,
                        firstname: currUser.data.firstName,
                        lastname: currUser.data.lastName,
                        birthdate: currUser.data.dateOfBirth,
                        firstyear: currUser.data.fromYear,
                        lastyear: currUser.data.toYear,
                        country: currUser.data.country,
                        town_city: currUser.data.town,
                        housename: currUser.data.house,
                        suburb: currUser.data.suburb,
                        postalcode: currUser.data.postcode,
                        province_state: currUser.data.province,
                        hometelephone: currUser.data.homePhone,
                        worktelephone: currUser.data.workPhone,
                        mobiletelephone: currUser.data.mobilePhone,
                        address1: currUser.data.streetAddress,
                    }
                });
                dialogOldBoy.afterClosed().subscribe(function (result1) {
                    if (result1) {
                        _this.membershipRequestService.createInformationSubmissionRequest(result1)
                            .subscribe(function (res) {
                            _this.snackBar.open(res.msg, 'Dismiss', {
                                duration: 100000,
                            });
                        });
                    }
                });
            }
        });
    };
    Object.defineProperty(LayoutComponent.prototype, "requestsLinkTitle", {
        get: function () {
            if (this.isAdmin() || this.isContributor()) {
                return "Requests";
            }
            else {
                return "My Requests";
            }
        },
        enumerable: true,
        configurable: true
    });
    return LayoutComponent;
}());
export { LayoutComponent };
