import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth.guard';
import { AdminGuard } from './admin.guard';
import { ContributorGuard } from './contributor.guard';
import {AccountComponent} from './account/account.component';
import {AccessComponent} from './access/access.component';
import {YearsComponent} from './years/years.component';
import {ReportingComponent} from './reporting/reporting.component';
import {ContactsComponent} from './contacts/contacts.component';
import {ReportsComponent} from './reports/reports.component';
import {PaymentsComponent} from './payments/payments.component';
import {KeywordsComponent} from './keywords/keywords.component';
import {CommitteesComponent} from './committees/committees.component';
import {BankingComponent} from './banking/banking.component';
import {VolunteersComponent} from './volunteers/volunteers.component';
import {CampaignsComponent} from './campaigns/campaigns.component';
import {OldboysComponent} from './oldboys/oldboys.component';
import {MaintenanceComponent} from './maintenance/maintenance.component';
import {RegisterComponent} from './register/register.component';
import { RequestsComponent } from './requests/requests.component';
import { LayoutComponent } from './layout/layout.component';
import {ClaimrecordComponent} from './claimrecord/claimrecord.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { DonationsReportComponent } from './donations-report/donations-report.component';

const routes: Routes = [
  //{ path: '', redirectTo: '/years', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: '', component: LayoutComponent, children: [
    { path: 'years', component: YearsComponent },
    { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
    { path: 'claimrecord', component: ClaimrecordComponent, canActivate: [AuthGuard] },
    { path: 'contacts', component: ContactsComponent, canActivate: [AuthGuard] },
    { path: 'account', component: AccountComponent, canActivate: [AuthGuard] },
    { path: 'oldboys', component: OldboysComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'payment-report', component: PaymentReportComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'donation-report', component: DonationsReportComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'payments', component: PaymentsComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'keywords', component: KeywordsComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'committees', component: CommitteesComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'volunteers', component: VolunteersComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'campaigns', component: CampaignsComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'banking', component: BankingComponent, canActivate: [AuthGuard, ContributorGuard] },
    { path: 'requests', component: RequestsComponent, canActivate: [AuthGuard] },
    { path: 'access', component: AccessComponent, canActivate: [AuthGuard, AdminGuard] },
    { path: 'maintenance', component: MaintenanceComponent, canActivate: [AuthGuard, AdminGuard] },
    { path: 'reporting', component: ReportingComponent, canActivate: [AuthGuard, AdminGuard] },
  ]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
