import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';

import {Service} from './service';

@Injectable({
  providedIn: 'root'
})
export class OldboysService extends Service {

  endpoint = this.getEndpoint('oldboys');

  constructor(private http: HttpClient) {
    super();
  }

  public getOlboys(search, searchFirstYear, sortby, sortdirection, filter): Observable<[Oldboy]> {
    var params = new HttpParams();
    if (search && search.length > 0) {
      params = params.set('search', search);
    }
    console.log(filter);
    if (filter) {
      const use = {};
      const values = {};
      Object.keys(filter).forEach(keyword => {
        if (keyword.startsWith('use')) {
          if (filter[keyword] === true) {
            use[keyword.slice(3).toLowerCase()] = true;
          }
        } else {
          values[keyword] = filter[keyword];
        }
      });
      Object.keys(values).forEach( keyword => {
        if (use[keyword.toLowerCase()] === true) {
          if (keyword.toLowerCase() === 'validated' ||
              keyword.toLowerCase() === 'wbhsoldboy' ||
              keyword.toLowerCase() === 'deceased') {
            const val = filter[keyword];
            if (val === null) {
              params = params.set(keyword.toLowerCase(), 'false');
            } else {
              params = params.set(keyword.toLowerCase(), filter[keyword]);
            }
          } else if (keyword.toLowerCase() === 'email') {
            if (filter['anyEmail']) {
              params = params.set('anyEmail', 'true');
            } else {
              params = params.set(keyword.toLowerCase(), filter[keyword]);
            }
          } else {
            params = params.set(keyword.toLowerCase(), filter[keyword]);
          }
        }
      });
    }

    if (searchFirstYear && searchFirstYear > 0) {
      params = params.set('firstyear', searchFirstYear);
    }

    if (sortby){
      params = params.set('sortby', sortby);
      if (sortdirection){
        params = params.set('sortdirection', sortdirection);
      }
    }

    return this.http.get(this.endpoint,{ params: params }).pipe(
      catchError(this.handleError('oldboys', []))
    );
  }

  getOldBoy(id): Observable<Oldboy> {
    return this.http.get<Oldboy>(this.endpoint + '/' + id);
  }

  public getOldboysCount(search, searchFirstYear, filter): Observable<any> {
    var params = new HttpParams();

    var useParams = false;

    if (search && search.length > 0) {
      params = params.set('search', search);
      useParams = true;
    }

    if (searchFirstYear && searchFirstYear > 0) {
      params = params.set('firstyear', searchFirstYear);
      useParams = true;
    }

    if (filter) {
      const use = {};
      const values = {};
      Object.keys(filter).forEach(keyword => {
        if (keyword.startsWith('use')) {
          if (filter[keyword] === true) {
            use[keyword.slice(3).toLowerCase()] = true;
          }
        } else {
          values[keyword] = filter[keyword];
        }
      });
      Object.keys(values).forEach( keyword => {
        if (use[keyword.toLowerCase()] === true) {
          if (keyword.toLowerCase() === 'validated' ||
            keyword.toLowerCase() === 'wbhsoldboy' ||
            keyword.toLowerCase() === 'deceased') {
            const val = filter[keyword];
            if (val === null) {
              params = params.set(keyword.toLowerCase(), 'false');
            } else {
              params = params.set(keyword.toLowerCase(), filter[keyword]);
            }
          } else if (keyword.toLowerCase() === 'email') {
            if (filter['anyEmail']) {
              params = params.set('anyEmail', 'true');
            } else {
              params = params.set(keyword.toLowerCase(), filter[keyword]);
            }
          } else {
            params = params.set(keyword.toLowerCase(), filter[keyword]);
          }
          useParams = true;
        }
      });
    }

    if (useParams) {
      return this.http.get(this.endpoint + '/count', { params: params }).pipe(
        map(t => t['count']),
        catchError(this.handleError('count_oldboys', []))
      );
    } else {
      return this.http.get(this.endpoint + '/count').pipe(
        map(t => t['count']),
        catchError(this.handleError('count_oldboys', []))
      );
    }
  }

  public createOldboy(params): Observable<Oldboy> {
    return this.http.post(this.endpoint, params).pipe(
      catchError(this.handleError('create_oldboy', []))
    );
  }

  public updateOldboy(id, params): Observable<Oldboy> {
    console.log(params);
    return this.http.put(this.endpoint + '/' + id, params).pipe(
      catchError(this.handleError('update_oldboy', []))
    );
  }

  // unlinking
  public unlinkOldboy(id,params): Observable<any> {
    return this.http.put(this.endpoint + '/'+id+'/prospect', params).pipe(
      catchError(this.handleError('unlink_oldboy',[]))
    );
  }

  public isLinked(id): Observable<any> {
    return this.http.get(this.endpoint + '/' + id + '/linked-status').pipe(
      catchError(this.handleError('isLinked',[]))
    );
  }

  //notes
  public getOldboyNotes(id): Observable<any> {
    return this.http.get(this.endpoint + '/' + id + '/notes').pipe(
      catchError(this.handleError('oldboy_notes',[]))
    )
  }

  public addOldboyNotes(id, noteText): Observable<any> {
    return this.http.post(this.endpoint + '/' + id + '/notes', {noteText: noteText}).pipe(
      catchError(this.handleError('oldboy_notes',[]))
    )
  }

  public updateOldboyNotes(id, noteText, noteId): Observable<any> {
    return this.http.put(this.endpoint + '/' + id + '/notes/' + noteId, {noteText: noteText}).pipe(
      catchError(this.handleError('oldboy_notes',[]))
    )
  }

  public deleteOldboyNotes(id, noteId): Observable<any> {
    return this.http.delete(this.endpoint + '/' + id + '/notes/' + noteId).pipe(
      catchError(this.handleError('oldboy_notes',[]))
    )
  }

  // Activities
  public getOlboyActivities(id): Observable<[Activity]> {
    return this.http.get(this.endpoint + '/' + id + '/activities').pipe(
      catchError(this.handleError('oldboy_activities', []))
    );
  }

  public createOldboyActivity(id, activity): Observable<Activity> {
    return this.http.post(this.endpoint + '/' + id + '/activities', activity).pipe(
      catchError(this.handleError('update_activity', []))
    );
  }

  public updateOldboyActivity(activity): Observable<Activity> {
    return this.http.put(this.endpoint + '/oldboyactivities', activity).pipe(
      catchError(this.handleError('update_activity', []))
    );
  }

  public deleteOldboyActivity(id): Observable<any> {
    return this.http.delete( this.endpoint + '/oldboyactivities/' + id).pipe(
      catchError(this.handleError('delete_activity', []))
    );
  }

  public getActivities(): Observable<[Activity]> {
    return this.http.get( this.endpoint + '/activities').pipe(
      catchError(this.handleError('activities', []))
    );
  }

  public addActivity(params): Observable<[Activity]> {
    return this.http.post( this.endpoint + '/activities', params).pipe(
      catchError(this.handleError('activities', []))
    );
  }

  public updateActivity(activity): Observable<Activity> {
    return this.http.put(this.endpoint + '/activities' , activity).pipe(
      catchError(this.handleError('update_activity', []))
    );
  }

  public deleteActivity(id): Observable<any> {
    return this.http.delete( this.endpoint + '/activities/' + id).pipe(
      catchError(this.handleError('delete_activity', []))
    );
  }

  // Categories
  public getOlboyCategories(id): Observable<[Category]> {
    return this.http.get(this.endpoint + '/' + id + '/categories').pipe(
      catchError(this.handleError('oldboy_categories', []))
    );
  }

  public createOldboyCategory(id, category): Observable<Category> {
    return this.http.post(this.endpoint + '/' + id + '/categories', category).pipe(
      catchError(this.handleError('create_category', []))
    );
  }

  public updateOldboyCategory(category): Observable<Category> {
    return this.http.put(this.endpoint + '/oldboycategories', category).pipe(
      catchError(this.handleError('update_category', []))
    );
  }

  public bulkUpdateOldboyCategory(id, categories, toDel): Observable<any> {
    return this.http.put(this.endpoint + '/' + id + '/categories/bulkupdate', {data: categories, toDel: toDel}).pipe(
      catchError(this.handleError('bulk_update_categories', []))
    )
  }

  public deleteOldboyCategory(id): Observable<any> {
    return this.http.delete( this.endpoint + '/oldboycategories/' + id ).pipe(
      catchError(this.handleError('delete_category', []))
    );
  }

  public getCategories(): Observable<[Category]> {
    return this.http.get( this.endpoint + '/categories').pipe(
      catchError(this.handleError('categories', []))
    );
  }

  public addCategory(params): Observable<[Category]> {
    return this.http.post( this.endpoint + '/categories', params).pipe(
      catchError(this.handleError('categories', []))
    );
  }

  public updateCategory(category): Observable<Category> {
    return this.http.put(this.endpoint + '/categories' , category).pipe(
      catchError(this.handleError('update_category', []))
    );
  }

  public deleteCategory(id): Observable<any> {
    return this.http.delete( this.endpoint + '/categories/' + id).pipe(
      catchError(this.handleError('delete_category', []))
    );
  }
  public deleteSubscriptionCategory(data): Observable<any> {
    return this.http.put( this.endpoint + '/subscriptioncategories',data).pipe(
      catchError(this.handleError('delete_SubscriptionCategory', []))
    );
  }
  public getActiveSubscriptionCategory(): Observable<any> {
    return this.http.get( this.endpoint + '/subscriptioncategories?ignoreDeleted=true').pipe(
      catchError(this.handleError('get_ActiveSubscriptionCategory', []))
    );
  }

  public deletePaymentMethod(data): Observable<any> {
    return this.http.put( this.endpoint + '/paymentmethods',data).pipe(
      catchError(this.handleError('delete_PaymentMethod', []))
    );
  }
  public getActivePaymentMethod(): Observable<any> {
    return this.http.get( this.endpoint + '/paymentmethods?ignoreDeleted=true').pipe(
      catchError(this.handleError('get_ActivePaymentMethod', []))
    );
  }

  public deletePaymentArea(data): Observable<any> {
    return this.http.put( this.endpoint + '/paymentareas',data).pipe(
      catchError(this.handleError('delete_PaymentArea', []))
    );
  }

  public getActivePaymentArea(): Observable<any> {
    return this.http.get( this.endpoint + '/paymentareas?ignoreDeleted=true').pipe(
      catchError(this.handleError('get_ActivePaymentArea', []))
    );
  }

  public deletePaymentProject(data): Observable<any> {
    return this.http.put( this.endpoint + '/paymentprojects',data).pipe(
      catchError(this.handleError('delete_PaymentProject', []))
    );
  }

  public getActivePaymentProject(): Observable<any> {
    return this.http.get( this.endpoint + '/paymentprojects?ignoreDeleted=true').pipe(
      catchError(this.handleError('get_ActivePaymentProject', []))
    );
  }

  // Keywords

  public getOldboyKeywords(id): Observable<[Keyword]> {
    return this.http.get(this.endpoint + '/' + id + '/keywords').pipe(
      catchError(this.handleError('oldboy_keywords', []))
    );
  }

  public createOldboyKeyword(id, keyword): Observable<Keyword> {
    return this.http.post(this.endpoint + '/' + id + '/keywords', keyword).pipe(
      catchError(this.handleError('create_keyword', []))
    );
  }

  public updateOldboyKeyword(keyword): Observable<Keyword> {
    return this.http.put(this.endpoint + '/oldboykeywords', keyword).pipe(
      catchError(this.handleError('update_keyword', []))
    );
  }

  public deleteOldboyKeyword(id): Observable<any> {
    return this.http.delete( this.endpoint + '/oldboykeywords/' + id).pipe(
      catchError(this.handleError('delete_keyword', []))
    );
  }

  public getKeywords(): Observable<[Keyword]> {
    return this.http.get( this.endpoint + '/keywords').pipe(
      catchError(this.handleError('keywords', []))
    );
  }

  public addKeyword(params): Observable<[Keyword]> {
    return this.http.post( this.endpoint + '/keywords', params).pipe(
      catchError(this.handleError('keywords', []))
    );
  }

  public updateKeyword(keyword): Observable<Keyword> {
    return this.http.put(this.endpoint + '/keywords', keyword).pipe(
      catchError(this.handleError('update_keyword', []))
    );
  }

  public deleteKeyword(id): Observable<any> {
    return this.http.delete( this.endpoint + '/keywords/' + id).pipe(
      catchError(this.handleError('delete_keyword', []))
    );
  }
  
  public getSubscriptionCategories(): Observable<string[]> {
    return this.http.get( this.endpoint + '/subscriptioncategories').pipe(
      catchError(this.handleError('subscriptioncategories', []))
    );
  }

  public addSubscriptionCategory(params): Observable<[Object]> {
    return this.http.post( this.endpoint + '/subscriptioncategories', params).pipe(
      catchError(this.handleError('subscriptioncategories', []))
    );
  }

  public updateSubscriptionCategory(sc): Observable<Object> {
    return this.http.put(this.endpoint + '/subscriptioncategories', sc).pipe(
      catchError(this.handleError('update_subscriptioncategory', []))
    );
  }

  public getmaxid(): Observable<string[]> {
    return this.http.get(this.endpoint + '/memberships/getmaxid').pipe(
      catchError(this.handleError('paymentmethods', []))
    );
  }

  public getmaxid_donations(): Observable<string[]> {
    return this.http.get(this.endpoint + '/donations/getmaxid').pipe(
      catchError(this.handleError('getmaxid_donations', []))
    );
  }

  public getPaymentsMethods(): Observable<string[]> {
    return this.http.get( this.endpoint + '/paymentmethods').pipe(
      catchError(this.handleError('paymentmethods', []))
    );
  }

  public addPaymentMethod(params): Observable<[Object]> {
    return this.http.post( this.endpoint + '/paymentmethods', params).pipe(
      catchError(this.handleError('paymentmethods', []))
    );
  }

  public updatePaymentMethod(pm): Observable<Object> {
    return this.http.put(this.endpoint + '/paymentmethods', pm).pipe(
      catchError(this.handleError('update_paymentmethod', []))
    );
  }

  public getMembershipCategories(): Observable<[String]> {
    return this.http.get( this.endpoint + '/membershipcategories').pipe(
      catchError(this.handleError('membershipcategories', []))
    );
  }


  public getOlboyMemberships(id): Observable<[Membership]> {
    return this.http.get(this.endpoint + '/' + id + '/memberships').pipe(
      catchError(this.handleError('oldboy_memberships', []))
    );
  }

  public updateOldboyMembership(id, membership): Observable<Membership> {
    if (membership.id) {
       return this.http.put(this.endpoint + '/' + id + '/memberships/' + membership.id, membership).pipe(
         catchError(this.handleError('update_membership', []))
       );
     } else {
       return this.http.post(this.endpoint + '/' + id + '/memberships', membership).pipe(
         catchError(this.handleError('update_membership', []))
       );
     }
  }

  public deleteOldboyMembership(id, membershipId): Observable<any> {
     return this.http.delete( this.endpoint + '/' + id + '/memberships/' + membershipId).pipe(
       catchError(this.handleError('delete_membership', []))
     );
  }

  public getPaymentAreas(): Observable<[string]> {
    return this.http.get( this.endpoint + '/paymentareas').pipe(
      catchError(this.handleError('paymentareas', []))
    );
  }

  public addPaymentArea(params): Observable<[Object]> {
    return this.http.post( this.endpoint + '/paymentareas', params).pipe(
      catchError(this.handleError('paymentareas', []))
    );
  }

  public updatePaymentArea(sc): Observable<Object> {
    return this.http.put(this.endpoint + '/paymentareas', sc).pipe(
      catchError(this.handleError('update_paymentarea', []))
    );
  }

  public getPaymentProjects(): Observable<[string]> {
    return this.http.get( this.endpoint + '/paymentprojects').pipe(
      catchError(this.handleError('paymentprojects', []))
    );
  }

  public addPaymentProject(params): Observable<[Object]> {
    return this.http.post( this.endpoint + '/paymentprojects', params).pipe(
      catchError(this.handleError('paymentprojects', []))
    );
  }

  public updatePaymentProject(sc): Observable<Object> {
    return this.http.put(this.endpoint + '/paymentprojects', sc).pipe(
      catchError(this.handleError('update_paymentproject', []))
    );
  }


  public getOlboyDonations(id): Observable<[Donation]> {
    return this.http.get(this.endpoint + '/' + id + '/donations').pipe(
      catchError(this.handleError('oldboy_donations', []))
    );
  }

  public updateOldboyDonation(id, donation): Observable<Donation> {
    if (donation.id) {
       return this.http.put(this.endpoint + '/' + id + '/donations/' + donation.id, donation).pipe(
         catchError(this.handleError('update_donation', []))
       );
     } else {
       return this.http.post(this.endpoint + '/' + id + '/donations', donation).pipe(
         catchError(this.handleError('update_donation', []))
       );
     }
  }

  public deleteOldboyDonation(id, donationId): Observable<any> {
     return this.http.delete( this.endpoint + '/' + id + '/donations/' + donationId).pipe(
       catchError(this.handleError('delete_donation', []))
     );
  }

  public deleteOldboy(id): Observable<any> {
    return this.http.delete( this.endpoint + '/' + id).pipe(
      catchError(this.handleError('delete_oldboy', []))
    );
  }

  public sendEmailOldboyMembership(id, membership,emailAddress,timeZone): Observable<any> {
       return this.http.post(this.endpoint + '/' + id + '/membership-receipt-email/' + membership.id, {emailAddress:emailAddress, timeZone:timeZone}).pipe(
         catchError(this.handleError('sendEmailOldboyMembership', []))
       );     
  }

  public sendEmailOldboyDonation(id, donation,emailAddress,timeZone): Observable<any> {
    return this.http.post(this.endpoint + '/' + id + '/donation-receipt-email/' + donation.id, {emailAddress:emailAddress, timeZone:timeZone}).pipe(
      catchError(this.handleError('sendEmailOldboyDonation', []))
    );     
}

}

export interface Oldboy {
  id: string;
  Categories: Array<Category>;
  validated: boolean;
  deceased: boolean;
  deceasedate: Date;
  alumnimember: boolean;
  label: boolean;
  capacitycategory: string;
  relationshipcategory: string;
  gender: string;
  title: string;
  initials: string;
  firstname: string;
  middlenames: string;
  lastname: string;
  salutation: string;
  maidenname: string;
  birthdate: Date;
  address1: string;
  address2: string;
  suburb: string;
  town_city: string;
  province_state: string;
  postal_code: string;
  country: string;
  hometelephone: string;
  mobiletelephone: string;
  faxnumber: string;
  emailaddress: string;
  employer: string;
  profession_jobtitle: string;
  qualificationlevel: string;
  careernotes: string;
  worktelephone: string;
  preferredcontactmethod: string;
  secondcontactmethod: string;
  workaddress1: string;
  workaddress2: string;
  worksuburb: string;
  worktown_city: string;
  workprovince_state: string;
  workpostalcode: string;
  workcountry: string;
  housename: string;
  firstyear: number;
  lastyear: number;
  border: boolean;
  fromyear: string;
  toyear: string;
  organisationalnotes: string;
  maritalstatus: string;
  parent_partnertitle: string;
  parent_partnerinitials: string;
  parent_partnerfirstname: string;
  parent_partnerlastname: string;
  partnerinterests: string;
  childrensnames: string;
  volunteer1id: string;
  volunteer2id: string;
  fundraisingnotes: string;
  firstclass: string;
  lastclass: string;
  updated: Date;
  loaded: string;
  buddy: string;
  createdAt: Date;
  updatedAt: Date;
  updatedBy: string;
  wbhsOldBoy: boolean;
}

export interface Note {
  id: string;
  noteText: string;
  updatedBy: string;
  updatedAt: Date;
}

export interface Activity {
  id: string;
  name: string;
  sport: boolean;
  position_role: string;
  years_involved: string;
  group_team: string;
  notes: string;
}

export interface Category {
  id: string;
  pseudoid: string;
  modified: boolean;
  name: string;
  from: string;
  to: string;
  notes: string;
}

export interface Keyword {
  id: string;
  name: string;
}

export interface Membership {
  id: number;
  SubscriptionCategory: object;
  payment_date: Date;
  renewed_to: Date;
  paid: number;
  subscription_category_id: number;
  payment_method_id: number;
  receipt_number: object | string;
  PaymentMethod: object;
  comment: string;
  allowSendReceipt:boolean;
}

export interface Donation {
  id: number;
  PaymentArea: object;
  PaymentProject: object;
  payment_date: Date;
  paid: number;
  payment_area_id: number;
  payment_project_id: number;
  payment_method_id: number;
  receipt_number: object | string;
  PaymentMethod: object;
  comment: string;
  allowSendReceipt:boolean;
}
