<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">lock</mat-icon>Access Management
    </mat-card-title>
    <mat-card-subtitle>
      Manage administrator, contributor and member accounts
    </mat-card-subtitle>

    <mat-card-header fxLayout="row">

      <mat-form-field fxFlex="80%">
        <input matInput (keyup)="applyFilter($event.target.value)" placeholder="Search">
      </mat-form-field>
      <div fxFlex="10%">
      </div>
      <div fxFlex="10%">
        <button  mat-mini-fab color="accent" matTooltip="Add a user" (click)="onAdd()"><mat-icon>add</mat-icon></button>
        <button  mat-mini-fab color="accent" matTooltip="Toggle Displaying Members" (click)="onViewMembers()"><mat-icon>remove_red_eye</mat-icon></button>
      </div>

    </mat-card-header>

    <mat-card-content fxLayout="column">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <table mat-table [dataSource]="dataSource" *ngIf="!loading">

        <!-- Username Column -->
        <ng-container matColumnDef="username">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{getName(element)}} </td>
        </ng-container>

        <!-- Email Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef> Email </th>
          <td mat-cell *matCellDef="let element"> {{element.email}} </td>
        </ng-container>

        <!-- Years Owned Column-->
        <ng-container matColumnDef="yearsowned" class="years-owned-column-cell">
          <th mat-header-cell *matHeaderCellDef> Years-Owned </th>
          <td mat-cell *matCellDef="let element">
            <mat-expansion-panel *ngIf="canBeYearOwner(element)" maxlength="20" [disabled]="!canBeYearOwner(element)" hideToggle>
              <mat-expansion-panel-header class="max-expansion-panel-header-edit">
                <mat-panel-title>
                  {{getYearsOwned(element)}}
                </mat-panel-title>
                <mat-icon [inline]="true">edit</mat-icon>
              </mat-expansion-panel-header>
              <ng-template matExpansionPanelContent>
                <table mat-table [dataSource]="getYearsOwned(element)">
                  <!-- Years owned by this user -->
                  <ng-container matColumnDef="years">
                    <th mat-header-cell *matHeaderCellDef> Years-Owned </th>
                    <td mat-cell *matCellDef="let element2">
                      {{element2}}
                      <button mat-icon-button color="accent" (click)="onDeleteYear(element,element2)" matTooltip="Remove this year"><mat-icon>delete</mat-icon></button>
                    </td>
                  </ng-container>
                  <!-- Button to delete this year -->
                  <tr mat-header-row *matHeaderRowDef="displayedSubColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedSubColumns;"></tr>
                </table>
                <!-- Field to add a year -->
                <mat-form-field>
                  <mat-select placeholder="Add Year" [disabled]="!canBeYearOwner(element)" (selectionChange)="onYearChange($event, element)">
                    <mat-option *ngFor="let date of datesavailable" [(value)]="date">
                      {{date}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </ng-template>
            </mat-expansion-panel>
          </td>
        </ng-container>

        <!-- Validated Column -->
        <ng-container matColumnDef="validated">
          <th mat-header-cell *matHeaderCellDef> Verified </th>
          <td mat-cell *matCellDef="let element">
            <mat-checkbox
              [(ngModel)]="element.validated"
              [disabled]="true">
            </mat-checkbox>
          </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef> Account type </th>
          <td mat-cell *matCellDef="let element">
            <mat-select [value]="element.type" (selectionChange)="onTypeChange($event, element)">
              <mat-option value="MEMBER">Member</mat-option>
              <mat-option value="YEAR-OWNER">Year-Owner</mat-option>
              <mat-option value="CONTRIBUTOR">Contributor</mat-option>
              <mat-option value="ADMIN">Administrator</mat-option>
            </mat-select>
          </td>
        </ng-container>

        <!-- Enabled Column -->
        <ng-container matColumnDef="disabled">
          <th mat-header-cell *matHeaderCellDef> Enabled </th>
          <td mat-cell *matCellDef="let element">
            <mat-slide-toggle
              color="primary"
              (change)="onEnabledChange($event,element)"
              [checked]="!element.disabled">
            </mat-slide-toggle>
          </td>
        </ng-container>

        <!-- edit user column Column -->
        <ng-container matColumnDef="editUser" *ngIf="isAdmin()">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editUserDialog(element)" matTooltip="Edit this User's data.">
              <mat-icon [inline]="true">
                settings
              </mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>

    <mat-card-actions align="end">
      <mat-paginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
    </mat-card-actions>
  </mat-card>
</div>

