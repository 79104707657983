<h1 mat-dialog-title *ngIf="!id">Assign category</h1>
<h1 mat-dialog-title *ngIf="id">Editing {{category.get('name').value}}</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="category">

  <mat-form-field>
    <mat-select matInput formControlName="name" placeholder="Name">
      <mat-option *ngFor="let category of filteredOptions | async" [value]="category.name">{{category.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let category of filteredOptions | async" [value]="category.name">{{category.name}}</mat-option>
  </mat-autocomplete>

  <mat-form-field>
    <input matInput placeholder="From" formControlName="from"  (keydown)="onKeydown($event)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="To" formControlName="to"  (keydown)="onKeydown($event)">
  </mat-form-field>
  <mat-form-field>
    <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog(true)" *ngIf="!isNew" color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial [disabled]="!isValid()" color="primary">Save</button>
</div>
