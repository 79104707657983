<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <div fxLayout="row">
        <div fxLayoutAlign="start center" fxFlex="7 0 auto">
          <mat-icon inline="true">event</mat-icon>&nbsp;&nbsp;Year lists
        </div>
        <!-- <div fxLayoutAlign="end center"  fxFlex="3 0 auto" *ngIf="canAddNewRecord()">
          <button mat-mini-fab color="accent" (click)="addNewRecord()"  matTooltip="Add new record"><mat-icon>add</mat-icon></button>
        </div> -->
      </div>
    </mat-card-title>
    <div class="mat-card-subtitle">{{ subTitle }}</div>
    <mat-card-header>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <div fxLayoutAlign="center center" fxFlex="6 0 auto" fxLayoutGap="12px">
          <mat-form-field>
            <input type="number" min="1900" max="2100"
                   (keydown)="onKeydown($event)"
                   placeholder="Class year"
                   aria-label="year" matInput [formControl]="search" [matAutocomplete]="auto" #searchInput>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let year of filteredOptions | async" [value]="year">
                {{year}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="onRefresh()" [disabled]="!hitTest()">Search</button>
        </div>
        <div fxFlex="4 0 auto"></div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading"></mat-spinner>
      <table mat-table [dataSource]="dataSource" *ngIf="!loading && (dataSource.data.length > 0)">

        <!-- First name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="header-cell"> Name </th>
          <td mat-cell *matCellDef="let element" class="name-cell"> {{element.firstname + ' ' + (element.middlenames != null ? element.middlenames : '') + ' ' + element.lastname}} </td>
        </ng-container>

        <!-- Last name Column -->
        <ng-container matColumnDef="years">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Years at WBHS </th>
          <td mat-cell *matCellDef="let element" class="years-cell"> {{element.firstyear + ' to ' + (element.lastyear === null ? '?' : element.lastyear)}} </td>
        </ng-container>

        <!-- Validated Column -->
        <ng-container matColumnDef="validated">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Validated </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon [inline]="true" *ngIf="element.validated">check</mat-icon>
          </td>
        </ng-container>

        <!-- WBHS Old Boy Member Column -->
        <ng-container matColumnDef="wbhsOldBoy">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Old Boys Member </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon [inline]="true" *ngIf="element.wbhsOldBoy">check</mat-icon>
          </td>
        </ng-container>

        <!-- Deceased Column -->
        <ng-container matColumnDef="deceased">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Deceased </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon [inline]="true" *ngIf="element.deceased">check</mat-icon>
          </td>
        </ng-container>

        <!-- Email on file Column -->
        <ng-container matColumnDef="emailOnFile">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Email on file </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon [inline]="true" *ngIf="element.emailOnFile">check</mat-icon>
          </td>
        </ng-container>

        <!-- Fill collumn -->
        <ng-container matColumnDef="fill">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"></th>
          <td mat-cell  *matCellDef="let element" class="years-cell"></td>
        </ng-container>

        <ng-container matColumnDef="contact">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"></th>
          <td mat-cell *matCellDef="let element">
            <button (click)="provideUpdate(element)" *ngIf="canProvideUpdate(element)"  mat-icon-button color="accent" matTooltip="Provide Update"><mat-icon>edit</mat-icon></button>
            <button (click)="contactMember(element)" *ngIf="canRequestContact(element)" mat-icon-button color="accent" matTooltip="Request Contact"><mat-icon>email</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>

<ng-template let-data #contactDialogRef>
  <h1 mat-dialog-title>Request Contact</h1>
  <div mat-dialog-content>
    We will pass on your email and phone number to {{ data.prospectName }}. We will do this via email if we have an email address or by phone if we have a current phone number. Should we be unable to contact this person we will update your contact request. Do you want to proceed?
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</ng-template>

<ng-template let-data #updateDialogRef>
  <h1 mat-dialog-title>Provide Update</h1>
  <div mat-dialog-content>
    All submitted updates to Old Boy records are checked by the administrator to ensure the validity of the provided data. Do you wish to continue and send the information to the administrator?
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</ng-template>
