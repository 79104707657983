<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">account_balance</mat-icon>Banking management
    </mat-card-title>
    <mat-card-subtitle>
      This is a page where banking related tasks can be performed by Contributors and Admins
    </mat-card-subtitle>
    <mat-card-content>

    </mat-card-content>
  </mat-card>
</div>
