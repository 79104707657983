import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';

export interface MessageBoxData {
  title: string;
  text: string;
  answers: [string];
}

@Component({
  selector: 'app-message-box',
  templateUrl: './message-box.component.html',
  styleUrls: ['./message-box.component.css']
})
export class MessageBoxComponent implements OnInit {
  title = 'Hello!';
  text = 'How are you?';
  answers = ['fantastic', 'never better', 'so so', 'dont ask'];

  constructor(public dialogRef: MatDialogRef<MessageBoxComponent>,
              @Inject(MAT_DIALOG_DATA) public data: MessageBoxData) {
    this.title = data.title ? data.title : 'Hello!';
    this.text = data.text ? data.text : 'How are you?';
    this.answers = data.answers ? data.answers : ['fantastic', 'never better', 'so so', 'dont ask'];
  }

  ngOnInit() {
  }

  selectedAnswer(answer) {
    this.dialogRef.close(answer);
  }

}
