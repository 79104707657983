import { UserService } from "./user.service";
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
var ContributorGuard = /** @class */ (function () {
    function ContributorGuard(userService) {
        this.userService = userService;
    }
    ContributorGuard.prototype.canActivate = function (next, state) {
        return this.userService.isContributorOrAdmin();
    };
    ContributorGuard.ngInjectableDef = i0.defineInjectable({ factory: function ContributorGuard_Factory() { return new ContributorGuard(i0.inject(i1.UserService)); }, token: ContributorGuard, providedIn: "root" });
    return ContributorGuard;
}());
export { ContributorGuard };
