<mat-card class="table-card">
  <mat-card-title>
    <div fxLayout="row">
      <div fxLayoutAlign="start center" fxFlex="7 0 auto" fxLayoutGap="10px">
        <mat-icon inline="true">poll</mat-icon>
        <div>Subscriptions Report - {{this.currentDate   }}</div>
      </div>
      <div fxLayoutAlign="end center" fxFlex="3 0 auto" fxLayoutGap="2px">
        <button mat-mini-fab matTooltip="Specify filter" color="accent"
          (click)="onFilter()"><mat-icon>filter_list</mat-icon></button>
        <button mat-mini-fab color="accent" (click)="onExport()"
          matTooltip="Export to CSV or PDF"><mat-icon>import_export</mat-icon></button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <div fxLayoutAlign="center center" fxFlex="6 0 auto" fxLayoutGap="12px">
         <div class="searchBar">
          <div class="flex">
            <div>
              <mat-icon>search</mat-icon>
  
            <mat-form-field class="search-bar">
              <input (keydown)="onKeydown($event)" placeholder="Name" matInput [(ngModel)]="name" #searchInput>
            </mat-form-field>
            </div>
            <mat-form-field>
              <input matInput [matDatepicker]="picker1" placeholder="From Payment Date" [(ngModel)]="paymentFromDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="picker2" placeholder="To Payment Date" [(ngModel)]="paymentToDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="picker3" placeholder="From Renewed to date " [(ngModel)]="renewedFromDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
              <mat-datepicker #picker3></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="picker4" placeholder="To Renewed to date " [(ngModel)]="renewedToDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
              <mat-datepicker #picker4></mat-datepicker>
            </mat-form-field>
           
          </div>
         <div class="lowerSearchBar">
          <div>
            
            <mat-form-field>
              <mat-select placeholder="Payment Method" [(ngModel)]="payment_method">
                <mat-option [value]="">All Payment Method</mat-option>
                <mat-option *ngFor="let method of paymentMethods" [value]="method.id">{{ method.name }}</mat-option>
              </mat-select>

            </mat-form-field>
          </div>
         <div  >
          <mat-form-field>
            <mat-select placeholder=" Subscription Type" [(ngModel)]="subscription_category">
              <!-- <mat-option [value]="">All Sub</mat-option> -->
              <mat-option  [value]="">ALl Subscription Types</mat-option>
              <mat-option *ngFor="let category of subscriptionCategories" [value]="category.id">{{ category.name
                }}</mat-option>
            </mat-select>
          </mat-form-field>
         </div>
          <button mat-raised-button color="primary" (click)="onRefresh()">Search</button>
         </div>
         </div>
        <!-- <div *ngIf="retrieved > 0 && retrieved < 2000">showing {{retrieved}} records</div>
        <div *ngIf="retrieved > 0 && retrieved == 2000">showing {{retrieved}} of {{length}} records</div> -->
      </div>
      <div fxFlex="4 0 auto"></div>

    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="mat-elevation-z8" [class.hidden]="loading" >
      <table mat-table [dataSource]="dataSource" matSort #table>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('name')"> Name </th>
          <td mat-cell *matCellDef="let element"   > {{element.Prospect.firstname + ' ' + element.Prospect.lastname}} </td>
        </ng-container>

        <!-- Start Date Column -->
        <ng-container matColumnDef="startDate">
          <th  class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('startDate')"> Payment Date </th>
          <td mat-cell *matCellDef="let element"  > {{element.payment_date | formatDate}} </td>
        </ng-container>
        <ng-container matColumnDef="renewed_to">
          <th  class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('renewed_to')"> Renewed To Date </th>
          <td mat-cell *matCellDef="let element"  > {{element.renewed_to | formatDate}} </td>
        </ng-container>

        <!-- Year Column -->
        <ng-container matColumnDef="year">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('year')"> Year </th>
          <td mat-cell *matCellDef="let element"  > {{element.Prospect.firstyear}} </td>
        </ng-container>

        <!-- Receipt Number Column -->
        <ng-container matColumnDef="receiptNumber">
          <th  class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('receiptNumber')"> Receipt Number </th>
          <td mat-cell *matCellDef="let element" class="center-align"  > {{element.id}} </td>
        </ng-container>

        <!-- Category Column -->
        <ng-container matColumnDef="category">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('category')"> Subscription  Type </th>
          <td mat-cell *matCellDef="let element" class="center-align"  > {{element.SubscriptionCategory?.name}} </td>
        </ng-container>

        <!-- Payment Method Column -->
        <ng-container matColumnDef="paymentMethod">
          <th  class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('paymentMethod')"> Payment Method </th>
          <td mat-cell *matCellDef="let element" class="center-align"  > {{element.PaymentMethod?.name}} </td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('amount')"> Amount </th>
          <td mat-cell *matCellDef="let element"  > {{element.paid}} </td>
        </ng-container>

        <!-- Note Column -->
        <ng-container matColumnDef="note">
          <th  class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('note')"> Note </th>
          <td mat-cell *matCellDef="let element"  > {{element.comment}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>

  </mat-card-content>
</mat-card>