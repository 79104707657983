<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-header>
      <div fxLayout="row" fxFlex>
        <div fxLayout="column" fxFlex="1 0 auto">
          <mat-card-title>
            <mat-icon>card_membership</mat-icon>&nbsp;&nbsp;{{ pageTitle }}
          </mat-card-title>
          <mat-card-subtitle>
            Click on the Type column to view details.
          </mat-card-subtitle>
        </div>
        <div fxLayout="column" fxLayoutAlign="center center" fxFlexFill>
          <div fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="center center">
            <div class="filter">
              <mat-label>Type</mat-label>
              <mat-select [(ngModel)]="selectedType" name="type" (selectionChange)="onTypeChanged($event)">
                <mat-option *ngFor="let type of types" [value]="type">
                  {{type}}
                </mat-option>
              </mat-select>
            </div>
            <div class="filter">
              <mat-label>Status</mat-label>
              <mat-select [(ngModel)]="selectedStatus" name="status" (selectionChange)="onStatusChanged($event)">
                <mat-option *ngFor="let status of statuses" [value]="status">
                  {{status}}
                </mat-option>
              </mat-select>
            </div>
          </div>
        </div>
      </div>
    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading"></mat-spinner>
      <table mat-table [dataSource]="dataSource" *ngIf="!loading && (dataSource.length > 0)">
        <!-- Position Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell class="clickable" (click)="showDetails(element)" *matCellDef="let element"> {{prettifyType(element.type)}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element"> {{prettifyStatus(element.status)}} </td>
        </ng-container>

        <!-- From User Column -->
        <ng-container matColumnDef="fromUser">
          <th mat-header-cell *matHeaderCellDef>From</th>
          <td mat-cell *matCellDef="let element"> {{getUserName(element)}} </td>
        </ng-container>

        <!-- From User Column -->
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef>Email</th>
          <td mat-cell *matCellDef="let element"> {{element.fromUser}} </td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef>Created At</th>
          <td mat-cell *matCellDef="let element"> {{prettifyTime(element.createdAt)}} </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="updatedAt">
          <th mat-header-cell *matHeaderCellDef>Updated At</th>
          <td mat-cell *matCellDef="let element"> {{prettifyTime(element.updatedAt)}} </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef>Notes</th>
          <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
        </ng-container>

        <ng-container matColumnDef="statusControls">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="editNotes(element)" color="accent" matTooltip="Edit Notes"><mat-icon>notes</mat-icon></button>
            <button mat-icon-button *ngIf="showAwaiting(element)" (click)="awaitRequest(element, null)" color="accent" matTooltip="Awaiting Input"><mat-icon>hourglass_empty</mat-icon></button>
            <button mat-icon-button *ngIf="showApprove(element)" (click)="approveRequest(element, null)" color="accent" matTooltip="Approve"><mat-icon>done</mat-icon></button>
            <button mat-icon-button *ngIf="showReject(element)" (click)="rejectRequest(element, null)" color="warn" matTooltip="Reject"><mat-icon>close</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>
<ng-template #dialogRef>
  <h1 mat-dialog-title>Edit Notes</h1>
  <div mat-dialog-content>
    <div fxFlex="1 0 auto">
      <mat-form-field [style.fontSize]="'14px'" class="long">
        <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5" name="notes" [(ngModel)]="selectedNotes" maxlength="255"></textarea>
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Save</button>
    <button mat-button [mat-dialog-close]="false">Cancel</button>
  </div>
</ng-template>

<ng-template let-data #detailsDialogRef>
  <mat-toolbar [color]="['primary']" mat-dialog-title fxLayout="row">
    <div fxFlex="95"  [ngSwitch]="data.request.type">
      <ng-container *ngSwitchCase="'INFORMATION_SUBMISSION'">Information Submission Request</ng-container>
      <ng-container *ngSwitchCase="'IDENTITY_CLAIM'">Record Claim Request</ng-container>
      <ng-container *ngSwitchCase="'CONTACT_REQUEST'">Contact Request</ng-container>
      <ng-container *ngSwitchDefault>Request</ng-container>
    </div>
    <div fxFlex><button mat-mini-fab mat-dialog-close matTooltip="Close Dialog">X</button></div>
  </mat-toolbar>
  <div mat-dialog-content [ngSwitch]="data.request.type">
    <div fxLayout="column" fxFlex="1 0 auto">
      <div fxLayout="row" fxFlex="1 0 auto" *ngSwitchCase="'INFORMATION_SUBMISSION'" fxLayoutAlign="center center">
        <table class="info-table" *ngIf="isUserContributorOrAdmin()">
          <caption>
            User <b [innerHTML]="getUserInfo(data.request)"></b> is submitting information about Old Boy <b>{{ getInformationSubmissionTarget(data.request) }}</b> with a note: <b>"{{data.request.data.notes}}"</b> and has supplied the following information:
          </caption>
          <tr>
            <th scope="col">Field Name</th>
            <th scope="col">User Input</th>
            <th scope="col" *ngIf="data.request.oldboy">On Record</th>
            <th scope="col" *ngIf="data.request.oldboy">Update Record</th>
          </tr>
          <tr *ngFor="let row of entries(data.request.data)">
            <ng-container *ngIf="row[1] && row[0] !== 'id' && row[0] !== 'notes'">
              <th scope="row">{{getFieldName(row[0])}}</th>
              <td>{{row[1]}}</td>
              <td *ngIf="data.request.oldboy">{{getExistingData(row[0], data.request)}}</td>
              <td *ngIf="data.request.oldboy" (click)="onAcceptToggle(row[0])"><mat-checkbox [checked]="accept[row[0]]" ></mat-checkbox></td>
            </ng-container>
          </tr>
          <tr>
            <th scope="row">UpdatedAt</th>
            <td>{{getDate(getExistingData('updatedAt', data.request)) | date }}</td>
          </tr>
          <tr>
            <th scope="row">UpdatedBy</th>
            <td>{{ getExistingData('updatedBy', data.request) }}</td>
          </tr>
        </table>
        <table class="info-table" *ngIf="!isUserContributorOrAdmin()">
          <caption>
            Request for submitting information about Old Boy <b>{{ getInformationSubmissionTarget(data.request) }}</b> is currently in status <b>{{data.request.status}}</b>
          </caption>
        </table>
      </div>
      <div fxLayout="row" fxFlex="1 0 auto" *ngSwitchCase="'IDENTITY_CLAIM'" fxLayoutAlign="center center">
        <table class="info-table" *ngIf="isUserContributorOrAdmin()">
          <caption>User <b [innerHTML]="getIdentityClaimUserInfo(data.request)"></b> is making a claim that they are Old Boy <b [innerHTML]="getIdentityClaimOldBoyInfo(data.request)"></b> with the following information on record:</caption>
          <tr>
            <th scope="col">Field Name</th>
            <th scope="col" *ngIf="data.request.oldboy">On Record</th>
            <th scope="col" *ngIf="data.request.oldboy">From User</th>
          </tr>
          <tr *ngFor="let row of entries(data.request.oldboy)" [ngSwitch]="row[0]">
            <ng-container *ngIf="row[1] && row[0] !== 'id'">
              <th scope="row">{{getFieldName(row[0])}}</th>
              <td *ngSwitchCase="'emailaddress'"><a [href]="'mailto:' + data.request.oldboy['emailaddress']" target="_blank">{{data.request.oldboy['emailaddress']}}</a></td>
              <td *ngSwitchCase="'deceasedate'">{{getDate(data.request.oldboy['deceasedate']) | date }}</td>
              <td *ngSwitchCase="'birthdate'">{{getDate(data.request.oldboy['birthdate']) | date }}</td>
              <td *ngSwitchCase="'updated'">{{getDate(data.request.oldboy['updated']) | date }}</td>
              <td *ngSwitchCase="'updatedBy'">{{ getExistingData('updatedBy', data.request) }}</td>
              <td *ngSwitchCase="'createdAt'">{{getDate(data.request.oldboy['createdAt']) | date }}</td>
              <td *ngSwitchCase="'updatedAt'">{{getDate(getExistingData('updatedAt', data.request)) | date }}</td>
              <td *ngSwitchDefault>{{getExistingData(row[0], data.request)}}</td>
              <td *ngSwitchCase="'birthdate'">{{getDate(getSuppliedData(getFieldNameForRegistrationName(row[0]), data.request)) | date}}</td>
              <td *ngSwitchCase="'emailaddress'"><a [href]="'mailto:' + getSuppliedData(getFieldNameForRegistrationName(row[0]), data.request)" target="_blank">{{getSuppliedData(getFieldNameForRegistrationName(row[0]), data.request)}}</a></td>
              <td *ngSwitchDefault>{{getSuppliedData(getFieldNameForRegistrationName(row[0]), data.request)}}</td>
            </ng-container>
          </tr>
        </table>
        <table class="info-table" *ngIf="!isUserContributorOrAdmin()">
          <caption>Your Record Claim request is currently in status <b>{{data.request.status}}</b></caption>
        </table>
      </div>
      <div fxLayout="row" fxFlex="1 0 auto" *ngSwitchCase="'CONTACT_REQUEST'" fxLayoutAlign="center center">
        <table class="info-table" *ngIf="isUserContributorOrAdmin()">
          <caption>User <b [innerHtml]="getUserInfo(data.request)"></b> is making a request to contact Old Boy: <b [innerHtml]="getContactRequestTargetInfo(data.request)"></b> who has
            <b *ngIf="!data.request.oldboy || !data.request.oldboy.validated">
              not
            </b>
            been validated and for whom there is the following information on record:
          </caption>
          <tr>
            <th scope="col">Field Name</th>
            <th scope="col" *ngIf="data.request.oldboy">On Record</th>
          </tr>
          <tr *ngFor="let row of entries(data.request.oldboy)" [ngSwitch]="row[0]">
              <th scope="row">{{getFieldName(row[0])}}</th>
              <td *ngSwitchCase="'emailaddress'"><a [href]="'mailto:' + data.request.oldboy['emailaddress']" target="_blank">{{data.request.oldboy['emailaddress']}}</a></td>
              <td *ngSwitchCase="'birthdate'">{{getDate(data.request.oldboy['birthdate']) | date }}</td>
              <td *ngSwitchCase="'updated'">{{getDate(data.request.oldboy['updated']) | date }}</td>
              <td *ngSwitchCase="'updatedBy'">{{ getExistingData('updatedBy', data.request) }}</td>
              <td *ngSwitchCase="'createdAt'">{{getDate(data.request.oldboy['createdAt']) | date }}</td>
              <td *ngSwitchCase="'updatedAt'">{{getDate(getExistingData('updatedAt', data.request)) | date }}</td>
              <td *ngSwitchDefault>{{getExistingData(row[0], data.request)}}</td>
          </tr>
        </table>
        <table class="info-table" *ngIf="!isUserContributorOrAdmin()">
          <caption>Your Contact Request is currently in status <b>{{data.request.status}}</b></caption>
        </table>
      </div>
      <ng-container *ngSwitchDefault>Unsupported Request Type</ng-container>
      <div fxLayout="row" fxFlex="1 0 auto" *ngIf="isUserContributorOrAdmin()">
        <mat-form-field style="width: 100%">
          <mat-label>Notes - visible to the request submitter</mat-label>
          <textarea matInput name="notes" [(ngModel)]="data.notes" maxlength="255"></textarea>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
      <button mat-icon-button mat-dialog-close *ngIf='isUserContributorOrAdmin()' (click)="saveNotes(data.request, data.notes)" color="accent" matTooltip="Save Notes"><mat-icon>notes</mat-icon></button>
      <button mat-icon-button mat-dialog-close *ngIf="isUserContributorOrAdmin() && showAwaiting(data.request)" (click)="awaitRequest(data.request, data.notes)" color="accent" matTooltip="Awaiting Input"><mat-icon>hourglass_empty</mat-icon></button>
      <button mat-icon-button mat-dialog-close *ngIf="isUserContributorOrAdmin() && showApprove(data.request)" (click)="approveRequest(data.request, data.notes)" color="accent" matTooltip="Approve"><mat-icon>done</mat-icon></button>
      <button mat-icon-button mat-dialog-close *ngIf="isUserContributorOrAdmin() && showReject(data.request)" (click)="rejectRequest(data.request, data.notes)" color="accent" matTooltip="Reject"><mat-icon>close</mat-icon></button>
  </div>
</ng-template>
