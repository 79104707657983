

<mat-card class="table-card" >
  <mat-card-title>
    <div fxLayout="row">
      <div fxLayoutAlign="start center" fxFlex="7 0 auto" fxLayoutGap="10px">
        <mat-icon inline="true">poll</mat-icon>
        <div>Reports</div>
      </div>
     
    </div>
  </mat-card-title>
  <mat-card-content>
    
    <div fxFlex="30%">
      <mat-form-field appearance="fill" style="width: 100%">
        <mat-label>Select Report Type</mat-label>
        <mat-select (selectionChange)="navigateToReport($event.value)">
          <mat-option value="/payment-report">Subscriptions Report</mat-option>
          <mat-option value="/donation-report">Donations and Payment Report</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </mat-card-content>

</mat-card>
