import { OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
var SignupComponent = /** @class */ (function () {
    function SignupComponent(auth, snackBar, router) {
        this.auth = auth;
        this.snackBar = snackBar;
        this.router = router;
        this.submitted = false;
        this.loading = false;
        this.form = new FormGroup({
            email: new FormControl('', [Validators.required, Validators.email]),
            password: new FormControl('', [Validators.required, Validators.minLength(6)]),
            passwordConfirmation: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            dateOfBirth: new FormControl('', [Validators.required]),
            streetAddress: new FormControl(''),
            suburb: new FormControl(''),
            town: new FormControl(''),
            province: new FormControl(''),
            postCode: new FormControl(''),
            country: new FormControl(''),
            homePhone: new FormControl(''),
            workPhone: new FormControl(''),
            mobilePhone: new FormControl(''),
            house: new FormControl(''),
            fromYear: new FormControl('', [Validators.required]),
            toYear: new FormControl('', [Validators.required])
        }, this.confirmPassword);
    }
    SignupComponent.prototype.ngOnInit = function () {
    };
    SignupComponent.prototype.onRegister = function () {
        var _this = this;
        this.submitted = true;
        if (this.form.invalid) {
            var errmsg = this.errors.join('\n');
            this.openSnackBar(errmsg, 'Dismiss');
            return;
        }
        this.loading = true;
        this.auth.register(this.form.value)
            .pipe(finalize(function () { return _this.loading = false; }))
            .subscribe(function (res) {
            if (res['success']) {
                _this.openSnackBar(res['msg'], 'Dismiss');
                _this.onClear();
                _this.submitted = false;
                _this.router.navigate(['/login']);
            }
            else {
                _this.openSnackBar(res['error']['msg'], 'Dismiss');
            }
            console.log(res);
        });
    };
    SignupComponent.prototype.onClear = function () {
        this.form.reset();
    };
    SignupComponent.prototype.openSnackBar = function (message, action) {
        this.snackBar.open(message, action, {
            duration: 100000,
            panelClass: 'snackbar-style'
        });
    };
    Object.defineProperty(SignupComponent.prototype, "firstNameError", {
        get: function () {
            return this.form.controls.firstName.hasError('required')
                ? 'First Name is missing.'
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "lastNameError", {
        get: function () {
            return this.form.controls.lastName.hasError('required')
                ? 'Last Name is missing.'
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "dateOfBirthError", {
        get: function () {
            return this.form.controls.dateOfBirth.hasError('required')
                ? 'Date of Birth is missing.'
                : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "emailError", {
        get: function () {
            return this.form.controls.email.hasError('email')
                ? 'Email is not valid.'
                : this.form.controls.email.hasError('required')
                    ? 'Email is missing.'
                    : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "passwordError", {
        get: function () {
            return this.form.controls.password.hasError('minlength')
                ? 'Password is not at least 8 characters long.'
                : this.form.controls.password.hasError('required')
                    ? 'Password is missing.'
                    : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "confirmPasswordError", {
        get: function () {
            return this.form.controls.passwordConfirmation.hasError('mismatch')
                ? 'Passwords do not match.'
                : this.form.controls.passwordConfirmation.hasError('required')
                    ? 'Password confirmation is missing.'
                    : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "fromYearError", {
        get: function () {
            return this.form.controls.fromYear.hasError('required') ? 'From Year is missing' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "toYearError", {
        get: function () {
            return this.form.controls.toYear.hasError('required') ? 'To Year is missing' : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SignupComponent.prototype, "errors", {
        get: function () {
            var errors = [];
            if (this.emailError) {
                errors.push(this.emailError);
            }
            if (this.passwordError) {
                errors.push(this.passwordError);
            }
            if (this.confirmPasswordError) {
                errors.push(this.confirmPasswordError);
            }
            if (this.firstNameError) {
                errors.push(this.firstNameError);
            }
            if (this.lastNameError) {
                errors.push(this.lastNameError);
            }
            if (this.dateOfBirthError) {
                errors.push(this.dateOfBirthError);
            }
            if (this.fromYearError) {
                errors.push(this.fromYearError);
            }
            if (this.toYearError) {
                errors.push(this.toYearError);
            }
            return errors;
        },
        enumerable: true,
        configurable: true
    });
    SignupComponent.prototype.confirmPassword = function (group) {
        var valid = group.controls.password.value === group.controls.passwordConfirmation.value;
        if (!valid) {
            group.controls.passwordConfirmation.setErrors({ mismatch: true });
        }
        return valid ? null : { mismatch: true };
    };
    return SignupComponent;
}());
export { SignupComponent };
