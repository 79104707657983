import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import {Service} from './service';

import { Observable } from 'rxjs';
import { catchError, tap  } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminService extends Service {
  endpoint = this.getEndpoint('admin');
  constructor(private http: HttpClient) {
    super();
  }
  public getUsers(): Observable<[UserAccount]> {
    return this.http.get(this.endpoint + '/users').pipe(
      catchError(this.handleError('users', []))
    );
  }

  public updateDetails(id, params): Observable<any> {
    return this.http.put(this.endpoint + '/users/' + id, params).pipe(
      catchError(this.handleError('update user', []))
    );
  }

  public invite(name, email, type): Observable<any> {
    return this.http.post(this.endpoint + '/users', {name: name, email: email, type: type}).pipe(
      catchError(this.handleError('update user', []))
    );
  }

  public putUserDetails(id, data) {
    return this.http.put(this.endpoint + '/users/' + id + '/details', data).pipe(
      catchError(this.handleError('user',[]))
    )
  }

  public deleteUser(id){
    return this.http.delete(this.endpoint + '/users/' + id).pipe(
      catchError(this.handleError('user',[]))
    )
  }

}

export interface UserAccount {
  id: string;
  username: string;
  name: string;
  email: string;
  type: string;
  validated: boolean;
  disabled: boolean;
  data: object;
}
