<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <div fxLayout="row">
        <div fxLayoutAlign="start center" fxFlex="7 0 auto" fxLayoutGap="10px">
          <mat-icon inline="true">people</mat-icon>
          <div>Old Boys Management</div>
        </div>
        <div fxLayoutAlign="end center"  fxFlex="3 0 auto"  fxLayoutGap="2px">
          <button mat-mini-fab matTooltip="Specify filter" color="accent" (click)="onFilter()"><mat-icon>filter_list</mat-icon></button>
          <button mat-mini-fab color="accent" (click)="onColumns()"  matTooltip="Choose columns"><mat-icon>view_column</mat-icon></button>
          <button mat-mini-fab color="accent" (click)="onRefresh()"   matTooltip="Refresh"><mat-icon>autorenew</mat-icon></button>
          <button mat-mini-fab color="accent" (click)="onExport()"   matTooltip="Export to CSV or PDF"><mat-icon>import_export</mat-icon></button>
          <button mat-mini-fab color="accent" (click)="onPrint()"  matTooltip="Print"><mat-icon>local_printshop</mat-icon></button>
          <button mat-mini-fab color="accent" (click)="onAdd()"  matTooltip="Add new record"><mat-icon>add</mat-icon></button>
        </div>
      </div>
    </mat-card-title>
    <mat-card-header>
      <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
        <div fxLayoutAlign="center center" fxFlex="6 0 auto" fxLayoutGap="12px">
          <mat-icon>search</mat-icon>
          <mat-form-field class="search-bar">
            <input  (keydown)="onKeydown($event)" placeholder="Last name" matInput [(ngModel)]="query" #searchInput>
          </mat-form-field>
          <mat-form-field>
            <input type="number" min="1900" max="2100"
                   (keydown)="onYearKeydown($event)"
                   placeholder="Class year"
                   aria-label="year" matInput [formControl]="searchYear" [matAutocomplete]="auto" #searchInputYear>
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
              <mat-option *ngFor="let year of filteredOptions | async" [value]="year">
                {{year}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field>
            <mat-label>Active filter</mat-label>
            <mat-select [(value)]="filterName" (selectionChange)="onFilterChanged()">
              <mat-option *ngFor="let name of filterNames" [value]="name">{{name}}</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-raised-button color="primary" (click)="onRefresh()">Search</button>
          <div *ngIf="retrieved > 0 && retrieved < 5000">showing {{retrieved}} records</div>
          <div *ngIf="retrieved > 0 && retrieved == 5000">showing {{retrieved}} of {{length}} records</div>
        </div>
        <div fxFlex="4 0 auto"></div>

      </div>
    </mat-card-header>
    <mat-card-content fxLayout="column">
      <mat-spinner *ngIf="loading"></mat-spinner>
      <div class="table-container oldboy-table" *ngIf="!loading">
        <table mat-table #table [dataSource]="dataSource" matSort [matSortActive]="sortby" [matSortDirection]="sortdirection" (matSortChange)="sortData($event)">

          <!-- ID Column -->
          <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> ID </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.id}} </td>
          </ng-container>

          <!-- validated Column -->
          <ng-container matColumnDef="validated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Validated </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.validated)}} </td>
          </ng-container>

          <!-- unsubscribed Column -->
          <ng-container matColumnDef="unsubscribed">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Unsubscribed </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.unsubscribed)}} </td>
          </ng-container>

          <!-- deceased Column -->
          <ng-container matColumnDef="deceased">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Deceased </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.deceased)}} </td>
          </ng-container>

          <!-- deceasedate Column -->
          <ng-container matColumnDef="deceasedate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Decease date </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.deceasedate | date}} </td>
          </ng-container>

          <!-- alumnimember Column -->
          <ng-container matColumnDef="alumnimember">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> OBA member </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.alumnimember)}} </td>
          </ng-container>

          <!-- label Column -->
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Label </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.label}} </td>
          </ng-container>

          <!-- capacitycategory Column -->
          <ng-container matColumnDef="capacitycategory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Capacity category </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.capacitycategory}} </td>
          </ng-container>

          <!-- relationshipcategory Column -->
          <ng-container matColumnDef="relationshipcategory">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Relationship category </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.relationshipcategory}} </td>
          </ng-container>

          <!-- gender Column -->
          <ng-container matColumnDef="gender">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Gender </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.gender}} </td>
          </ng-container>

          <!-- title Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.title}} </td>
          </ng-container>

          <!-- initials Column -->
          <ng-container matColumnDef="initials">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Initials </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.initials}} </td>
          </ng-container>


          <!-- First name Column -->
          <ng-container matColumnDef="firstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First name </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.firstname}} </td>
          </ng-container>

          <!-- Middle name Column -->
          <ng-container matColumnDef="middlenames">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Middle name </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.middlenames}} </td>
          </ng-container>

          <!-- Last name Column -->
          <ng-container matColumnDef="lastname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last name </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.lastname}} </td>
          </ng-container>

          <!-- Salutation Column -->
          <ng-container matColumnDef="salutation">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Salutation </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.salutation}} </td>
          </ng-container>

          <!-- Maiden name Column -->
          <ng-container matColumnDef="maidenname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Maiden name </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.maidenname}} </td>
          </ng-container>

          <!-- Birth date Column -->
          <ng-container matColumnDef="birthdate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Birth date </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.birthdate | date}} </td>
          </ng-container>

          <!-- Address 1 Column -->
          <ng-container matColumnDef="address1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 1 </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.address1}} </td>
          </ng-container>

          <!-- Address 2 Column -->
          <ng-container matColumnDef="address2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Address 2 </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.address2}} </td>
          </ng-container>

          <!-- Suburb Column -->
          <ng-container matColumnDef="suburb">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Suburb </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.suburb}} </td>
          </ng-container>

          <!-- Town city Column -->
          <ng-container matColumnDef="town_city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Town/city </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.town_city}} </td>
          </ng-container>

          <!-- Province state Column -->
          <ng-container matColumnDef="province_state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Province/state </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.province_state}} </td>
          </ng-container>

          <!-- Postal code Column -->
          <ng-container matColumnDef="postal_code">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Postal code </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.postalcode}} </td>
          </ng-container>

          <!-- Country Column -->
          <ng-container matColumnDef="country">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Country </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.country}} </td>
          </ng-container>

          <!-- Home telephone Column -->
          <ng-container matColumnDef="hometelephone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Home telephone </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.hometelephone}} </td>
          </ng-container>

          <!-- Mobile telephone Column -->
          <ng-container matColumnDef="mobiletelephone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Mobile telephone </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.mobiletelephone}} </td>
          </ng-container>

          <!-- Fax number Column -->
          <ng-container matColumnDef="faxnumber">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fax number </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.faxnumber}} </td>
          </ng-container>

          <!-- Email address Column -->
          <ng-container matColumnDef="emailaddress">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email address </th>
            <td mat-cell *matCellDef="let element" class="clickable">
              <a href="mailto:{{element.emailaddress}}?subject=Message from WBHSOBA">{{element.emailaddress}}</a>
            </td>
          </ng-container>

          <!-- Email address2 Column -->
          <ng-container matColumnDef="emailaddress2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Email address 2</th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="contactEmail(element.emailaddress2)">
              {{element.emailaddress2}}
            </td>
          </ng-container>

          <!-- Employer Column -->
          <ng-container matColumnDef="employer">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Employer </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.employer}} </td>
          </ng-container>

          <!-- Profession jobtitle Column -->
          <ng-container matColumnDef="profession_jobtitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Profession/job title </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.profession_jobtitle}} </td>
          </ng-container>

          <!-- Qualification level Column -->
          <ng-container matColumnDef="qualificationlevel">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Qualification level </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.qualificationlevel}} </td>
          </ng-container>

          <!-- Career notes Column -->
          <ng-container matColumnDef="careernotes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Career notes </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.careernotes}} </td>
          </ng-container>

          <!-- Work telephone Column -->
          <ng-container matColumnDef="worktelephone">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work telephone </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.worktelephone}} </td>
          </ng-container>

          <!-- Preferred contact method Column -->
          <ng-container matColumnDef="preferredcontactmethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Preferred contact method </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.preferredcontactmethod}} </td>
          </ng-container>

          <!-- Second contact method Column -->
          <ng-container matColumnDef="secondcontactmethod">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Second contact method </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.secondcontactmethod}} </td>
          </ng-container>

          <!-- Work address 1 Column -->
          <ng-container matColumnDef="workaddress1">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work address 1 </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.workaddress1}} </td>
          </ng-container>

          <!-- Work address 2 Column -->
          <ng-container matColumnDef="workaddress2">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work address 2 </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.workaddress2}} </td>
          </ng-container>

          <!-- Work suburb Column -->
          <ng-container matColumnDef="worksuburb">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work suburb </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{element.worksuburb}} </td>
          </ng-container>

          <!-- Work town/city Column -->
          <ng-container matColumnDef="worktown_city">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work town/city </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.worktown_city}} </td>
          </ng-container>

          <!-- Work province/state Column -->
          <ng-container matColumnDef="workprovince_state">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work province/state </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.workprovince_state}} </td>
          </ng-container>

          <!-- Work postal code Column -->
          <ng-container matColumnDef="workpostalcode">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work postal code </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.workpostalcode}} </td>
          </ng-container>

          <!-- Work country Column -->
          <ng-container matColumnDef="workcountry">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Work country </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.workcountry}} </td>
          </ng-container>

          <!-- Hose name Column -->
          <ng-container matColumnDef="housename">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> House name </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.housename}} </td>
          </ng-container>

          <!-- First year Column -->
          <ng-container matColumnDef="firstyear">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First year </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.firstyear}} </td>
          </ng-container>

          <!-- Last year Column -->
          <ng-container matColumnDef="lastyear">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last year </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.lastyear}} </td>
          </ng-container>

          <!-- Border Column -->
          <ng-container matColumnDef="border">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Boarder </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.border)}} </td>
          </ng-container>

          <!-- From year Column -->
          <ng-container matColumnDef="fromyear">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> From year </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.fromyear}} </td>
          </ng-container>

          <!-- To year Column -->
          <ng-container matColumnDef="toyear">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> To year </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.toyear}} </td>
          </ng-container>

          <!-- Organisational notes Column -->
          <ng-container matColumnDef="organisationalnotes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Organisational notes </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.organisationalnotes}} </td>
          </ng-container>

          <!-- Marital status Column -->
          <ng-container matColumnDef="maritalstatus">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Marital status </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.maritalstatus}} </td>
          </ng-container>

          <!-- Parent partner title Column -->
          <ng-container matColumnDef="parent_partnertitle">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner title </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.parent_partnertitle}} </td>
          </ng-container>

          <!-- Parent partner initials Column -->
          <ng-container matColumnDef="parent_partnerinitials">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner initials </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.parent_partnerinitials}} </td>
          </ng-container>

          <!-- Parent partner firstname Column -->
          <ng-container matColumnDef="parent_partnerfirstname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner first name </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.parent_partnerfirstname}} </td>
          </ng-container>

          <!-- Parent partner lastname Column -->
          <ng-container matColumnDef="parent_partnerlastname">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner last name </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.parent_partnerlastname}} </td>
          </ng-container>

          <!-- Partner interests Column -->
          <ng-container matColumnDef="partnerinterests">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Partner interests </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.partnerinterests}} </td>
          </ng-container>

          <!-- Children's names Column -->
          <ng-container matColumnDef="childrensnames">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Children's names </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.childrensnames}} </td>
          </ng-container>

          <!-- Volunteer 1 id Column -->
          <ng-container matColumnDef="volunteer1id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Volunteer 1 ID </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.volunteer1id}} </td>
          </ng-container>

          <!-- Volunteer 2 id Column -->
          <ng-container matColumnDef="volunteer2id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Volunteer 2 ID </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.volunteer2id}} </td>
          </ng-container>

          <!-- Fundraising notes Column -->
          <ng-container matColumnDef="fundraisingnotes">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fundraising notes </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.fundraisingnotes}} </td>
          </ng-container>

          <!-- First class Column -->
          <ng-container matColumnDef="firstclass">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> First class </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.firstclass}} </td>
          </ng-container>

          <!-- Last class Column -->
          <ng-container matColumnDef="lastclass">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Last class </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.lastclass}} </td>
          </ng-container>

          <!-- Updated Column -->
          <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.updated | date:'medium'}} </td>
          </ng-container>

          <!-- Loaded Column -->
          <ng-container matColumnDef="loaded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Loaded </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.loaded)}} </td>
          </ng-container>

          <!-- Buddy Column -->
          <ng-container matColumnDef="buddy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Buddy </th>
            <td mat-cell *matCellDef="let element"   class="clickable" (click)="onCellClicked(element)"> {{element.buddy}} </td>
          </ng-container>

          <!-- wbhsOldBoy Column -->
          <ng-container matColumnDef="wbhsOldBoy">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> WBHS Old Boy </th>
            <td mat-cell *matCellDef="let element" class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.wbhsOldBoy)}} </td>
          </ng-container>

          <!-- Created at Column -->
          <ng-container matColumnDef="createdAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Created at </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.createdAt | date:'medium'}} </td>
          </ng-container>

          <!-- Updated at Column -->
          <ng-container matColumnDef="updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Updated at </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{element.updatedAt | date:'medium'}} </td>
          </ng-container>

          <ng-container matColumnDef="isLinked">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Linked to a Member </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{getBooleanDisplayValue(element.isLinked)}} </td>
          </ng-container>

          <ng-container matColumnDef="recordType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Record Type </th>
            <td mat-cell *matCellDef="let element"  class="clickable" (click)="onCellClicked(element)"> {{getRecords(element)}} </td>
          </ng-container>

          <!-- Actions Column -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Actions </th>
            <td mat-cell *matCellDef="let element"  class="clickable">
              <button mat-mini-fab color="primary" (click)="onEdit(element)"><mat-icon>edit</mat-icon></button>
              <button mat-mini-fab color="warn" (click)="onDelete(element)"><mat-icon>delete</mat-icon></button>
              <!-- <mat-slide-toggle
                color="primary"
                (change)="onEnabledChange($event,element)"
                [checked]="!element.disabled">
              </mat-slide-toggle> -->
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
    </mat-card-content>
  </mat-card>
</div>
