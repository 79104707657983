<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="form-card">
    <form [formGroup]="detailsForm"  (ngSubmit)="onSubmitDetails()">

      <mat-card-title>
        Details
      </mat-card-title>

      <mat-card-content fxLayout="column">
        <mat-form-field>
          <input matInput placeholder="Username" type="text" formControlName="username">
        </mat-form-field>

        <mat-form-field>
          <input matInput placeholder="Email" type="email" formControlName="email">
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" [disabled]="!detailsForm.valid">Save</button>
      </mat-card-actions>

    </form>
  </mat-card>

  <mat-card  class="form-card">
    <form [formGroup]="passwordForm"  (ngSubmit)="onSubmitPassword()">

      <mat-card-title>
        Password
      </mat-card-title>

      <mat-card-content fxLayout="column">
        <mat-form-field>
          <input matInput type="password" placeholder="Old password" formControlName="oldPassword">
        </mat-form-field>
        <mat-form-field>
          <input matInput type="password" placeholder="New password" formControlName="newPassword">
        </mat-form-field>
        <mat-form-field>
          <input matInput type="password" placeholder="Confirm new password" formControlName="confirmNewPassword">
        </mat-form-field>
      </mat-card-content>

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" [disabled]="!passwordForm.valid">Save</button>
      </mat-card-actions>

    </form>
  </mat-card>
</div>



