/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./print-dialog.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/flex-layout/flex";
import * as i4 from "@angular/flex-layout/core";
import * as i5 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i6 from "@angular/material/button";
import * as i7 from "@angular/cdk/platform";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./print-dialog.component";
var styles_PrintDialogComponent = [i0.styles];
var RenderType_PrintDialogComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PrintDialogComponent, data: {} });
export { RenderType_PrintDialogComponent as RenderType_PrintDialogComponent };
export function View_PrintDialogComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i2.MatDialogTitle, [[2, i2.MatDialogRef], i1.ElementRef, i2.MatDialog], null, null), (_l()(), i1.ɵted(-1, null, ["Print"])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["fxLayout", "column"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 671744, null, 0, i3.DefaultLayoutDirective, [i1.ElementRef, i4.StyleUtils, [2, i3.LayoutStyleBuilder], i4.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(5, 16384, null, 0, i2.MatDialogContent, [], null, null), (_l()(), i1.ɵted(-1, null, [" todo\n"])), (_l()(), i1.ɵeld(7, 0, null, null, 7, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i2.MatDialogActions, [], null, null), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.closeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(10, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(-1, 0, ["Cancel"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["cdkFocusInitial", ""], ["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.submitDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_MatButton_0, i5.RenderType_MatButton)), i1.ɵdid(13, 180224, null, 0, i6.MatButton, [i1.ElementRef, i7.Platform, i8.FocusMonitor, [2, i9.ANIMATION_MODULE_TYPE]], { disabled: [0, "disabled"] }, null), (_l()(), i1.ɵted(-1, 0, ["Save"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_1 = "column"; _ck(_v, 4, 0, currVal_1); var currVal_6 = true; _ck(_v, 13, 0, currVal_6); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_2 = (i1.ɵnov(_v, 10).disabled || null); var currVal_3 = (i1.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 9, 0, currVal_2, currVal_3); var currVal_4 = (i1.ɵnov(_v, 13).disabled || null); var currVal_5 = (i1.ɵnov(_v, 13)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_4, currVal_5); }); }
export function View_PrintDialogComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-print-dialog", [], null, null, null, View_PrintDialogComponent_0, RenderType_PrintDialogComponent)), i1.ɵdid(1, 114688, null, 0, i10.PrintDialogComponent, [i2.MatDialogRef, i2.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PrintDialogComponentNgFactory = i1.ɵccf("app-print-dialog", i10.PrintDialogComponent, View_PrintDialogComponent_Host_0, {}, {}, []);
export { PrintDialogComponentNgFactory as PrintDialogComponentNgFactory };
