<div fxLayout="column" fxLayoutAlign="center">
  <!--
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">home</mat-icon>User's home
    </mat-card-title>
    <mat-card-subtitle>
      This is a member only accessible dashboard
    </mat-card-subtitle>
    <mat-card-content>

    </mat-card-content>
  </mat-card>
  -->
</div>
