<h1 style="font-size:20px;">Note</h1>
<mat-form-field class="notesfield" [formGroup]="noteForm">
  <textarea matInput formControlName="noteText" cdkTextareaAutosize
  #autosize="cdkTextareaAutosize"
  cdkAutosizeMinRows="1"
  cdkAutosizeMaxRows="5"></textarea>
</mat-form-field>
<div mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
  <button mat-raised-button (click)="closeDialog()"  color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog()" type="submit" color="primary">Save</button>
</div>