import {Component, Inject, OnInit} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-columns-dialog',
  templateUrl: './columns-dialog.component.html',
  styleUrls: ['./columns-dialog.component.css']
})
export class ColumnsDialogComponent implements OnInit {

  availableColumns = {};
  selectedColumns = [];
  columnNames = [];
  available = [];

  //columns = new FormControl();

  constructor(public dialogRef: MatDialogRef<ColumnsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.availableColumns = data.availableColumns || {};
    this.selectedColumns = data.selectedColumns ? data.selectedColumns.slice() : [];
    this.available =  Object.keys(this.availableColumns).filter(k => !this.selectedColumns.includes(k));
  }

  ngOnInit() {
    this.columnNames = Object.keys(this.availableColumns);
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    this.dialogRef.close(this.selectedColumns);
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
}
