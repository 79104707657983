import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OldboysService, Membership } from "../oldboys.service";

@Component({
  selector: 'app-membership-dialog',
  templateUrl: './membership-dialog.component.html',
  styleUrls: ['./membership-dialog.component.css']
})
export class MembershipDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentDateInput') paymentDateInput: ElementRef;
  @ViewChild('receiptNumberInput') receiptNumberInput: ElementRef;

  
  id = null;
  EditReceiptNumber: boolean = false;
  IsAllowSendReceipt: boolean = false;
  receiptNumber: string;
  prospect_id = null;
  membership = new FormGroup({
    id: new FormControl(),
    payment_date: new FormControl(),
    subscription_category: new FormControl(null, Validators.required),
    paid: new FormControl(null, Validators.required),
    payment_method: new FormControl(null, Validators.required),
    renewed_to: new FormControl(),
    comment: new FormControl(),
  });


  membershipCopy: any;

  subscriptionCategories: string[] = [];
  paymentMethods: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<MembershipDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oldboysService: OldboysService, // Inject the OldboysService
    private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
    private zone: NgZone // Inject NgZone
  ) {
    if (data.prospect_id) {
      this.prospect_id = data.prospect_id;
      this.getmaxid();
    }

    if (data.membership) {
      this.id = data.membership.id;
      this.membership.patchValue({
        ...data.membership,
        payment_date: data.membership.payment_date || null // Ensure payment_date has a default value
      });
      this.membership.controls.payment_method.patchValue(data.membership.payment_method_id);
      this.membership.controls.subscription_category.patchValue(data.membership.subscription_category_id);
      this.receiptNumber = data.membership.receipt_number;
      this.membershipCopy = { receipt_number: this.receiptNumber, ...this.membership.value };
    }
  }

  ngOnInit() {
    this.loadSubscriptionCategories();
    this.loadPaymentMethods();
  }

  ngAfterViewInit() {
    this.makePaymentDateFocus()
  }

  

  makePaymentDateFocus(makeReciptSeleted:boolean =false){
    this.zone.run(() => {
      this.cdr.detectChanges(); // Trigger change detection
      setTimeout(() => {
        if (this.id && !makeReciptSeleted) {
          // Editing existing record
          if (this.paymentDateInput) {
            this.paymentDateInput.nativeElement.focus();
          }
        }
        else if(this.id && !makeReciptSeleted){
          if (this.receiptNumberInput) {
            this.receiptNumberInput.nativeElement.focus();
          }
        } else {
          // Creating new record
          if (this.receiptNumberInput) {
            this.receiptNumberInput.nativeElement.focus();
          }
        }
      }, 500);
    });
  }
  
  onSubcription_Type_change(category) {
    console.log(category);
    if (category == undefined) {
      this.IsAllowSendReceipt = false;
      return;
    }
    if (category.allowSendReceipt) {
      this.IsAllowSendReceipt = true;
    } else if (category.length == 0 || category.allowSendReceipt == null || category.allowSendReceipt == false) {
      this.IsAllowSendReceipt = false;
    }
  }

  formatCurrency() {
    const paidControl = this.membership.get('paid');
    if (paidControl.value != null) {
      const formattedValue = Number.parseFloat(paidControl.value).toFixed(2);
      paidControl.setValue(formattedValue, { emitEvent: false }); // Update the value without emitting an event
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  hasObjectChanged(original, modified) {
    // Check if both are null or undefined
    if (original == null && modified == null) {
      return false;
    }
  
    // Check if one is null or undefined and the other is not
    if (original == null || modified == null) {
      return true;
    }
    // Check if both are objects
    if (typeof original !== 'object' || typeof modified !== 'object') {
      return original !== modified; // Compare values directly if not objects
    }

    const keys = Object.keys(original);
    const modifiedKeys = Object.keys(modified);
  
    if (keys.length !== modifiedKeys.length) {
      return true;
    }

    // Check all keys in the original object
    for (let key of keys) {
      if (!this.hasObjectChanged(original[key], modified[key])) {
        // Recursively check nested properties
        continue;
      }
      return true; // Found a change
    }

    return false; // No changes found
  }

  public submitDialog(remove): void {
    if (this.membership.invalid) {
      this.membership.controls.subscription_category.markAsTouched();
      this.membership.controls.payment_method.markAsTouched();
      this.membership.controls.paid.markAsTouched();
      console.log(this.membership)
      return;
    } else {
      if (remove) {
        this.dialogRef.close({ delete: true, id: this.id });
      } else {
        const membershipData: Membership = this.membership.value;
        if (this.id) {
          membershipData.id = this.id; // Set the ID if editing an existing membership
        }
        membershipData.receipt_number = this.receiptNumber

        if (!this.hasObjectChanged(this.membership.value, this.membershipCopy) && this.IsAllowSendReceipt && this.id) {
          this.IsAllowSendReceipt = false;
        }
        membershipData.subscription_category_id = this.membership.controls.subscription_category.value
        membershipData.payment_method_id = this.membership.controls.payment_method.value
        membershipData.payment_date = this.membership.controls.payment_date.value; // Ensure payment_date is included

        this.oldboysService.updateOldboyMembership(this.prospect_id, membershipData).subscribe(savedMembership => {
          console.log('Membership saved:', savedMembership);
          savedMembership.allowSendReceipt = this.IsAllowSendReceipt;
          this.dialogRef.close(savedMembership);
        });
      }
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  loadSubscriptionCategories() {
    this.oldboysService.getSubscriptionCategories().subscribe(categories => {
      this.subscriptionCategories = categories.filter((data: any) => data.deleted != true);
      let selectedcategory = this.subscriptionCategories.filter((data: any) => data.id == this.membership.controls.subscription_category.value)
      this.onSubcription_Type_change(selectedcategory[0])
    });
  }

  loadPaymentMethods() {
    this.oldboysService.getPaymentsMethods().subscribe(methods => {
      this.paymentMethods = methods.filter((data: any) => data.deleted != true);
    });
  }

  getmaxid() {
    this.oldboysService.getmaxid().subscribe((res: any) => {
      this.receiptNumber = res.id + 1;
      this.onClickEditReceiptNumber()
    });
  }

  onClickEditReceiptNumber() {
    this.EditReceiptNumber = !this.EditReceiptNumber;
    console.log(this.EditReceiptNumber)
    this.makePaymentDateFocus(true)
  }

  public deleteMembership(): void {
    // Clear the validation for the 'paid' field temporarily for delete action
    this.membership.get('paid').clearValidators();
    this.membership.get('paid').updateValueAndValidity(); // Update the validation status
    
    // Close the dialog with delete action and id
    this.dialogRef.close({ delete: true, id: this.id });
  }
}
