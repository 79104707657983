import { Observable, of } from 'rxjs';

import { environment } from '../environments/environment';

export abstract class Service {
  protected getEndpoint(path) {
    return (environment['api'] || '') + '/' + path;
  }

  protected handleError (operation = 'operation', result?: any) {
    return (error: any): Observable<any> => {
      return of(error);
    };
  }
}
