<h1 mat-dialog-title *ngIf="!id">Subscription Payment</h1>
<h1 mat-dialog-title *ngIf="id">Edit Subscription Payment</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="membership">

  <div *ngIf=" prospect_id || id" style="margin-bottom:20px">
    <label>Receipt number: </label>
    
    <span *ngIf="!EditReceiptNumber">{{ receiptNumber }} <button class="editReceiptNumberButton" (click)="onClickEditReceiptNumber()"  mat-icon-button color="accent"  type="button" matTooltip="Edit Receipt number "><mat-icon class="noteMatIcon">edit</mat-icon></button></span>
    <mat-form-field *ngIf="EditReceiptNumber" class="receiptNumberfield">
      <input matInput #receiptNumberInput  placeholder="" [(ngModel)]="receiptNumber" [ngModelOptions]="{standalone: true}" (keydown)="onKeydown($event)" >
    </mat-form-field>
    <!-- <ng-container **ngIf="!hideCross">
      <button *ngIf="EditReceiptNumber && !prospect_id " class="editReceiptNumberButton" (click)="onClickEditReceiptNumber()"  mat-icon-button color="accent"  type="button" ><mat-icon>highlight_off</mat-icon></button>

    </ng-container> -->
  </div>

  <mat-form-field>
    <input matInput #paymentDateInput [matDatepicker]="picker2" placeholder="Payment date" formControlName="payment_date" (keydown)="onKeydown($event)">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
    
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="subscription_category" placeholder="Subscription Type" >
      <mat-option *ngFor="let category of subscriptionCategories" [value]="category.id" (click)="onSubcription_Type_change(category)">{{ category.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="membership.get('subscription_category').invalid && membership.get('subscription_category').touched">
      Subscription Type is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input type="number" matInput formControlName="paid" placeholder="Amount paid" (blur)="formatCurrency()">
    <mat-error *ngIf="membership.get('paid').invalid && membership.get('paid').touched">
      Amount is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="payment_method" placeholder="Payment Method">
      <mat-option *ngFor="let method of paymentMethods" [value]="method.id">{{ method.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="membership.get('payment_method').invalid && membership.get('payment_method').touched">
      Payment Method is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input matInput [matDatepicker]="picker3" placeholder="Renewed To/Next due date" formControlName="renewed_to" (keydown)="onKeydown($event)">
    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
    <mat-datepicker #picker3></mat-datepicker>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput formControlName="comment" placeholder="Comment" (keydown)="onKeydown($event)" rows="4"></textarea>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="deleteMembership()" *ngIf="id" color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial  color="primary">Save</button>
</div>
