<h1 mat-dialog-title>Export to</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="file">
  <mat-radio-group formControlName="target" fxLayoutGap="10px" class="export-buttons">
    <mat-radio-button value="xlsx">Excel</mat-radio-button>
    <mat-radio-button value="pdf">PDF</mat-radio-button>
  </mat-radio-group>
  <mat-form-field>
    <input type="text" matInput formControlName="filename" placeholder="File name" >
    <span matSuffix>.{{file.get('target').value}}</span>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="submitDialog()"  >Save</button>
</div>
