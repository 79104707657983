/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payment-report.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../subscriptions-report/subscriptions-report.component.ngfactory";
import * as i5 from "../subscriptions-report/subscriptions-report.component";
import * as i6 from "@angular/material/dialog";
import * as i7 from "../user.service";
import * as i8 from "@angular/material/snack-bar";
import * as i9 from "../reports.service";
import * as i10 from "../oldboys.service";
import * as i11 from "./payment-report.component";
var styles_PaymentReportComponent = [i0.styles];
var RenderType_PaymentReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentReportComponent, data: {} });
export { RenderType_PaymentReportComponent as RenderType_PaymentReportComponent };
export function View_PaymentReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { subscriptionsReportComponent: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center"]], null, null, null, null, null)), i1.ɵdid(2, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "app-subscriptions-report", [], null, null, null, i4.View_SubscriptionsReportComponent_0, i4.RenderType_SubscriptionsReportComponent)), i1.ɵdid(5, 4308992, [[1, 4]], 0, i5.SubscriptionsReportComponent, [i6.MatDialog, i7.UserService, i8.MatSnackBar, i9.SubscriptionsService, i10.OldboysService], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 2, 0, currVal_0); var currVal_1 = "center"; _ck(_v, 3, 0, currVal_1); _ck(_v, 5, 0); }, null); }
export function View_PaymentReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-payment-report", [], null, null, null, View_PaymentReportComponent_0, RenderType_PaymentReportComponent)), i1.ɵdid(1, 114688, null, 0, i11.PaymentReportComponent, [i6.MatDialog, i8.MatSnackBar, i9.SubscriptionsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentReportComponentNgFactory = i1.ɵccf("app-payment-report", i11.PaymentReportComponent, View_PaymentReportComponent_Host_0, {}, {}, []);
export { PaymentReportComponentNgFactory as PaymentReportComponentNgFactory };
