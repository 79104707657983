<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading">  
  <div class="signup-container" fxLayout="column" [formGroup]="form">    
    <p class="line">
      <label for="email">Email Address *</label>
      <input name="email" formControlName="email">
    </p>
    <p class="line">
      <label for="password">Password (please type it twice)</label>
      <input type="password" formControlName="password">
      <input type="password" formControlName="passwordConfirmation">
    </p>
    <mat-divider></mat-divider>
    <p class="line">
      <label for="firstName">First Name *</label>
      <input name="firstName" formControlName="firstName">
    </p>
    <p class="line">
      <label for="lastName">Last Name *</label>
      <input name="lastName" formControlName="lastName">
    </p>
    <p class="line">
      <label for="dateOfBirth">Date of Birth *</label>
      <input matInput type="date" name="dateOfBirth" formControlName="dateOfBirth">
    </p>
    <p class="line">
      <label for="years">Years at WBHS *</label>
      <input matInput name="fromYear" type="number" formControlName="fromYear" placeholder="From">
      <input matInput name="toYear" type="number" formControlName="toYear" placeholder="To">
    </p>
    <mat-divider></mat-divider>
    <p class="line">
      <label for="streetAddress">Street Address</label>
      <input name="streetAddress" formControlName="streetAddress">
    </p>
    <p class="line">
      <label for="suburb">Suburb</label>
      <input name="suburb" formControlName="suburb">
    </p>
    <p class="line">
      <label for="town">Town / City</label>
      <input name="town" formControlName="town">
    </p>
    <p class="line">
      <label for="province">Province / State</label>
      <input name="province" formControlName="province">
    </p>
    <p class="line">
      <label for="postCode">Postcode / ZIP</label>
      <input name="postCode" formControlName="postCode">
    </p>
    <p class="line">
      <label for="country">Country</label>
      <input name="country" formControlName="country">
    </p>
    <mat-divider></mat-divider>
    <p class="line">
      <label for="homePhone">Home Phone</label>
      <input name="homePhone" formControlName="homePhone">
    </p>
    <p class="line">
      <label for="workPhone">Work Phone</label>
      <input name="workPhone" formControlName="workPhone">
    </p>
    <p class="line">
      <label for="mobilePhone">Mobile Phone</label>
      <input name="mobilePhone" formControlName="mobilePhone">
    </p>
    <mat-divider></mat-divider>
    <p class="line">
      <label for="house">House / Whanau</label>
      <input name="house" formControlName="house">
    </p>
    <div fxLayout="row" fxLayoutAlign="end">
      <p class="submit">
        <button mat-button color="primary" (click)="onRegister()" fxFlex="">Register</button>
      </p>
    </div>
  </div>
</div>
<p id="nav">
  <a [routerLink]="['/login']">Login</a>
</p>