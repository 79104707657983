import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroup} from "@angular/forms";
import {Activity, OldboysService} from "../oldboys.service";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {UserService} from "../user.service";

@Component({
  selector: 'app-activity-dialog',
  templateUrl: './activity-dialog.component.html',
  styleUrls: ['./activity-dialog.component.css']
})
export class ActivityDialogComponent implements OnInit {

  id = null;
  name = null;
  activity = new FormGroup({
    id: new FormControl(),
    activity_id: new FormControl(),
    name: new FormControl(),
    years_involved: new FormControl(),
    group_team: new FormControl(),
    position_role: new FormControl(),
    notes: new FormControl()
  });

  activities: Array<Activity> = [];
  filteredOptions: Observable<Activity[]>;

  constructor(public dialogRef: MatDialogRef<ActivityDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private oldBoysService: OldboysService,
              private userService: UserService) {
    if (data.activity) {
      this.id = data.activity.id;
      const acc = Object.assign({name: data.activity.Activity.name}, data.activity);
      this.activity.patchValue(acc);
    }
  }

  ngOnInit() {
    this.oldBoysService.getActivities().subscribe(activities => {
      this.activities = activities;
      this.activities.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.filteredOptions = this.activity.get('name').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }

  private _filter(value: string): Activity[] {
    const filterValue = value.toLowerCase();
    return this.activities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (remove): void {
    if  (remove) {
      this.dialogRef.close({delete: true, id: this.id});
    } else {
      const result = this.activity.value;
      if (this.id) {
        result.id = this.id;
      }
      const activity_ids = this.activities.filter(a => a.name === result.name);
      if (activity_ids.length > 0) {
        result.activity_id = activity_ids[0].id;
      }
      this.dialogRef.close(result);
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  isValid() {
    return this.activities.map(a => a.name).includes(this.activity.get('name').value);
  }
}
