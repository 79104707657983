import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatSelect, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroup} from "@angular/forms";
import {Category, OldboysService} from "../oldboys.service";
import {UserService} from "../user.service";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.css']
})
export class CategoryDialogComponent implements OnInit {
  id = null;
  isNew = true
  pseudoid = null;
  name = null;
  category = new FormGroup({
    id: new FormControl(),
    category_id: new FormControl(),
    pseudoid: new FormControl(),
    name: new FormControl(),
    from: new FormControl(),
    to: new FormControl(),
    notes: new FormControl()
  });

  categories: Array<Category> = [];
  filteredOptions: Observable<Category[]>;

  constructor(public dialogRef: MatDialogRef<CategoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private oldBoysService: OldboysService,
              private userService: UserService) {
    if (data && data.pseudoid){
      this.pseudoid = data.pseudoid
      this.isNew = false
    }
    if (data.category) {
      this.id = data.category.id;
      if (this.id){
        this.isNew = false
      }
      if (data.category.pseudoid){
        this.pseudoid = data.category.pseudoid
        this.isNew = false
      }
      const acc = Object.assign({name: data.category.Category.name}, data.category);
      this.category.patchValue(acc);
    }
  }

  ngOnInit() {
    this.oldBoysService.getCategories().subscribe(categories => {
      this.categories = categories;
      this.categories.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      if (!this.id){
        if (this.categories.map(a => a.name).includes('Old Boy')){
          const acc = Object.assign({name: 'Old Boy'})
          this.category.patchValue(acc)
        } else if (this.categories.length > 0) {
          const acc = Object.assign({name: this.categories[0].name})
          this.category.patchValue(acc)
        }
      }
      this.filteredOptions = this.category.get('name').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }

  private _filter(value: string): Category[] {
    const filterValue = value.toLowerCase();

    //return this.categories.filter(option => option.name.toLowerCase().includes(filterValue));
    return this.categories.filter(option => option.name.toLowerCase().includes(''));
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (remove): void {
    if  (remove) {
      //needs pseudoid - index in the array?
      this.dialogRef.close({delete: true, id: this.id, pseudoid: this.pseudoid});
    } else {
      const result = this.category.value;
      if (this.id) {
        result.modified = true
        result.id = this.id;
      }
      if (this.pseudoid) {
        result.pseudoid = this.pseudoid
      }
      const category_ids = this.categories.filter(a => a.name === result.name);
      if (category_ids.length > 0) {
        result.category_id = category_ids[0].id;
      }
      this.dialogRef.close(result);
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  isValid() {
    return this.categories.map(a => a.name).includes(this.category.get('name').value);
  }

}
