<!-- subscription-filter.component.html
<h1 mat-dialog-title>Define Filter for Subscriptions</h1>
<form [formGroup]="filterForm">
  <div mat-dialog-content fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-form-field class="line">
        <mat-label>Category</mat-label>
        <mat-select formControlName="category">
          <mat-option value="Annual">Annual</mat-option>
          <mat-option value="Monthly">Monthly</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
      <mat-form-field class="line">
        <mat-label>Payment Method</mat-label>
        <mat-select formControlName="paymentMethod">
          <mat-option value="Stripe">Stripe</mat-option>
          <mat-option value="Credit Card">Credit Card</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
    <button mat-raised-button (click)="submitDialog()" cdkFocusInitial color="primary">Apply</button>
  </div>
</form> -->


<h1 mat-dialog-title>Define Filter</h1>
<!-- <mat-form-field class="line">
  <input type="text" matInput [formControl]="filterName" placeholder="Filter Name">
  <mat-error *ngIf="filterName.hasError('minLength') && !filterName.hasError('required')">
    Please enter a valid filter name (longer than 3 letters)
  </mat-error>
  <mat-error *ngIf="filterName.hasError('validateNotNone')">
    Filter <strong>None</strong> cannot be modified
  </mat-error>
</mat-form-field> -->
<div mat-dialog-content fxLayout="column"  [formGroup]="filter">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-form-field class="line">
      <input type="text" matInput formControlName="name" placeholder=" Name">
    </mat-form-field>
    <mat-slide-toggle formControlName="useName"></mat-slide-toggle>
   
  </div>

  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <mat-label>Payment From Date</mat-label>
      <input matInput [matDatepicker]="picker1" formControlName="paymentFromDate"  (keydown)="onKeydown($event)">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-slide-toggle formControlName="usePaymentFromDate"></mat-slide-toggle>
    <mat-form-field  class="line">
      <mat-label>Payment To Date</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="paymentToDate"  (keydown)="onKeydown($event)">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-slide-toggle formControlName="usePaymentToDate"></mat-slide-toggle>
  </div>


  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <!-- <mat-form-field  class="line">
      <mat-label>Capacity</mat-label>
      <mat-select>
        <mat-option *ngFor="let category of capacitycategories" [value]="category">{{category}}</mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field  class="line">
      <mat-select placeholder="Payment Method"  formControlName="payment_method">
        <mat-option *ngFor="let method of paymentMethods" [value]="method.id">{{ method.name }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="usePayment_method"></mat-slide-toggle>
    <mat-form-field  class="line">
      <mat-select placeholder="Select Category" formControlName="subscription_category">
        <mat-option *ngFor="let category of subscriptionCategories" [value]="category.id">{{ category.name
          }}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useSubscription_category"></mat-slide-toggle>
  </div>

  </div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <!-- <button mat-raised-button (click)="submitDialog(true)"  color="warn" [disabled]="filterName.invalid">Delete</button> -->
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial  color="primary">Apply</button>
</div>
