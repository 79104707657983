import {Component, Inject, OnInit} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.css']
})
export class ExportDialogComponent implements OnInit {

  file = new FormGroup({
    filename: new FormControl(),
    target: new FormControl('pdf')});

  constructor(public dialogRef: MatDialogRef<ExportDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    
    this.file.patchValue({
      filename: this.generatePaymentReportFilename()
    })
  }
  public generatePaymentReportFilename() {
    const now = new Date();
  
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const date = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    return `PaymentReport${year}${month}${date}${hours}${minutes}`;
  }
  
  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    const result = this.file.value;
    console.log(result);
    this.dialogRef.close(result);
  }

}
