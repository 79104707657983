import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Service } from './service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var MembershipRequestService = /** @class */ (function (_super) {
    tslib_1.__extends(MembershipRequestService, _super);
    function MembershipRequestService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('requests');
        return _this;
    }
    MembershipRequestService.prototype.getRequests = function (type, status) {
        var params = new HttpParams();
        if (status !== 'All') {
            if (status === 'New') {
                params = params.set('status', 'NEW');
            }
            else if (status === 'Approved') {
                params = params.set('status', 'APPROVED');
            }
            else if (status === 'Awaiting Input') {
                params = params.set('status', 'AWAITING_INPUT');
            }
            else if (status === 'Rejected') {
                params = params.set('status', 'REJECTED');
            }
        }
        if (type !== 'All') {
            if (type === 'Record Claim') {
                params = params.set('type', 'IDENTITY_CLAIM');
            }
            else if (type === 'Contact Request') {
                params = params.set('type', 'CONTACT_REQUEST');
            }
            else if (type === 'Information Submission') {
                params = params.set('type', 'INFORMATION_SUBMISSION');
            }
        }
        return this.http.get(this.endpoint, { params: params }).pipe(catchError(this.handleError('requests', [])));
    };
    MembershipRequestService.prototype.createContactRequest = function (recordData) {
        var body = {
            data: recordData,
        };
        return this.http.post(this.endpoint + '/contact', body).pipe(catchError(this.handleError('contact', [])));
    };
    MembershipRequestService.prototype.createIdentityClaimRequest = function (data) {
        return this.http.post(this.endpoint + '/identity', data).pipe(catchError(this.handleError('identity', [])));
    };
    MembershipRequestService.prototype.updateRequest = function (request) {
        return this.http.put(this.endpoint + ("/" + request.id), request).pipe(catchError(this.handleError('update', [])));
    };
    MembershipRequestService.prototype.createInformationSubmissionRequest = function (data) {
        return this.http.post(this.endpoint + '/submission', data).pipe(catchError(this.handleError('submission', [])));
    };
    MembershipRequestService.prototype.getUserActiveClaimRequests = function () {
        return this.http.get(this.endpoint + '/claiming').pipe(catchError(this.handleError('claiming', [])));
    };
    MembershipRequestService.prototype.isUserAwaitingSubmission = function (userRequests) {
        var reqs = userRequests.filter(function (r) { return r.type === 'INFORMATION_SUBMISSION' && r.status === 'NEW'; });
        if (reqs.length === 0) {
            return false;
        }
        else {
            return true;
        }
    };
    MembershipRequestService.prototype.isUserAwaitingClaim = function (userRequests) {
        var reqs = userRequests.filter(function (r) { return r.type === 'IDENTITY_CLAIM' && r.status === 'NEW'; });
        if (reqs.length === 0) {
            return false;
        }
        else {
            return true;
        }
    };
    MembershipRequestService.ngInjectableDef = i0.defineInjectable({ factory: function MembershipRequestService_Factory() { return new MembershipRequestService(i0.inject(i1.HttpClient)); }, token: MembershipRequestService, providedIn: "root" });
    return MembershipRequestService;
}(Service));
export { MembershipRequestService };
