import * as tslib_1 from "tslib";
import { ElementRef, OnInit, TemplateRef } from '@angular/core';
import { MatSnackBar, MatTableDataSource, MatDialog } from '@angular/material';
import { PublicService } from "../public.service";
import { FormControl } from "@angular/forms";
import { map, startWith } from 'rxjs/operators';
import { MembershipRequestService } from '../membership-request.service';
import { OldboysService } from '../oldboys.service';
import { UserService } from '../user.service';
import { SubmitInfoDialogComponent } from '../submit-info-dialog/submit-info-dialog.component';
var YearsComponent = /** @class */ (function () {
    function YearsComponent(publicService, snackBar, dialog, contactDialog, membershipRequestService, oldBoyService, userService) {
        this.publicService = publicService;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.contactDialog = contactDialog;
        this.membershipRequestService = membershipRequestService;
        this.oldBoyService = oldBoyService;
        this.userService = userService;
        this.loading = false;
        this.search = new FormControl();
        this.displayedColumns = [
            'name',
            'years',
            'validated',
            'wbhsOldBoy',
            'deceased',
            'emailOnFile',
            //'contact',
            'fill',
        ];
        this.years = [];
        this.subTitle = '';
        this.isUserAwaitingClaim = false;
        this.dataSource = new MatTableDataSource();
    }
    YearsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.publicService.getYears().subscribe(function (years) {
            _this.years = years;
            _this.filteredOptions = _this.search.valueChanges.pipe(startWith(''), map(function (value) { return _this.filterYears(value); }));
            var currUser = _this.userService.getCurrentUser();
            if (currUser && !_this.userService.isContributorOrAdmin()) {
                _this.userService.retrieveCurrentUser().subscribe(function (cu) {
                    if (!cu.prospectId) {
                        _this.membershipRequestService.getUserActiveClaimRequests().subscribe(function (cr) {
                            _this.isUserAwaitingClaim = _this.membershipRequestService.isUserAwaitingClaim(cr);
                            if (_this.isUserAwaitingClaim) {
                                _this.subTitle = "Your request for claiming your school record is in progress.";
                            }
                            else {
                                var startYear = (currUser.data && currUser.data.fromYear ? currUser.data.fromYear : '');
                                if (_this.years.includes(startYear)) {
                                    _this.search.setValue(startYear);
                                    _this.subTitle = "We are displaying all school records for you start year (" + startYear + "). If you can find your record in the results please claim it, else add a new one by clicking the blue button in the upper right hand corner.";
                                    _this.onRefresh();
                                }
                                else {
                                    _this.subTitle = "There are no records from the school for your starting year (" + startYear + "). Please add a new record by clicking the blue button in the upper right hand corner.";
                                }
                            }
                        });
                    }
                });
            }
        });
    };
    YearsComponent.prototype.checkIfUserAwaitingClaim = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var cr;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.membershipRequestService.getUserActiveClaimRequests()];
                    case 1:
                        cr = _a.sent();
                        this.isUserAwaitingClaim = this.membershipRequestService.isUserAwaitingClaim(cr);
                        return [2 /*return*/];
                }
            });
        });
    };
    YearsComponent.prototype.filterYears = function (value) {
        if (value) {
            var filterValue_1 = value.toString();
            return this.years.filter(function (option) { return option && option.toString().indexOf(filterValue_1) === 0; });
        }
        else {
            return this.years;
        }
    };
    YearsComponent.prototype.onYearChange = function (event) {
        var _this = this;
        this.publicService.getYearStudents(event.value).subscribe(function (students) {
            _this.dataSource.data = students;
        });
    };
    YearsComponent.prototype.onKeydown = function (event) {
        var _this = this;
        if (event.key === 'Enter') {
            this.loading = true;
            this.publicService.getYearStudents(this.search.value).subscribe(function (students) {
                _this.dataSource.data = students;
                _this.loading = false;
            });
            this.searchInput.nativeElement.blur();
        }
    };
    YearsComponent.prototype.onRefresh = function () {
        var _this = this;
        this.loading = true;
        this.publicService.getYearStudents(this.search.value).subscribe(function (students) {
            _this.dataSource.data = students;
            _this.loading = false;
        });
        this.searchInput.nativeElement.blur();
    };
    YearsComponent.prototype.hitTest = function () {
        var _this = this;
        return this.years.filter(function (option) { return option && _this.search.value && (option.toString() === _this.search.value.toString()); }).length > 0;
    };
    YearsComponent.prototype.canProvideUpdate = function (currRow) {
        var currUser = this.userService.getCurrentUser();
        return (currUser && currUser.prospectId && currUser.prospectId !== currRow.id);
    };
    YearsComponent.prototype.addNewRecord = function () {
        var _this = this;
        var data = {};
        var currUser = this.userService.getCurrentUser();
        if (!this.userService.isContributorOrAdmin() && !currUser.prospectId) {
            data = {
                emailaddress: currUser.email,
                firstname: currUser.data.firstName,
                lastname: currUser.data.lastName,
                birthdate: currUser.data.dateOfBirth,
                firstyear: currUser.data.fromYear,
                lastyear: currUser.data.toYear,
                country: currUser.data.country,
                town_city: currUser.data.town,
                housename: currUser.data.house,
                suburb: currUser.data.suburb,
                postalcode: currUser.data.postcode,
                province_state: currUser.data.province,
                hometelephone: currUser.data.homePhone,
                worktelephone: currUser.data.workPhone,
                mobiletelephone: currUser.data.mobilePhone,
                address1: currUser.data.streetAddress,
            };
        }
        var dialogRef = this.dialog.open(SubmitInfoDialogComponent, {
            maxHeight: '100vh',
            height: '100%',
            data: data
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.membershipRequestService.createInformationSubmissionRequest(result)
                    .subscribe(function (res) {
                    _this.snackBar.open(res.msg, 'Dismiss', {
                        duration: 100000,
                    });
                });
            }
        });
    };
    YearsComponent.prototype.provideUpdate = function (row) {
        var _this = this;
        this.dialog.open(this.updateDialogRef).afterClosed()
            .subscribe(function (result) {
            if (result) {
                var dialogOldBoy = _this.dialog.open(SubmitInfoDialogComponent, {
                    maxHeight: '100vh',
                    height: '100%',
                    data: row
                });
                dialogOldBoy.afterClosed().subscribe(function (result1) {
                    if (result1) {
                        _this.membershipRequestService.createInformationSubmissionRequest(result1)
                            .subscribe(function (res) {
                            _this.snackBar.open(res.msg, 'Dismiss', {
                                duration: 100000,
                            });
                        });
                    }
                });
            }
        });
    };
    YearsComponent.prototype.contactMember = function (row) {
        var _this = this;
        this.contactDialog.open(this.contactDialogRef, {
            data: {
                prospectName: row.firstname + ' ' + row.lastname
            }
        }).afterClosed()
            .subscribe(function (result) {
            if (result) {
                _this.membershipRequestService.createContactRequest(row)
                    .subscribe(function (res) {
                    _this.snackBar.open(res.msg, 'Dismiss', {
                        duration: 100000,
                    });
                });
            }
        });
    };
    YearsComponent.prototype.canRequestContact = function (currRow) {
        var currUser = this.userService.getCurrentUser();
        return (currUser && currUser.prospectId && currUser.prospectId !== currRow.id);
    };
    YearsComponent.prototype.canAddNewRecord = function () {
        var currUser = this.userService.getCurrentUser();
        if (currUser) {
            if (this.userService.isContributorOrAdmin() || currUser.prospectId) {
                return true;
            }
            else {
                if (this.isUserAwaitingClaim) {
                    return false;
                }
                else {
                    return true;
                }
            }
        }
        else {
            return false;
        }
    };
    return YearsComponent;
}());
export { YearsComponent };
