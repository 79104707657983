import {Component, Inject, OnInit} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {FormControl, FormGroup} from "@angular/forms";
import {Keyword, OldboysService} from "../oldboys.service";
import {UserService} from "../user.service";
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-keyword-dialog',
  templateUrl: './keyword-dialog.component.html',
  styleUrls: ['./keyword-dialog.component.css']
})
export class KeywordDialogComponent implements OnInit {

  id = null;
  name = null;
  keyword = new FormGroup({
    id: new FormControl(),
    keyword_id: new FormControl(),
    name: new FormControl()
  });

  keywords: Array<Keyword> = [];
  filteredOptions: Observable<Keyword[]>;

  constructor(public dialogRef: MatDialogRef<KeywordDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private oldBoysService: OldboysService,
              private userService: UserService) {
    if (data.keyword) {
      this.id = data.keyword.id;
      const acc = Object.assign({name: data.keyword.Keyword.name}, data.keyword);
      this.keyword.patchValue(acc);
    }
  }

  ngOnInit() {
    this.oldBoysService.getKeywords().subscribe(keywords => {
      this.keywords = keywords;
      this.keywords.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      this.filteredOptions = this.keyword.get('name').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });
  }

  private _filter(value: string): Keyword[] {
    const filterValue = value.toLowerCase();

    return this.keywords.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (remove): void {
    if  (remove) {
      this.dialogRef.close({delete: true, id: this.id});
    } else {
      const result = this.keyword.value;
      if (this.id) {
        result.id = this.id;
      }
      const keyword_ids = this.keywords.filter(a => a.name === result.name);
      if (keyword_ids.length > 0) {
        result.keyword_id = keyword_ids[0].id;
      }
      this.dialogRef.close(result);
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  isValid() {
    return this.keywords.map(a => a.name).includes(this.keyword.get('name').value);
  }

}
