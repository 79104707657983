import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { MembershipRequestService, MembershipRequest } from '../membership-request.service';
import { UserService } from '../user.service';
import * as moment from 'moment';
import { MatDialog, MatSnackBar } from '@angular/material';
import { OldboyDialogComponent } from '../oldboy-dialog/oldboy-dialog.component';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.css']
})
export class RequestsComponent implements OnInit {

  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  @ViewChild('detailsDialogRef') detailsDialogRef: TemplateRef<any>;

  loading = false;
  displayedColumns: string[] = ['type', 'status', 'fromUser', 'email', 'createdAt', 'updatedAt', 'notes'];
  dataSource: MembershipRequest[] = [];
  selectedRequest: MembershipRequest;
  selectedNotes = '';
  pageTitle = "Membership Requests";
  statuses = ['New', 'Approved', 'Awaiting Input', 'Rejected', 'All'];
  selectedStatus = 'New';
  types = ['Record Claim', 'Contact Request', 'Information Submission', 'All'];
  selectedType = 'All';
  accept = {firstname: false,
            lastname: false,
            suburb: false,
            country: false,
            address1: false,
            address2: false,
            employer: false,
            lastyear: false,
            firstyear: false,
            faxnumber: false,
            housename: false,
            town_city: false,
            postalcode: false,
            emailaddress: false,
            hometelephone: false,
            province_state: false,
            mobiletelephone: false,
            organisationalnotes: false,
            profession_jobtitle: false};
  fieldWhitelist = new Set([
    "firstname",
    "middlenames",
    "lastname",
    "birthdate",
    "deceased",
    "firstyear",
    "lastyear",
    "emailaddress",
    "emailaddress2",
    "address1",
    "address2",
    "suburb",
    "town_city",
    "province_state",
    "postalcode",
    "country",
    "hometelephone",
    "mobiletelephone",
    "worktelephone",
    "housename",
    "updatedBy",
    "updatedAt"]);

  constructor(
    private membershipRequestService: MembershipRequestService,
    private userService: UserService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.getData();
    if (this.userService.isContributorOrAdmin()) {
      this.displayedColumns.push('statusControls');
    } else {
      this.pageTitle = "My Requests";
    }
  }

  getData() {
    this.loading = true;
    this.membershipRequestService.getRequests(this.selectedType, this.selectedStatus)
      .pipe(finalize(() => this.loading = false))
      .subscribe(requests => this.dataSource = requests);
  }

  onStatusChanged(event) {
    this.getData();
  }

  onTypeChanged(event) {
    this.getData();
  }

  showDetails(request: MembershipRequest) {
    const requestData = {
      request : request,
      notes : request.notes
    };

    this.accept.firstname = false;
    this.accept.lastname = false;
    this.accept.suburb = false;
    this.accept.country = false;
    this.accept.address1 = false;
    this.accept.address2 = false;
    this.accept.employer = false;
    this.accept.lastyear = false;
    this.accept.firstyear = false;
    this.accept.faxnumber = false;
    this.accept.housename = false;
    this.accept.town_city = false;
    this.accept.postalcode = false;
    this.accept.emailaddress = false;
    this.accept.hometelephone = false;
    this.accept.province_state = false;
    this.accept.mobiletelephone = false;
    this.accept.organisationalnotes = false;
    this.accept.profession_jobtitle = false;

    this.dialog.open(this.detailsDialogRef, {
        width: '800px',
        autoFocus: false,
        data : requestData
      })
    .afterClosed()
    .subscribe((result) => {
      if (result === false) {
        return;
      }
    });
  }

  sortEntries(arr) {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "firstname"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "middlenames"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "lastname"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "birthdate"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "deceased"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "firstyear"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "lastyear"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "emailaddress"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "emailaddress2"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "address1"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "address2"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "suburb"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "town_city"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "province_state"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "postalcode"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "country"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "hometelephone"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "mobiletelephone"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "housename"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "updatedAt"){
        result.push(arr[i]);
        break;
      }
    }
    for (let i = 0; i < arr.length; i++) {
      if (arr[i][0] === "updatedBy"){
        result.push(arr[i]);
        break;
      }
    }

    return result;
  }

  getDate(date) {
    if (date === '' || date === 'N/A') {
      return null;
    } else {
      return date;
    }
  }

  entries(dict) {
    return this.sortEntries(Object.entries(dict).filter(row => row[1] && row[0] !== 'id'));
  }

  getUserName(element) {
    if (element.type === 'CONTACT_REQUEST' || element.type === 'INFORMATION_SUBMISSION') {
      if (element.requestedBy) {
        return element.requestedBy.firstname + ' ' + element.requestedBy.lastname;
      } else {
        return 'N/A';
      }
    } else if (element.data && element.data.firstName && element.data.lastName) {
      return element.data.firstName + ' ' + element.data.lastName;
    } else if (element.fromUserName) {
      return element.fromUserName;
    } else {
      return 'N/A';
    }
  }

  getInformationSubmissionTarget(request) {
    let source = request.data;
    if (request.oldboy) {
      source = request.oldboy;
    }
    let result = '';
    if (source.firstname) {
      result = source.firstname;
    }
    if (source.lastname) {
      result += ' ' + source.lastname;
    }
    if (source.firstyear) {
      result += '(' + source.firstyear + ')';
    }
    return result;
  }

  getExistingData(name, request) {
    if (name === "updatedBy") {
      if (request.oldboy && request.oldboy.User && request.oldboy.User.username) {
        return request.oldboy.User.username;
      } else {
        return 'N/A';
      }
    } else if (name === "updatedAt") {
      if (request.oldboy) {
        const result = request.oldboy[name];
        return result || null;
      } else {
        return null;
      }
    } else {
      if (request.oldboy) {
        const result = request.oldboy[name];
        return result || 'N/A';
      } else {
        return 'N/A';
      }
    }
  }

  getSuppliedData(name, request) {
    if(name === 'emailaddress') {
      return request.fromUser;
    }
    const result = request.data && request.data[name];
    return result || 'N/A';
  }

  getFieldName(name) {
    if (name === 'firstname') {
      return 'First Name';
    } else if (name === 'middlenames') {
      return 'Middle Name';
    } else if (name === 'lastname') {
      return 'Last Name';
    } else if (name === 'suburb') {
      return 'Suburb';
    } else if (name === 'country') {
      return 'Country';
    } else if (name === 'address1') {
      return 'Address Line 1';
    } else if (name === 'address2') {
      return 'Address Line 2';
    } else if (name === 'employer') {
      return 'Employer';
    } else if (name === 'lastyear') {
      return 'Last Year';
    } else if (name === 'firstyear') {
      return 'First Year';
    } else if (name === 'faxnumber') {
      return 'Fax Number';
    } else if (name === 'housename') {
      return 'House Name';
    } else if (name === 'town_city') {
      return 'Town/City';
    } else if (name === 'postalcode') {
      return 'Postal Code';
    } else if (name === 'emailaddress') {
      return 'Email Address';
    } else if (name === 'hometelephone') {
      return 'Home Telephone';
    } else if (name === 'worktelephone') {
      return 'Work Telephone';
    } else if (name === 'province_state') {
      return 'Province State';
    } else if (name === 'mobiletelephone') {
      return 'Mobile Telephone';
    } else if (name === 'organisationalnotes') {
      return 'Notes';
    } else if (name === 'profession_jobtitle') {
      return 'Job Title';
    } else {
      return name.charAt(0).toUpperCase() + name.slice(1);
    }
  }

  getFieldNameForRegistrationName(name) {
    if (name === 'firstname') {
      return 'firstName';
    } else if (name === 'middlenames') {
      return 'middleName';
    } else if (name === 'lastname') {
      return 'lastName';
    } else if (name === 'suburb') {
      return 'suburb';
    } else if (name === 'country') {
      return 'country';
    } else if (name === 'address1') {
      return 'streetAddress';
    } else if (name === 'lastyear') {
      return 'toYear';
    } else if (name === 'firstyear') {
      return 'fromYear';
    } else if (name === 'housename') {
      return 'house';
    } else if (name === 'town_city') {
      return 'town';
    } else if (name === 'postalcode') {
      return 'postCode';
    } else if (name === 'hometelephone') {
      return 'homePhone';
    } else if (name === 'province_state') {
      return 'province';
    } else if (name === 'mobiletelephone') {
      return 'mobilePhone';
    } else if (name === 'birthdate') {
      return 'dateOfBirth';
    } else if (name === 'worktelephone') {
      return 'workPhone';
    } else {
      return name;
    }
  }

  onAcceptToggle(name) {
    this.accept[name] = !this.accept[name];
  }

  approveRequest(request: MembershipRequest, notes: string) {
    request.status = 'APPROVED';
    if (notes) {
      request.notes = notes;
    }
    if (request.type === 'INFORMATION_SUBMISSION') {
      const updatedData = {id: request.data['id']};
      this.entries(request.data).forEach(entry => {
        if (this.accept[entry[0]]) {
          updatedData[entry[0]] = entry[1];
        }
      });
      request.data = updatedData;
    }
    this.membershipRequestService.updateRequest(request)
      .subscribe((res) => {
        this.snackbar.open(res.msg, 'Dismiss', {
          duration: 100000,
        });
        this.getData();
      });
  }

  awaitRequest(request: MembershipRequest, notes: string) {
    request.status = 'AWAITING_INPUT';
    if (notes) {
      request.notes = notes;
    }
    this.membershipRequestService.updateRequest(request)
      .subscribe((res) => {
        this.snackbar.open(res.msg, 'Dismiss', {
          duration: 100000,
        });
        this.getData();
      });
  }

  rejectRequest(request: MembershipRequest, notes: string) {
    request.status = 'REJECTED';
    if (notes) {
      request.notes = notes;
    }
    this.membershipRequestService.updateRequest(request)
      .subscribe((res) => {
        this.snackbar.open(res.msg, 'Dismiss', {
          duration: 100000,
        });
        this.getData();
      });
  }

  editNotes(request: MembershipRequest) {
    this.selectedRequest = request;
    this.selectedNotes = this.selectedRequest.notes;
    this.dialog.open(this.dialogRef,{
      width: '640px',
      autoFocus: false
    })
      .afterClosed()
      .subscribe((result) => {
        if (result === false) {
          return;
        }
        this.selectedRequest.notes = this.selectedNotes;
        this.membershipRequestService.updateRequest(this.selectedRequest)
          .subscribe((res) => {
            this.snackbar.open(res.msg, 'Dismiss', {
              duration: 100000,
            });
            this.getData();
          });
      });
  }

  saveNotes(request: MembershipRequest, notes: string) {
    request.notes = notes;
    this.membershipRequestService.updateRequest(request)
        .subscribe((res) => {
          this.snackbar.open(res.msg, 'Dismiss', {
            duration: 100000,
          });
          this.getData();
        });
  }

  prettifyType(type: string) {
    switch (type) {
      case 'IDENTITY_CLAIM':
        return 'Record Claim';
      case 'CONTACT_REQUEST':
        return 'Contact Request';
      case 'INFORMATION_SUBMISSION':
        return 'Information Submission';
      default:
        return '';
    }
  }

  prettifyStatus(status: string) {
    switch (status) {
      case 'NEW':
        return 'New';
      case 'APPROVED':
        return 'Approved';
      case 'AWAITING_INPUT':
        return 'Awaiting Input';
      case 'REJECTED':
        return 'Rejected';
      default:
        return '';
    }
  }

  prettifyTime(time: Date) {
    return moment(time).format('LLL');
  }

  showApprove(request: MembershipRequest) {
    return (request.status === 'NEW' || request.status === 'AWAITING_INPUT');
  }

  showAwaiting(request: MembershipRequest) {
    if (request.type !== 'CONTACT_REQUEST') {
      return false;
    }
    return ((request.status !== 'APPROVED' && request.status !== 'REJECTED') && request.status !== 'AWAITING_INPUT');
  }

  showReject(request: MembershipRequest) {
    return (request.status === 'AWAITING_INPUT' || request.status === 'NEW');
  }

  prettifyDOB(time: Date) {
    return moment(time).format('DD-MM-YYYY');
  }

  getUserInfo(request) {
    if (request.requestedBy) {
      let result = request.requestedBy.firstname + ' ' + request.requestedBy.lastname;
      if (request.requestedBy.emailaddress) {
        result = result + " with email <a href='mailto:" + request.requestedBy.emailaddress + "' target='_blank'>" + request.requestedBy.emailaddress + "</a>";
      }
      return result;
    } else {
      return request.fromUserName;
    }
  }

  getIdentityClaimUserInfo(request) {
    if (request.data && request.data.firstName && request.data.lastName) {
      return request.data.firstName + ' ' + request.data.lastName + " with email <a href='mailto:" + request.fromUser + "' target='_blank'>" + request.fromUser + "</a>";
    } else {
      return request.fromUserName + " with email <a href='mailto:" + request.fromUser + "' target='_blank'>" + request.fromUser + "</a>";
    }
  }

  getIdentityClaimOldBoyInfo(request) {
    if (request.oldboy) {
      let result = request.oldboy.firstname + ' ' + request.oldboy.lastname;
      if (request.oldboy.emailaddress) {
        result = result + " with email <a href='mailto:" + request.oldboy.emailaddress + "' target='_blank'>" + request.oldboy.emailaddress + "</a>";
      }
      return result;
    } else {
      return 'Unknown';
    }
  }

  getContactRequestTargetInfo(request) {
    if (request.oldboy) {
      let result = request.oldboy.firstname + ' ' + request.oldboy.lastname;
      if (request.oldboy.emailaddress) {
        result = result + " with email <a href='mailto:" + request.oldboy.emailaddress + "' target='_blank'>" + request.oldboy.emailaddress + "</a>";
      }
      return result;
    } else {
      return 'Unknown';
    }
  }

  isUserContributorOrAdmin() {
    return this.userService.isContributorOrAdmin();
  }
}
