<div class="main-background">
  <div class="main-div">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h1 class="text-center">
        <a class="text-center message" href="http://www.wbhsoba.co.nz/">Whangarei Boys High School Old Boys Association (Inc)</a>
      </h1>
      <app-signin (loginSuccess)="onLoginSuccess()"></app-signin>
    </div>
  </div>
</div>





