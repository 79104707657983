<h1 mat-dialog-title>Add new user</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="createUserForm">
  <mat-form-field matLine>
    <input matInput placeholder="Name" formControlName="name">
    <mat-error *ngIf="createUserForm.get('name').hasError('minlength') || createUserForm.get('name').hasError('required')">
      Please enter a valid name (longer than 4 letters)
    </mat-error>
  </mat-form-field>
  <mat-form-field matLine>
    <input matInput placeholder="Email" formControlName="email">
    <mat-error *ngIf="createUserForm.get('email').hasError('email') || createUserForm.get('email').hasError('required')">
      Please enter a valid email address
    </mat-error>
  </mat-form-field>
  <mat-form-field matLine>
    <mat-select formControlName="type" >
      <mat-option value="MEMBER">Member</mat-option>
      <mat-option value="CONTRIBUTOR">Contributor</mat-option>
      <mat-option value="ADMIN">Administrator</mat-option>
      <mat-option value="YEAR-OWNER">Year Owner</mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog()" cdkFocusInitial [disabled]="!createUserForm.valid" color="primary">Save</button>
</div>
