import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-activity-edit-dialog',
  templateUrl: './activity-edit-dialog.component.html',
  styleUrls: ['./activity-edit-dialog.component.css']
})
export class ActivityEditDialogComponent implements OnInit {
  id = null;
  oldName = null;
  activity = new FormGroup({
    name: new FormControl(),
    sport: new FormControl(false)
  });
  constructor(public dialogRef: MatDialogRef<ActivityEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.activity) {
      this.id = data.activity.id;
      this.activity.patchValue(data.activity);
      this.oldName = data.activity.name;
    }
  }

  ngOnInit() {
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (remove): void {
    if  (remove) {
      this.dialogRef.close({delete: true, id: this.id});
    } else {
      const result = this.activity.value;
      if (this.id) {
        result.id = this.id;
      }
      this.dialogRef.close(result);
    }
  }

  isValid() {
    return this.activity.get('name').value;
  }
}
