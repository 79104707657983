<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <div fxLayout="row">
        <div fxLayoutAlign="start center" fxFlex="7 0 auto">
          <mat-icon inline="true">how_to_reg</mat-icon>&nbsp;&nbsp;Claim My Record
        </div>
        <div fxLayoutAlign="end center"  fxFlex="3 0 auto" *ngIf="!this.isUserAwaitingClaim">
          <button mat-flat-button color="accent" (click)="contactAdministrator()"  matTooltip="Contact Administrator">Contact Administrator</button>
        </div>
      </div>
    </mat-card-title>
    <div class="mat-card-subtitle">{{ subTitle }}</div>
    <mat-card-header>

    </mat-card-header>
    <mat-card-content>
      <mat-spinner *ngIf="loading"></mat-spinner>
      <table mat-table [dataSource]="dataSource" *ngIf="!loading && (dataSource.data.length > 0)">

        <!-- First name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef class="header-cell"> Name </th>
          <td mat-cell *matCellDef="let element" class="name-cell">
            <ng-container *ngIf="canClaimRecord(element)">
              <button mat-flat-button (click)="claimIdentity(element)">{{element.firstname + ' ' + (element.middlenames != null ? element.middlenames : '') + ' ' + element.lastname}} </button>
            </ng-container>
            <ng-container *ngIf="!canClaimRecord(element)">
              {{element.firstname + ' ' + (element.middlenames != null ? element.middlenames : '') + ' ' + element.lastname}}
            </ng-container>
          </td>
        </ng-container>

        <!-- Last name Column -->
        <ng-container matColumnDef="years">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Years at WBHS </th>
          <td mat-cell *matCellDef="let element" class="years-cell"> {{element.firstyear + ' to ' + (element.lastyear === null ? '?' : element.lastyear)}} </td>
        </ng-container>

        <!-- Validated Column -->
        <ng-container matColumnDef="validated">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Validated </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon *ngIf="element.validated">check</mat-icon>
          </td>
        </ng-container>

        <!-- WBHS Old Boy Member Column -->
        <ng-container matColumnDef="wbhsOldBoy">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Old Boys Member </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon *ngIf="element.wbhsOldBoy">check</mat-icon>
          </td>
        </ng-container>

        <!-- Deceased Column -->
        <ng-container matColumnDef="deceased">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Deceased </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon *ngIf="element.deceased">check</mat-icon>
          </td>
        </ng-container>

        <!-- Email on file Column -->
        <ng-container matColumnDef="emailOnFile">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"> Email on file </th>
          <td mat-cell *matCellDef="let element" class="years-cell">
            <mat-icon *ngIf="element.emailOnFile">check</mat-icon>
          </td>
        </ng-container>

        <!-- Fill collumn -->
        <ng-container matColumnDef="fill">
          <th mat-header-cell *matHeaderCellDef  class="header-cell"></th>
          <td mat-cell  *matCellDef="let element" class="years-cell"></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </mat-card-content>
  </mat-card>
</div>

<ng-template #dialogRef>
  <h1 mat-dialog-title>Claim Record</h1>
  <div mat-dialog-content>
    All record claims are checked by the administrator to ensure the validity of the claim. Do you wish to continue and send your claim request to the administrator?
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</ng-template>

<ng-template let-data #contactAdministratorDialogRef>
  <h1 mat-dialog-title>Contact Administrator</h1>
  <div mat-dialog-content>
    This will allow you to contact the Administrator and supply them with your information so that they can assist you in claiming your record. Do you wish to proceed?
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</ng-template>

