<div id="oldboy-dialog-container">
  <mat-toolbar class="mat-dialog-title-main" [color]="['primary']" mat-dialog-title fxLayout="row">
    <div fxFlex="95" fxLayout="row" fxLayoutAlign="space-between">
      <div class="logo">
        <img src="assets/wbhs-logo.svg">
      </div>
      <div mat-dialog-actions class="mat-dialog-actions-2" fxLayout="row" fxLayoutAlign="start center">
        <button mat-raised-button class="button2" (click)="closeDialog()"  color="white">Cancel</button>
        <button mat-raised-button class="button2" (click)="submitDialog(true)" [disabled]="false" color="warn" *ngIf="id">Delete</button>
        <button mat-raised-button class="button2" (click)="submitDialog(false)" [disabled]="false" type="submit" color="white" *ngIf="!disableSave">Save</button>
      </div>
    </div>
  </mat-toolbar>
  <div class="oldboy-title-divider"></div>
  <div mat-dialog-content class="oldboy-dialog-content" fxLayout="column">
    <form [formGroup]="oldBoyForm">
      <div fxLayout="column">

        <mat-card>
          <mat-card-content>
            <div fxLayout="row"  fxLayoutGap="5px" fxLayoutAlign="start center">
              <div  *ngIf="creating" fxFlex="7 0 auto"><h1 class="mat-h1 big">New</h1></div>
              <div  *ngIf="!creating" fxFlex="7 0 auto"><h1 class="mat-h1 big">{{oldBoyForm.get('firstname').value + ' ' + (oldBoyForm.get('middlenames').value || '') + ' ' + oldBoyForm.get('lastname').value + ' ' + (oldBoyForm.get('firstyear').value || '') }}</h1></div>

              <div *ngIf="isContributorOrAdmin()" fxFlex="3 0 auto" fxLayoutGap="5px" fxLayoutAlign="end center">
                <mat-checkbox  formControlName="validated">Validated</mat-checkbox>
                <mat-checkbox  formControlName="unsubscribed">Unsubscribed</mat-checkbox>
                <mat-checkbox  formControlName="alumnimember">OBA Member</mat-checkbox>
                <mat-checkbox  formControlName="deceased" [(ngModel)]="deceased">Deceased</mat-checkbox>
                <mat-checkbox [checked]="linkedAcc" disabled>Linked</mat-checkbox>
                <mat-form-field *ngIf="deceased" class="datefield">
                  <input matInput [matDatepicker]="picker2" placeholder="Decease date" formControlName="deceasedate"  [disableControl]="!deceased" (keydown)="onKeydown($event)">
                  <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>


        <mat-card>
          <mat-card-title>
            Personal
          </mat-card-title>
          <mat-card-content>
            <div class="flex-wrapper">
              <div class="leftcolumn">
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Name</h3>
                  </div>

                  <mat-form-field   class="namefield">
                    <input matInput  placeholder="First" formControlName="firstname" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <mat-form-field  class="middlenamefield">
                    <input matInput  placeholder="Middle" formControlName="middlenames" (keydown)="onKeydown($event)">
                  </mat-form-field>
                  <mat-form-field  class="lastnamefield">
                    <input matInput  placeholder="Last" formControlName="lastname" (keydown)="onKeydown($event)">
                  </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Title</h3>
                  </div>
                  <mat-form-field class="namefield">
                    <mat-select formControlName="title">
                      <mat-option value="Mr">Mr</mat-option>
                      <mat-option value="Ms">Ms</mat-option>
                      <mat-option value="Mrs">Mrs</mat-option>
                      <mat-option value="Miss">Miss</mat-option>
                      <mat-option value="Mr & Ms">Mr & Ms</mat-option>
                      <mat-option value="Mr & Mrs">Mr & Mrs</mat-option>
                      <mat-option value="Admiral">Admiral</mat-option>
                      <mat-option value="Ambassador">Ambassador</mat-option>
                      <mat-option value="Brother">Brother</mat-option>
                      <mat-option value="Capt">Capt</mat-option>
                      <mat-option value="Cmdr">Cmdr</mat-option>
                      <mat-option value="Col">Col</mat-option>
                      <mat-option value="Dame">Dame</mat-option>
                      <mat-option value="Dr">Dr</mat-option>
                      <mat-option value="Father">Father</mat-option>
                      <mat-option value="General">General</mat-option>
                      <mat-option value="Judge">Judge</mat-option>
                      <mat-option value="Lady">Lady</mat-option>
                      <mat-option value="Lord">Lord</mat-option>
                      <mat-option value="Lt">Lt</mat-option>
                      <mat-option value="Madam">Madam</mat-option>
                      <mat-option value="Major">Major</mat-option>
                      <mat-option value="Pastor">Pastor</mat-option>
                      <mat-option value="Prof">Prof</mat-option>
                      <mat-option value="Rabbi">Rabbi</mat-option>
                      <mat-option value="Rev">Rev</mat-option>
                      <mat-option value="Sir">Sir</mat-option>
                      <mat-option value="Sister">Sister</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="formheader2">
                    <h3 class="mat-h3 formlabel">Salutation</h3>
                  </div>
                  <mat-form-field class="salutationnamefield">
                    <input matInput formControlName="salutation"  (keydown)="onKeydown($event)">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Gender</h3>
                  </div>
                  <mat-form-field class="namefield">
                    <mat-select formControlName="gender">
                      <mat-option value="Male">Male</mat-option>
                      <mat-option value="Female">Female</mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div class="formheader2">
                    <h3 class="mat-h3 formlabel" >Birthdate</h3>
                  </div>
                  <mat-form-field class="namefield">
                    <input matInput [matDatepicker]="picker" formControlName="birthdate"  (keydown)="onKeydown($event)">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="sep1"></div>
              </div>

              <div>
                <mat-card-content fxLayout="column">
                  <mat-table [dataSource]="notesDataSource" class="notesMatTable">

                    <!--- Note that these columns can be defined in any order.
                          The actual rendered columns are set as a property on the row definition" -->
      
                    <!-- Note id -->
                    <ng-container matColumnDef="id">
                      <th mat-header-cell *matHeaderCellDef> Note Id </th>
                      <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>

                    <!-- Note Column -->
                    <ng-container matColumnDef="noteText">
                      <th mat-header-cell *matHeaderCellDef> School Notes
                        <button class="addNoteButton" [disabled]="!id" mat-icon-button color="accent" (click)="addNote()" type="button" matTooltip="Add a new note"><mat-icon class="noteMatIcon">add</mat-icon></button>
                      </th>
                      <td class="noteMatCell" mat-cell *matCellDef="let element"> {{element.noteText}} </td>
                    </ng-container>

                    <!-- Full Updated Column-->
                    <ng-container matColumnDef="updatedFull">
                      <th mat-header-cell align="center" *matHeaderCellDef> Updated At </th>
                      <td class="noteSmallMatCell" mat-cell *matCellDef="let element"> {{element.updatedAt | date : 'dd/MM/yyyy'}} by {{element.User.data.firstName}} {{element.User.data.lastName}}</td>
                    </ng-container>
      
                    <!-- Updated At Column -->
                    <ng-container matColumnDef="updatedAt">
                      <th mat-header-cell align="center" *matHeaderCellDef> Updated At </th>
                      <td class="noteSmallMatCell" mat-cell *matCellDef="let element"> {{element.updatedAt | date : 'dd/MM/yyyy'}} </td>
                    </ng-container>
      
                    <!-- Updated By Column -->
                    <ng-container matColumnDef="updatedBy">
                      <th mat-header-cell align="center" *matHeaderCellDef> Updated By </th>
                      <td class ='noteSmallMatCell' mat-cell *matCellDef="let element"> {{element.User.data.firstName}} {{(element.User.data.middleName != null ? element.User.data.middleName : '')}} {{element.User.data.lastName}} </td>
                    </ng-container>

                    <!-- Control Column -->
                    <ng-container matColumnDef="modify">
                      <th mat-header-cell align="right" *matHeaderCellDef  class="header-cell"></th>
                      <td mat-cell *matCellDef="let element">
                        <button (click)="updateNote(element)"  mat-icon-button color="accent" matTooltip="Update this note."><mat-icon>edit</mat-icon></button>
                        <button (click)="deleteNote(element)" mat-icon-button color="accent" matTooltip="Delete this note."><mat-icon>delete</mat-icon></button>
                      </td>
                    </ng-container>
      
                  <tr mat-header-row *matHeaderRowDef="notesDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: notesDisplayedColumns;"  (click)="onNoteRowClicked(row)"></tr>
                  </mat-table>
                </mat-card-content>
                <!-- <mat-card-actions *ngIf="notesDataSource.data.length > 2"> -->
                  <!-- <mat-paginator [pageSizeOptions]="[3, 5, 10, 20]" showFirstLastButtons></mat-paginator> -->
                <!-- </mat-card-actions> -->
              </div>
            </div>
          </mat-card-content>
        </mat-card>


        <mat-card *ngIf="isContributorOrAdmin()">
          <div #Record_Type_Card fxLayout="row">
            <div fxFlex="90%">
              <mat-card-title>
                Record Type
              </mat-card-title>
            </div>
            <div fxFlex="10%">
              <!-- <div *ngIf="id"> -->
              <div>
                <button mat-mini-fab color="accent" (click)="onAddCategory()"  type="button"><mat-icon>add</mat-icon></button>
              </div>
              <!-- <div *ngIf="!id">
                Please save this form in order to access record types.
              </div> -->
            </div>
          </div>
          <mat-card-content>

            <table mat-table [dataSource]="categoriesDataSource" *ngIf="categoriesDataSource.data && categoriesDataSource.data.length">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{getCatName(element)}} </td>
          </ng-container>

          <!-- From Column -->
          <ng-container matColumnDef="from">
            <th mat-header-cell *matHeaderCellDef> From </th>
            <td mat-cell *matCellDef="let element">{{getCatFrom(element)}} </td>
          </ng-container>

          <!-- To Column -->
          <ng-container matColumnDef="to">
            <th mat-header-cell *matHeaderCellDef> To </th>
            <td mat-cell *matCellDef="let element"> {{getCatTo(element)}} </td>
          </ng-container>

          <!-- Notes Column -->
          <ng-container matColumnDef="notes">
            <th mat-header-cell *matHeaderCellDef> Notes </th>
            <td mat-cell *matCellDef="let element"> {{getCatNotes(element)}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="categoriesDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: categoriesDisplayedColumns;"  (click)="onCategoryRowClicked(row)"></tr>
        </table>

          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>
            Contact Details
          </mat-card-title>

          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Email Address</h3>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Email 1" formControlName="emailaddress"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div>
                  <button (click)="contactEmail(1)" *ngIf="canContactEmail(1)" mat-icon-button color="accent" matTooltip="Contact this email"><mat-icon>email</mat-icon></button>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Email 2" formControlName="emailaddress2"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div>
                  <button (click)="contactEmail(2)" *ngIf="canContactEmail(2)" mat-icon-button color="accent" matTooltip="Contact this email"><mat-icon>email</mat-icon></button>
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Street Address</h3>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Street Address Line 1" formControlName="address1"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Street Address Line 2" formControlName="address2"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Suburb</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="suburb"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Town/City</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="town_city"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Province/State</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="province_state"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Postal Code</h3>
                </div>
                <mat-form-field class="yearfield">
                  <input matInput formControlName="postalcode"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Country</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="country"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Telephone</h3>
                </div>
                <mat-form-field class="telephonefield">
                  <input matInput placeholder="Home" formControlName="hometelephone"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="telephonefield">
                  <input matInput placeholder="Mobile" formControlName="mobiletelephone"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Contact Method</h3>
                </div>
                <mat-form-field class="methodfield">
                  <mat-select placeholder="Preferred Contact Method" formControlName="preferredcontactmethod">
                    <mat-option value="None">None</mat-option>
                    <mat-option value="Home Address">Home Address</mat-option>
                    <mat-option value="Home Telephone">Home Telephone</mat-option>
                    <mat-option value="Work Telephone">Work Telephone</mat-option>
                    <mat-option value="Mobile Telephone">Mobile Telephone</mat-option>
                    <mat-option value="Email">Email</mat-option>
                    <mat-option value="Fax">Fax</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="methodfield">
                  <mat-select placeholder="Second Contact Method" formControlName="secondcontactmethod">
                    <mat-option value="None">None</mat-option>
                    <mat-option value="Home Address">Home Address</mat-option>
                    <mat-option value="Home Telephone">Home Telephone</mat-option>
                    <mat-option value="Work Telephone">Work Telephone</mat-option>
                    <mat-option value="Mobile Telephone">Mobile Telephone</mat-option>
                    <mat-option value="Email">Email</mat-option>
                    <mat-option value="Fax">Fax</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Capacity/Relationship</h3>
                </div>
                <mat-form-field class="addressfield">
                  <mat-select placeholder="Capacity" formControlName="capacitycategory">
                    <mat-option value="DELETE">Blank</mat-option>
                    <mat-option value="A">A</mat-option>
                    <mat-option value="B">B</mat-option>
                    <mat-option value="C">C</mat-option>
                    <mat-option value="D">D</mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field class="addressfield">
                  <mat-select placeholder="Relationship" formControlName="relationshipcategory">
                    <mat-option value="DELETE">Blank</mat-option>
                    <mat-option value="High">High</mat-option>
                    <mat-option value="Medium">Medium</mat-option>
                    <mat-option value="Low">Low</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow" *ngIf="oldNotesExist()">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel">Legacy School Notes</h3>
                </div>
                <mat-form-field class="notesfield">
                  <textarea matInput formControlName="organisationalnotes" matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5></textarea>
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>
            School Information
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Attended</h3>
                </div>
                <mat-form-field class="yearfield">
                  <input matInput placeholder="First Year" formControlName="firstyear"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="yearfield">
                  <input matInput placeholder="Last Year" formControlName="lastyear"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >House</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="housename"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">

                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Boarder</h3>
                </div>

                <div class="formswitch">
                  <mat-slide-toggle  formControlName="border" [(ngModel)]="boarder"></mat-slide-toggle>
                </div>
                <mat-form-field  class="yearfield">
                  <input matInput  placeholder="From Year" type="number" formControlName="fromyear" [disableControl]="!boarder"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field  class="yearfield">
                  <input matInput  placeholder="To Year" type="number" formControlName="toyear" [disableControl]="!boarder"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div fxFlexFill></div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>Work & Career</mat-card-title>
          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Profession/Job Title</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="profession_jobtitle"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Employer</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="employer"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Work Telephone</h3>
                </div>
                <mat-form-field class="telephonefield">
                  <input matInput formControlName="worktelephone"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Work Address</h3>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Line 1" formControlName="workaddress1"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Line 2" formControlName="workaddress2"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Work Suburb</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="worksuburb"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" > Work Town/City</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="worktown_city"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Work Province/State</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="workprovince_state"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Work Postal Code</h3>
                </div>
                <mat-form-field class="yearfield">
                  <input matInput formControlName="workpostalcode"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Work Country</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="workcountry"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Career Notes</h3>
                </div>
                <mat-form-field class="notesfield">
                  <textarea matInput formControlName="careernotes" matTextareaAutosize matAutosizeMinRows=2 matAutosizeMaxRows=5></textarea>
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>




        <mat-card *ngIf="isContributorOrAdmin()">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-card-title>
                Activities & Sports
              </mat-card-title>
            </div>
            <div fxFlex="10%">
              <div *ngIf="id">
                <button mat-mini-fab color="accent" (click)="onAddActivity()" type="button"><mat-icon>add</mat-icon></button>
              </div>
              <div *ngIf="!id">
                Please save this form in order to access activities.
              </div>
            </div>
          </div>
          <mat-card-content>
            <table mat-table [dataSource]="activitiesDataSource" *ngIf="activitiesDataSource.data && activitiesDataSource.data.length">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.Activity.name}} </td>
              </ng-container>

              <!-- Years involved Column -->
              <ng-container matColumnDef="years_involved">
                <th mat-header-cell *matHeaderCellDef> Years involved </th>
                <td mat-cell *matCellDef="let element"> {{element.years_involved}} </td>
              </ng-container>

              <!-- Position/Role Column -->
              <ng-container matColumnDef="position_role">
                <th mat-header-cell *matHeaderCellDef> Position/Role </th>
                <td mat-cell *matCellDef="let element"> {{element.position_role}} </td>
              </ng-container>

              <!-- Group/Team Column -->
              <ng-container matColumnDef="group_team">
                <th mat-header-cell *matHeaderCellDef> Group/Team </th>
                <td mat-cell *matCellDef="let element"> {{element.group_team}} </td>
              </ng-container>

              <!-- Sport Column -->
              <ng-container matColumnDef="sport">
                <th mat-header-cell *matHeaderCellDef> Sport </th>
                <td mat-cell *matCellDef="let element">
                  <mat-icon *ngIf="element.Activity.sport">check</mat-icon>
                </td>
              </ng-container>

              <!-- Notes Column -->
              <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef> Notes </th>
                <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
              </ng-container>

            <tr mat-header-row *matHeaderRowDef="activitiesDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: activitiesDisplayedColumns;"  (click)="onActivityRowClicked(row)"></tr>
            </table>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="isContributorOrAdmin()">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-card-title>
                Keywords
              </mat-card-title>
            </div>
            <div fxFlex="10%">
              <div *ngIf="id">
                <button mat-mini-fab color="accent" (click)="onAddKeyword()"  type="button"><mat-icon>add</mat-icon></button>
              </div>
              <div *ngIf="!id">
                Please save this form in order to access keywords.
              </div>
            </div>
          </div>
          <mat-card-content>

            <table mat-table [dataSource]="keywordsDataSource" *ngIf="keywordsDataSource.data && keywordsDataSource.data.length">

              <!--- Note that these columns can be defined in any order.
                    The actual rendered columns are set as a property on the row definition" -->

              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> Name </th>
                <td mat-cell *matCellDef="let element"> {{element.Keyword.name}} </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="keywordsDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: keywordsDisplayedColumns;"  (click)="onKeywordRowClicked(row)"></tr>
            </table>

          </mat-card-content>
        </mat-card>


        <mat-card *ngIf="isContributorOrAdmin()">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-card-title>
                Association Subscriptions
              </mat-card-title>
            </div>
            <div fxFlex="10%">
              <div *ngIf="id">
                <button mat-mini-fab color="accent" (click)="onAddMembership()"  type="button"><mat-icon>add</mat-icon></button>
              </div>
              <div *ngIf="!id">
                Please save this form in order to access memberships.
              </div>
            </div>
          </div>
          <mat-card-content>

            <table mat-table class="" [dataSource]="membershipsDataSource" *ngIf="membershipsDataSource.data && membershipsDataSource.data.length">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
          
          <!-- Receipt number Column -->
          <ng-container matColumnDef="receipt_number">
            <th mat-header-cell *matHeaderCellDef> Receipt number </th>
            <td mat-cell *matCellDef="let element"> {{element.receipt_number}} </td>
          </ng-container>

          <!-- Payment Date Column -->
          <ng-container matColumnDef="payment_date">
            <th mat-header-cell *matHeaderCellDef> Payment Date </th>
            <td mat-cell *matCellDef="let element"> {{element.payment_date | formatDate}} </td>
          </ng-container>

          <!-- Category Column -->
          <ng-container matColumnDef="subscription_category">
            <th mat-header-cell *matHeaderCellDef> Subscription Type </th>
            <td mat-cell *matCellDef="let element"> {{ getSubscriptionCategoryName(element.subscription_category_id) }} </td>
          </ng-container>

          <!-- Paid Column -->
          <ng-container matColumnDef="paid">
            <th mat-header-cell *matHeaderCellDef> Amount paid </th>
            <td mat-cell *matCellDef="let element"> {{element.paid}} </td>
          </ng-container>

          <!-- Payment Method Column -->
          <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef> Payment Method </th>
            <td mat-cell *matCellDef="let element"> {{ getPaymentMethodName(element.payment_method_id) }} </td>
          </ng-container>

          <!-- Renewed to Column -->
          <ng-container matColumnDef="renewed_to">
            <th mat-header-cell *matHeaderCellDef> Renewed to </th>
            <td mat-cell *matCellDef="let element"> {{element.renewed_to | formatDate}} </td>
          </ng-container>

          <!-- Comment Column -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef> Comment </th>
            <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="membershipsDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: membershipsDisplayedColumns;"  (click)="onMembershipRowClicked(row)"></tr>
        </table>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="isContributorOrAdmin()">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-card-title>
                Donations and Other Payments
              </mat-card-title>
            </div>
            <div fxFlex="10%">
              <div *ngIf="id">
                <button mat-mini-fab color="accent" (click)="onAddDonation()"  type="button"><mat-icon>add</mat-icon></button>
              </div>
              <div *ngIf="!id">
                Please save this form in order to access donations.
              </div>
            </div>
          </div>
          <mat-card-content>

            <table mat-table class="" [dataSource]="donationsDataSource" *ngIf="donationsDataSource.data && donationsDataSource.data.length">

          <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
          
          <!-- Receipt number Column -->
          <ng-container matColumnDef="receipt_number">
            <th mat-header-cell *matHeaderCellDef> Receipt number </th>
            <td mat-cell *matCellDef="let element"> {{element.receipt_number}} </td>
          </ng-container>

          <!-- Payment Date Column -->
          <ng-container matColumnDef="payment_date">
            <th mat-header-cell *matHeaderCellDef> Payment Date </th>
            <td mat-cell *matCellDef="let element"> {{element.payment_date | formatDate}} </td>
          </ng-container>

          <!-- Payment Area Column -->
          <ng-container matColumnDef="payment_area">
            <th mat-header-cell *matHeaderCellDef> Payment Area </th>
            <td mat-cell *matCellDef="let element"> {{ getPaymentAreaName(element.payment_area_id) }} </td>
          </ng-container>

          <!-- Payment Project Column -->
          <ng-container matColumnDef="payment_project">
            <th mat-header-cell *matHeaderCellDef> Payment Project </th>
            <td mat-cell *matCellDef="let element"> {{ getPaymentProjectName(element.payment_project_id) }} </td>
          </ng-container>

          <!-- Paid Column -->
          <ng-container matColumnDef="paid">
            <th mat-header-cell *matHeaderCellDef> Amount paid </th>
            <td mat-cell *matCellDef="let element"> {{element.paid}} </td>
          </ng-container>

          <!-- Payment Method Column -->
          <ng-container matColumnDef="payment_method">
            <th mat-header-cell *matHeaderCellDef> Payment Method </th>
            <td mat-cell *matCellDef="let element"> {{ getPaymentMethodName(element.payment_method_id) }} </td>
          </ng-container>

          <!-- Comment Column -->
          <ng-container matColumnDef="comment">
            <th mat-header-cell *matHeaderCellDef> Comment </th>
            <td mat-cell *matCellDef="let element"> {{element.comment}} </td>
          </ng-container>



          <tr mat-header-row *matHeaderRowDef="donationsDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: donationsDisplayedColumns;"  (click)="onDonationRowClicked(row)"></tr>
        </table>

          </mat-card-content>
        </mat-card>

        <mat-card *ngIf="updatedAt && updatedByUser">
          <mat-card-content>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px">
              <div *ngIf="updatedAt">Last updated at: {{updatedAt | formatDate}}</div>
              <div *ngIf="updatedByUser">
                by {{updatedByUser}}
              </div>
            </div>
          </mat-card-content>
        </mat-card>


      </div>
    </form>

    <!--
    id: string;
    validated: boolean;
    deceased: boolean;
    deceasedate: Date;
    alumnimember: boolean;
    label: boolean;
    capacitycategory: string;
    relationshipcategory: string;
    gender: string;
    title: string;
    initials: string;
    firstname: string;
    middlenames: string;
    lastname: string;
    salutation: string;
    maidenname: string;
    birthdate: Date;
    address1: string;
    address2: string;
    suburb: string;
    town_city: string;
    province_state: string;
    postal_code: string;
    country: string;
    hometelephone: string;
    mobiletelephone: string;
    faxnumber: string;
    emailaddress: string;
    employer: string;
    profession_jobtitle: string;
    qualificationlevel: string;
    careernotes: string;
    worktelephone: string;
    preferredcontactmethod: string;
    secondcontactmethod: string;
    workaddress1: string;
    workaddress2: string;
    worksuburb: string;
    worktown_city: string;
    workprovince_state: string;
    workpostalcode: string;
    workcountry: string;
    housename: string;
    firstyear: number;
    lastyear: number;
    border: boolean;
    fromyear: string;
    toyear: string;
    organisationalnotes: string;
    martialstatus: string;
    parent_partnertitle: string;
    parent_partnerinitials: string;
    parent_partnerfirstname: string;
    parent_partnerlastname: string;
    partnerinterests: string;
    childrensnames: string;
    volunteer1id: string;
    volunteer2id: string;
    fundraisingnotes: string;
    firstclass: string;
    lastclass: string;
    updated: Date;
    loaded: string;
    buddy: string;
    createdAt: Date;
    updatedAt: Date;
    -->
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">   
    <button mat-raised-button (click)="closeDialog()"  color="primary">Cancel</button>
    <button mat-raised-button (click)="submitDialog(true)"  color="warn" *ngIf="id">Delete</button>
    <button mat-raised-button (click)="submitDialog(false)" [disabled]="false" type="submit" color="primary" *ngIf="!disableSave">Save</button>
    <button mat-raised-button (click)="unlinkDialog()" [disabled]="!linkedAcc" ng-if="!isAdmin" color="warn" *ngIf="id">Unlink</button>
  </div>
</div>
