/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./login.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../signin/signin.component.ngfactory";
import * as i5 from "../signin/signin.component";
import * as i6 from "../auth.service";
import * as i7 from "../user.service";
import * as i8 from "../sidebar.service";
import * as i9 from "@angular/material/snack-bar";
import * as i10 from "@angular/router";
import * as i11 from "@angular/material/dialog";
import * as i12 from "../membership-request.service";
import * as i13 from "./login.component";
var styles_LoginComponent = [i0.styles];
var RenderType_LoginComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoginComponent, data: {} });
export { RenderType_LoginComponent as RenderType_LoginComponent };
export function View_LoginComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "main-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "div", [["class", "main-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "text-center message"], ["href", "http://www.wbhsoba.co.nz/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whangarei Boys High School Old Boys Association (Inc)"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-signin", [], null, [[null, "loginSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginSuccess" === en)) {
        var pd_0 = (_co.onLoginSuccess() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SigninComponent_0, i4.RenderType_SigninComponent)), i1.ɵdid(9, 114688, null, 0, i5.SigninComponent, [i6.AuthService, i7.UserService, i8.SidebarService, i9.MatSnackBar, i10.Router, i11.MatDialog, i12.MembershipRequestService], null, { loginSuccess: "loginSuccess" })], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 4, 0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_LoginComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-login", [], null, null, null, View_LoginComponent_0, RenderType_LoginComponent)), i1.ɵdid(1, 114688, null, 0, i13.LoginComponent, [i6.AuthService, i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoginComponentNgFactory = i1.ɵccf("app-login", i13.LoginComponent, View_LoginComponent_Host_0, {}, { loginSuccess: "loginSuccess" }, []);
export { LoginComponentNgFactory as LoginComponentNgFactory };
