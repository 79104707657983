import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatTableDataSource} from '@angular/material';
import {AdminService, UserAccount} from "../admin.service";
import {UserService} from "../user.service";
import {CreateUserDialogComponent} from "../create-user-dialog/create-user-dialog.component";
import { UserEditDialogComponent } from '../user-edit-dialog/user-edit-dialog.component';
import { MessageBoxComponent } from '../message-box/message-box.component';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';

@Component({
  selector: 'app-access',
  templateUrl: './access.component.html',
  styleUrls: ['./access.component.css']
})
export class AccessComponent implements OnInit {
  displayedColumns: string[] = ['username', 'email', 'validated', 'yearsowned', 'disabled', 'type','editUser'];
  displayedSubColumns: string[] = ['years']
  datesavailable: string[] = []
  hideMembers = true
  loading = true

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private adminService: AdminService,
              private userService: UserService,
              public snackBar: MatSnackBar,
              public dialog: MatDialog) { }

  dataSource = new MatTableDataSource<UserAccount>();

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.getData();
    let ii = new Date().getFullYear()
    while(ii >= 1881){
      this.datesavailable.push(ii.toString())
      ii = ii - 1
    }
  }

  getData() {
    this.loading = true
    this.adminService.getUsers().subscribe(users => {
      //console.log(users)
      let temp_array = [];
      for (let key in users){
        let temp_user = users[key]
        if (!(this.hideMembers && temp_user.type === "MEMBER")){
          if(!temp_user || !temp_user.data || !temp_user.data['yearsowned']){
            if (users[key].data) {
              users[key].data['yearsowned'] = [];
            }
          }
          temp_array.push(users[key])
        }
      }
      temp_array.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));
      this.dataSource.data = temp_array
      this.loading = false
      //console.log(this.dataSource.data)
    });
  }

  onViewMembers() {
    this.hideMembers = !this.hideMembers
    this.getData()
  }

  onEnabledChange(event, element) {
    this.adminService.updateDetails(element.id, {disabled: !event.checked}).subscribe(result => {
      if (result.success) {
        this.adminService.getUsers().subscribe( users => {
          //console.log(users)
          let temp_array = [];
          for (let key in users){
            let temp_user = users[key]
            if (!(this.hideMembers && temp_user.type === "MEMBER")){
              if(!temp_user || !temp_user.data || !temp_user.data['yearsowned']){
                if (users[key].data) {
                  users[key].data['yearsowned'] = [];
                }
              }
              temp_array.push(users[key])
            }
          }
          temp_array.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));
          this.dataSource.data = temp_array;
          //console.log(this.dataSource.data)
          this.openSnackBar(result['msg'],'Dismiss');
        });
      } else {
        this.openSnackBar(result['error']['msg'],'Dismiss');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
    });
  }

  onTypeChange(event, element) {
    let data_to_update = {type: event.value};
    if(!element.data){
      element.data = {"town": "",
      "house": "",
      "suburb": "",
      "toYear": 1993,
      "country": "",
      "fromYear": 1991,
      "lastName": "",
      "postCode": "",
      "province": "",
      "firstName": "",
      "homePhone": "",
      "workPhone": "",
      "dateOfBirth": "",
      "mobilePhone": "",
      "streetAddress": ""}
    }
    if (event.value != 'Year-Owner'){
      element.data.yearsowned = [];
      data_to_update['data'] = element.data;
    }
    this.adminService.updateDetails(element.id, data_to_update).subscribe(result => {
      if (result.success) {
        this.adminService.getUsers().subscribe( users => {
          //console.log(users)
          let temp_array = [];
          for (let key in users){
            let temp_user = users[key];
            if (!(this.hideMembers && temp_user.type === "MEMBER")){
              if(!temp_user || !temp_user.data || !temp_user.data['yearsowned']){
                if (users[key].data) {
                  users[key].data['yearsowned'] = [];
                }
              }
              temp_array.push(users[key])
            }
          }
          temp_array.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));
          if (this.userService.getCurrentUser().id === element.id) {
            this.userService.retrieveCurrentUser().subscribe(u => {
              this.dataSource.data = temp_array;
              this.openSnackBar(result['msg'],'Dismiss');
            })
          } else {
            this.dataSource.data = temp_array
            this.openSnackBar(result['msg'],'Dismiss');
          }
        });
      } else {
        this.openSnackBar(result['error']['msg'],'Dismiss');
      }
    });
  }

  onDeleteYear(element,year) {
    let index = -1;
    if (element && element.data && element.data.yearsowned) {
      for (let ii in element.data.yearsowned){
        if (element.data.yearsowned[ii] === year){
          index = Number(ii);
          break;
        }
      }
    }
    if (index === -1){
      this.openSnackBar("Something went wrong: (" + year + ") is not in the list of years.",'Dismiss')
    } else {
      if (element && element.data && element.data.yearsowned) {
        element.data.yearsowned.splice(index, 1);
      }
      this.adminService.updateDetails(element.id, {data: element.data}).subscribe(result => {
        if (result.success) {
          this.adminService.getUsers().subscribe( users => {
            //console.log(users)
            let temp_array = [];
            for (let key in users){
              let temp_user = users[key]
              if (!(this.hideMembers && temp_user.type === "MEMBER")){
                if(!temp_user || !temp_user.data || !temp_user.data['yearsowned']){
                  if (users[key].data) {
                    users[key].data['yearsowned'] = [];
                  }
                }
                temp_array.push(users[key])
              }
            }
            temp_array.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));
            if (this.userService.getCurrentUser().id === element.id) {
              this.userService.retrieveCurrentUser().subscribe(u => {
                this.dataSource.data = temp_array;
                this.openSnackBar(result['msg'],'Dismiss');
              })
            } else {
              this.dataSource.data = temp_array;
              this.openSnackBar(result['msg'],'Dismiss');
            }
          });
        } else {
          this.openSnackBar(result['error']['msg'],'Dismiss');
        }
      })
    }
  }

  isAdmin() {
    return this.userService.isAdmin()
  }

  tableRefresh(newData) {
    // this.loading = true
    // newData.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));
    // this.dataSource.data = newData
    // this.loading = false
    this.getData()
  }

  editUserDialog(event) {
    console.log('editUserDialog',event)
    const dialogRef = this.dialog.open(UserEditDialogComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      data: { user: event }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result)
      if (result && result.delete) {
        const dialogRefDel = this.dialog.open(MessageBoxComponent, {
          //width: '400px',
          data: { title: 'Warning!', text: 'Do you really want to delete ' + result.data.user.username + ' ?', answers: ['No', 'Yes']}
        });

        dialogRefDel.afterClosed().subscribe(resultDel => {
          console.log(resultDel);
          if (resultDel === 'Yes') {
            this.adminService.deleteUser(event.id).subscribe(i => {
              var users = this.dataSource.data
              for (var value in users){
                if(users[value].id === event.id){
                  delete users[value]
                }
              }
              this.tableRefresh(users)
            });
            //
          }
        });
      } else if (result) {
        this.adminService.putUserDetails(event.id, result.data).subscribe(r => {
          var users = this.dataSource.data
          var valueInTable = false
          for (var value in users){
            if(users[value].id === event.id){
              for (var key in result.data){
                users[value][key] = result.data[key]
              }
              valueInTable = true
            }
          }
          if (!valueInTable){
            this.getData()
          }
          this.tableRefresh(users)
        });
      }
    });
  }

  onYearChange(event, element) {
    let index = -1;
    if (element && element.data && element.data.yearsowned) {
      for (let ii in element.data.yearsowned){
        if (element.data.yearsowned[ii] === event.value){
          index = Number(ii);
          break;
        }
      }
    }
    if(index === -1){
      if (element && element.data && element.data.yearsowned) {
        element.data.yearsowned.push(event.value);
      }
      this.adminService.updateDetails(element.id, {data: element.data}).subscribe(result => {
        if (result.success) {
          this.adminService.getUsers().subscribe( users => {
            //console.log(users)
            let temp_array = [];
            for (let key in users){
              let temp_user = users[key];
              if (!(this.hideMembers && temp_user.type === "MEMBER")){
                if(!temp_user || !temp_user.data || !temp_user.data['yearsowned']){
                  if (users[key].data) {
                    users[key].data['yearsowned'] = [];
                  }
                }
                temp_array.push(users[key]);
              }
            }
            temp_array.sort((a, b) => (this.getSortName(a) > this.getSortName(b)) ? 1 : ((this.getSortName(b) > this.getSortName(a)) ? -1 : 0));            
            if (this.userService.getCurrentUser().id === element.id) {
              this.userService.retrieveCurrentUser().subscribe(u => {
                this.dataSource.data = temp_array;
                this.openSnackBar(result['msg'], 'Dismiss');
              })
            } else {
              this.dataSource.data = temp_array;
              this.openSnackBar(result['msg'], 'Dismiss');
            }
          });
        } else {
          this.openSnackBar(result['error']['msg'], 'Dismiss');
        }
      })
    } else {
      this.openSnackBar(event.value + ' is already assigned to this user.', 'Dismiss');
    }
  }

  getSortName(user) {
    var name = this.getName(user)
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  getName(user) {
    if (user.prospect) {
      if (user.prospect.firstname && user.prospect.lastname) {
        return user.prospect.firstname + ' ' + user.prospect.lastname;
      }
    }
    const data = JSON.parse(JSON.stringify(user.data));
    if (data) {
      if (data.firstName && data.lastName) {
        return data.firstName + ' ' + data.lastName;
      }
    }
    return user.username;
  }

  canBeYearOwner(element) {
    if (element && element.type) {
      return element.type === 'YEAR-OWNER' || element.type === 'ADMIN';
    }
    return false;
  }

  getYearsOwned(element) {
    if (element && element.data && element.data.yearsowned) {
        return element.data.yearsowned;
    }
    return [];
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  onAdd() {
    const dialogRef = this.dialog.open(CreateUserDialogComponent, {
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.adminService.invite(result.name, result.email, result.type ).subscribe(res => {
          if (res.success) {
            this.openSnackBar('User invited','Dismiss');
            this.getData();
          } else {
            this.openSnackBar(res['error']['msg'],'Dismiss');
          }
        });
      }
    });
  }
}

