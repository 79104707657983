<h1 mat-dialog-title>Select columns</h1>
<div mat-dialog-content fxLayout="row">
  <div fxLayout="column">
    <div>
      Drag and drop to choose what columns to display in the report and in what order
      <div class="example-header" fxLayout="row">
        <div class="example-column-header"><h2>Available</h2></div>
        <div class="example-column-header"><h2>Displayed</h2></div>
      </div>
    </div>

    <div fxLayout="row">
      <div class="example-container">
        <div
          cdkDropList
          #availableList="cdkDropList"
          [cdkDropListData]="available"
          [cdkDropListConnectedTo]="[selectedList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of available" cdkDrag>{{availableColumns[item]}}</div>
        </div>
      </div>

      <div class="example-container">
        <div
          cdkDropList
          #selectedList="cdkDropList"
          [cdkDropListData]="selectedColumns"
          [cdkDropListConnectedTo]="[availableList]"
          class="example-list"
          (cdkDropListDropped)="drop($event)">
          <div class="example-box" *ngFor="let item of selectedColumns" cdkDrag>{{availableColumns[item]}}</div>
        </div>
      </div>
    </div>
  </div>

</div>
<div mat-dialog-actions>
  <button mat-button (click)="closeDialog()">Cancel</button>
  <button mat-button (click)="submitDialog()" cdkFocusInitial>Apply</button>
</div>
