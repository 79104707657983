<h2 mat-dialog-title>Send Receipt</h2>
<div  *ngIf="!showEmail" mat-dialog-content>
  <p>Do you want to send a receipt?</p>
  <div mat-dialog-actions>
    <button mat-raised-button (click)="cancel()" color="primary">No</button>
    <button mat-raised-button (click)="confirm()" color="primary">Yes</button>
  </div>
</div>
<div *ngIf="showEmail" mat-dialog-content>
  <p>Please enter the email address to send the receipt to:</p>
  <mat-form-field>
    <input matInput placeholder="Email" [formControl]="email">
  </mat-form-field>
</div>
<div *ngIf="showEmail" mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="sendEmail()" color="primary">Send</button>
</div>
