import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { SubscriptionsService } from '../reports.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionFilterComponent } from '../subscription-filter/subscription-filter.component';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import html2canvas from 'html2canvas';
import { OldboysService } from '../oldboys.service';
import { FormControl } from '@angular/forms';
import { UserService } from '../user.service';
import { FormatDatePipe } from '../formatDate';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

interface PaymentOption {
  id: string;
  name: string;
  deleted?: boolean;
}

@Component({
  selector: 'app-donations-report',
  templateUrl: './donations-report.component.html',
  styleUrls: ['./donations-report.component.css']
})
export class DonationsReportComponent implements OnInit, AfterViewInit {
  displayedColumns = ['name', 'year', 'paymentDate', 'receiptNumber', 'area', 'project', 'amount', 'note'];
  dataSource = new MatTableDataSource<any>();
  paymentAreas: PaymentOption[] = [];
  paymentProjects: PaymentOption[] = [];
  name: string;
  paymentFromDate: any;
  paymentToDate: any;
  paymentArea: string;
  paymentProject: string;
  paymentGreaterThan: number;
  renewedFromDate: any;
  renewedToDate: any;
  currentDate: string;
  sortDirection = 'asc';
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table', { read: ElementRef }) table: ElementRef<HTMLTableElement>;
  loading = false;

  private isFirstRefresh = true;
  filterName = 'None';

  constructor(
    public dialog: MatDialog,
    private user: UserService,
    public snackBar: MatSnackBar,
    private subscriptionsService: SubscriptionsService,
    private oldboysService: OldboysService
  ) {
    this.currentDate = new Date().toDateString();
  }

  ngOnInit() {
    this.loadPaymentAreas();
    this.loadPaymentProjects();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  getTableElement(): ElementRef {
    return this.table;
  }

  applyFilters(filters: any): void {
    this.dataSource.filter = JSON.stringify(filters);
  }

  onFilter(): void {
    const dialogRef = this.dialog.open(SubscriptionFilterComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(selectedFilters => {
      if (selectedFilters) {
        this.applyFilters(selectedFilters);
      }
    });
  }

  onExport() {
    const username = this.user.getCurrentFirstLastName();

    const dialogRef = this.dialog.open(ExportDialogComponent, {
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const exportDate = new Date().toLocaleString();

        if (result.target === 'pdf') {
          this.exportTableAsPDFFile(result.filename + '.pdf', username, exportDate);
        } else if (result.target === 'xlsx') {
          this.exportTableAsExcelFile(result.filename + '.xlsx', username, exportDate);
        }
      }
    });
  }

  exportTableAsPDFFile(pdfFileName: string, username: string, exportDate: string): void {
    const doc = new jsPDF({
      orientation: 'landscape',
      unit: 'mm',
      format: 'a4'
    });

    const pageWidth = doc.internal.pageSize.getWidth();

    const headers = this.displayedColumns.map(column => {
      const title = column
        .replace(/([A-Z])/g, ' $1')
        .replace(/^./, str => str.toUpperCase());
      return { title, dataKey: column };
    });

    const dataRows = Array.from(this.table.nativeElement.querySelectorAll('tr:not(.mat-header-row)'));
    const tableData = dataRows.map(row => {
      const cells = row.querySelectorAll('td');
      const rowData: any = {};
      headers.forEach((header, index) => {
        rowData[header.dataKey] = cells[index] ? cells[index].innerText.trim() : '';
      });
      return rowData;
    });

    (doc as any).autoTable({
      head: [headers.map(header => header.title)],
      body: tableData.map(row => Object.values(row)),
      startY: 35,
      styles: { fontSize: 8 },
      theme: 'grid',
      didDrawPage: (pageData) => {
        if (pageData.pageNumber === 1) {
          doc.setFontSize(18);
          doc.text('Donations Report', pageWidth / 2, 20, { align: 'center' });

          doc.setFontSize(12);
          doc.text(`Username: ${username}`, 10, 30);

          const textWidth = doc.getTextWidth(`Date: ${exportDate}`);
          doc.text(`Date: ${exportDate}`, pageWidth - textWidth - 10, 30);
        }
      }
    });

    doc.save(pdfFileName);
  }

  exportTableAsExcelFile(excelFileName: string, username: string, exportDate: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();

    const range = XLSX.utils.decode_range(ws['!ref'] || 'A1');
    const shiftRows = 3;

    for (let R = range.e.r; R >= 0; --R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellRef = XLSX.utils.encode_cell({ r: R, c: C });
        const newCellRef = XLSX.utils.encode_cell({ r: R + shiftRows, c: C });
        ws[newCellRef] = ws[cellRef];
        delete ws[cellRef];
      }
    }

    range.e.r += shiftRows;
    ws['!ref'] = XLSX.utils.encode_range(range);

    // Center the title and date by calculating the required padding
    const titlePadding = ' '.repeat(65);
    const datePadding = ' '.repeat(40);
    const title = `${titlePadding}Donations Report`;
    const dateStr = `${datePadding}Date: ${exportDate}`;
    XLSX.utils.sheet_add_aoa(ws, [
      [title],
      [`Username: ${username}`, '', dateStr]
    ], { origin: 'A1' });

    ws['!merges'] = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: 8 } },
      { s: { r: 1, c: 0 }, e: { r: 1, c: 1 } },
      { s: { r: 1, c: 2 }, e: { r: 1, c: 8 } }
    ];

    ws['A1'].s = {
      alignment: { horizontal: 'center', vertical: 'center' },
      font: { bold: true, sz: 14 }
    };

    for (let col = 0; col <= 8; col++) {
      const cell = ws[XLSX.utils.encode_cell({ r: 0, c: col })];
      if (cell) {
        cell.s = {
          alignment: { horizontal: 'center', vertical: 'center' },
          font: { bold: true, sz: 14 }
        };
      }
    }

    const wscols = [
      { wch: 20 }, // Name
      { wch: 10 }, // Year
      { wch: 15 }, // Payment Date
      { wch: 20 }, // Receipt Number
      { wch: 20 }, // Area
      { wch: 20 }, // Project
      { wch: 10 }, // Amount
      { wch: 30 }  // Note
    ];
    ws['!cols'] = wscols;

    Object.keys(ws).forEach((key) => {
      if (key.startsWith('!')) {
        return;
      }

      const cell = ws[key];
      const cellRef = XLSX.utils.decode_cell(key);

      if (cellRef.r >= 4 && cellRef.c === 2) {
        if (typeof cell.v === 'number') {
          const formattedDate = this.adjustDateForTimezone(cell.v);
          cell.v = formattedDate;
          cell.t = 's';
        }
      }
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Donations Data');
    XLSX.writeFile(wb, excelFileName);
  }

  adjustDateForTimezone(serialNumber: number): string {
    const originalDate = new Date(Math.round((serialNumber - 25569) * 86400000));
    const timezoneOffset = -originalDate.getTimezoneOffset() / 60;

    const adjustment = (() => {
      switch (true) {
        case timezoneOffset < 0 && timezoneOffset >= -12: {
          return 1;
        }
        default: {
          return 0;
        }
      }
    })();

    originalDate.setDate(originalDate.getDate() + adjustment);
    const formatDate = new FormatDatePipe();
    return formatDate.transform(originalDate);
  }

  setSorting(sortName: string): void {
    this.loading = true;
    const activeSort = this.sort && this.sort.active ? this.sort.active : 'paymentDate';
    const activeDirection = this.sort && this.sort.direction ? this.sort.direction : 'asc';
    const sortDirection = (sortName === activeSort && activeDirection === 'asc') ? 'asc' : 'desc';

    const queryObject = {
      name: this.name || '',
      paymentFromDate: this.formatDateParam(this.paymentFromDate),
      paymentToDate: this.formatDateParam(this.paymentToDate),
      payment_area: this.paymentArea || '',
      payment_project: this.paymentProject || '',
      paymentGreaterThan: this.paymentGreaterThan || 0,
      sortName,
      sortDirection
    };

    this.subscriptionsService.searchDonations(queryObject).subscribe(
      (res) => {
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  onRefresh() {
    if (this.isFirstRefresh) {
      this.sort.active = 'paymentDate';
      this.setSorting('paymentDate');
      this.isFirstRefresh = false;
    }

    this.loading = true;
    const queryObject = {
      name: this.name || '',
      paymentFromDate: this.formatDateParam(this.paymentFromDate),
      paymentToDate: this.formatDateParam(this.paymentToDate),
      payment_area: this.paymentArea || '',
      payment_project: this.paymentProject || '',
      paymentGreaterThan: this.paymentGreaterThan || 0,
      sortName: this.sort.active,
      sortDirection: this.sort.direction
    };

    // console.log(queryObject);
    console.log(this.paymentProjects);

    this.subscriptionsService.searchDonations(queryObject).subscribe(
      (res) => {
        this.dataSource.data = res;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
      }
    );
  }

  private formatDateParam(date: any): string {
    return date ? `${date._i.year}-${date._i.month + 1}-${date._i.date}` : '';
  }

  loadPaymentAreas() {
    this.oldboysService.getPaymentAreas().subscribe((areas: any[]) => {
      this.paymentAreas = areas
        .filter(data => data.deleted !== true)
        .map(area => ({ id: area.id, name: area.name }));
    });
  }

  loadPaymentProjects() {
    this.oldboysService.getPaymentProjects().subscribe((projects: any[]) => {
      this.paymentProjects = projects
        .filter(data => data.deleted !== true)
        .map(project => ({ id: project.id, name: project.name }));
    });
  }

  onKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter') {
      this.onRefresh();
    }
  }

  getTotalAmount(): number {
    return this.dataSource.data
      .map(item => Number(item.paid) || 0)
      .reduce((acc, value) => acc + value, 0);
  }
}
