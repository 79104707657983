import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { Service } from './service';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends Service {
  endpoint = this.getEndpoint('auth');

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public signIn(username, password): Observable<any> {
    const params = {};
    if (username) {
      if (this.validateEmail(username)) {
        params['email'] = username;
      } else {
        params['username'] = username;
      }
    }
    if (password) {
      params['password'] = password;
    }
    return this.http.post(this.endpoint + '/signin', params).pipe(
      catchError(this.handleError('signIn', [])),
      tap(res => res['success'] ? localStorage.setItem('token', res['token']) : localStorage.removeItem('token'))
    );
  }

  public register(registrationRequest): Observable<any> {
    return this.http.post(this.endpoint + '/register', {
      email: registrationRequest.email,
      password: registrationRequest.password,
      data: {
        firstName: registrationRequest.firstName,
        lastName: registrationRequest.lastName,
        dateOfBirth: registrationRequest.dateOfBirth,
        streetAddress: registrationRequest.streetAddress,
        suburb: registrationRequest.suburb,
        town: registrationRequest.town,
        province: registrationRequest.province,
        postCode: registrationRequest.postCode,
        country: registrationRequest.country,
        homePhone: registrationRequest.homePhone,
        workPhone: registrationRequest.workPhone,
        mobilePhone: registrationRequest.mobilePhone,
        house: registrationRequest.house,
        fromYear: registrationRequest.fromYear,
        toYear: registrationRequest.toYear
      }
    }).pipe(
      catchError(this.handleError('register', []))
    );
  }

  public changePassword(oldPassword, newPassword): Observable<any> {
    return this.http.put(this.endpoint + '/password', {
      oldPassword: oldPassword,
      newPassword: newPassword
    }).pipe(
      catchError(this.handleError('change password', []))
    );
  }

  public resetPassword(username): Observable<any> {
    const params = {};
    if (username) {
      if (this.validateEmail(username)) {
        params['email'] = username;
      } else {
        params['username'] = username;
      }
    }
    return this.http.post(this.endpoint + '/password', params).pipe(
      catchError(this.handleError('reset password', []))
    );
  }

  public isSignedIn() {
    return localStorage.getItem('token');
  }

  public signOut() {
    localStorage.removeItem('token');
    localStorage.removeItem('currentUser');
    this.router.navigate(['/login']);
  }
}
