import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Service } from './service';
import { catchError, tap } from 'rxjs/operators';
import { mergeMap } from "rxjs/internal/operators";
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var UserService = /** @class */ (function (_super) {
    tslib_1.__extends(UserService, _super);
    function UserService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('users');
        return _this;
    }
    UserService.prototype.retrieveCurrentUser = function () {
        return this.http.get(this.endpoint + '/current').pipe(catchError(this.handleError('users', [])), tap(function (res) { return localStorage.setItem('currentUser', JSON.stringify(res)); }));
    };
    UserService.prototype.getCurrentUser = function () {
        return JSON.parse(localStorage.getItem('currentUser'));
    };
    UserService.prototype.getCurrentFirstLastName = function () {
        var user = this.getCurrentUser();
        if (user && user.data && user.data.firstName && user.data.lastName) {
            return user.data.firstName + " " + user.data.lastName;
        }
        if (user && user.username) {
            return user.username;
        }
        return 'Anonymous';
    };
    UserService.prototype.getUsername = function () {
        var user = this.getCurrentUser();
        if (user) {
            return user['username'];
        }
        else {
            return 'Anonymous';
        }
    };
    UserService.prototype.getCurrentName = function () {
        var user = this.getCurrentUser();
        if (user) {
            if (user['data']) {
                if (user['data'].firstName && user['data'].lastName) {
                    var un_1 = user['username'] + ' - ' + user['data'].firstName + ' ' + user['data'].lastName;
                    return un_1 ? un_1 : user['email'];
                }
            }
            var un = user['username'];
            return un ? un : user['email'];
        }
        return 'Anonymous';
    };
    UserService.prototype.getCurrentSearch = function () {
        return localStorage.getItem('currentSearch');
    };
    UserService.prototype.setCurrentSearch = function (search) {
        localStorage.setItem('currentSearch', search);
    };
    UserService.prototype.getCurrentSearchFirstYear = function () {
        return localStorage.getItem('currentSearchFirstYear');
    };
    UserService.prototype.setCurrentSearchFirstYear = function (search) {
        localStorage.setItem('currentSearchFirstYear', search);
    };
    UserService.prototype.getCurrentColumns = function () {
        var cs = localStorage.getItem('currentColumns');
        if (cs) {
            return JSON.parse(cs);
        }
        else {
            return [
                'firstname',
                'lastname',
                'firstyear',
                'lastyear',
                'town_city',
                'emailaddress',
                'deceased',
                'validated',
                'alumnimember',
                'isLinked',
            ];
        }
    };
    UserService.prototype.setCurrentColumns = function (columns) {
        localStorage.setItem('currentColumns', JSON.stringify(columns));
    };
    UserService.prototype.updateDetails = function (params) {
        return this.http.put(this.endpoint + '/current', params).pipe(catchError(this.handleError('users', [])));
    };
    UserService.prototype.isContributor = function () {
        var user = this.getCurrentUser();
        return user ? (user['type'] === 'CONTRIBUTOR' || user['type'] === 'ADMIN' || user['type'] === 'YEAR-OWNER') : false;
    };
    UserService.prototype.isAdmin = function () {
        var user = this.getCurrentUser();
        return user ? user['type'] === 'ADMIN' : false;
    };
    UserService.prototype.isContributorOrAdmin = function () {
        return this.isContributor() || this.isAdmin();
    };
    UserService.prototype.getUserDetails = function (id) {
        return this.http.get(this.endpoint + '/' + id).pipe(catchError(this.handleError('user', [])));
    };
    UserService.prototype.putUserData = function (data) {
        var _this = this;
        return this.http.put(this.endpoint + '/data', { data: data }).pipe(mergeMap(function (res) { return _this.retrieveCurrentUser(); }), catchError(this.handleError('user', [])));
    };
    UserService.ngInjectableDef = i0.defineInjectable({ factory: function UserService_Factory() { return new UserService(i0.inject(i1.HttpClient)); }, token: UserService, providedIn: "root" });
    return UserService;
}(Service));
export { UserService };
