import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatTableDataSource, MatSort} from '@angular/material';
import {FormControl, FormGroup} from "@angular/forms";
import {Oldboy, OldboysService} from "../oldboys.service";
import {PageEvent} from '@angular/material';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import {MessageBoxComponent} from "../message-box/message-box.component";
import {OldboyDialogComponent} from "../oldboy-dialog/oldboy-dialog.component";
import {ColumnsDialogComponent} from "../columns-dialog/columns-dialog.component";
import {FilterDialogComponent} from "../filter-dialog/filter-dialog.component";
import {ExportDialogComponent} from "../export-dialog/export-dialog.component";
import {PrintDialogComponent} from "../print-dialog/print-dialog.component";
import {debounceTime, finalize, switchMap, tap} from "rxjs/internal/operators";
import {UserService} from "../user.service";
import {Observable} from "rxjs";
import {map, startWith} from 'rxjs/operators';
import {PublicService} from "../public.service";

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-oldboys',
  templateUrl: './oldboys.component.html',
  styleUrls: ['./oldboys.component.css']
})
export class OldboysComponent implements OnInit, AfterViewInit {

  filters: any = { 'None': {}};
  filterName = 'None';
  filterNames = ['None'];

  availableColumns = { id: 'ID',
    validated: 'Validated',
    deceased: 'Deceased',
    deceasedate: 'Decease date',
    alumnimember: 'OBA member',
    label: 'Label',
    capacitycategory: 'Capacity category',
    relationshipcategory: 'Relationship category',
    gender: 'Gender',
    title: 'Title',
    initials: 'Initials',
    firstname: 'First Name',
    middlenames: 'Middle Names',
    lastname: 'Last Name',
    salutation: 'Salutation',
    //maidenname: 'Maiden Name',
    birthdate: 'Birthdate',
    address1: 'Address Line 1',
    address2: 'Address Line 2',
    suburb: 'Suburb',
    town_city: 'Town/City',
    province_state: 'Province/State',
    postal_code: 'Postal Code',
    country: 'Country',
    hometelephone: 'Home Telephone',
    mobiletelephone: 'Mobile Telephone',
    //faxnumber: 'Fax Number',
    emailaddress: 'Email Address',
    emailaddress2: 'Email Address 2',
    employer: 'Employer',
    profession_jobtitle: 'Profession/Job Title',
    //qualificationlevel: 'Qualification Level',
    //careernotes: 'Career Notes',
    //worktelephone: 'Work Telephone',
    //preferredcontactmethod: '1st Contact Method',
    //secondcontactmethod: '2nd Contact Method',
    //workaddress1: 'Work Address 1',
    //workaddress2: 'Work Address 2',
    //worksuburb: 'Work Suburb',
    //worktown_city: 'Work Town/City',
    //workprovince_state: 'Work Province/State',
    //workpostalcode: 'Work Postal Code',
    //workcountry: 'Work Country',
    housename: 'House',
    firstyear: 'First Year',
    lastyear: 'Last Year',
    border: 'Boarder',
    fromyear: 'From Year',
    toyear: 'To Year',
    organisationalnotes: 'Organisational/School Notes',
    //maritalstatus: 'Marital Status',
    //parent_partnertitle: 'Parent or Partner Title',
    //parent_partnerinitials: 'Parent or Partner Initials',
    //parent_partnerfirstname: 'Parent or Partner First Name',
    //parent_partnerlastname: 'Parent or Partner Last Name',
    //partnerinterests: 'Partner Interests',
    //childrensnames: 'Childrens Names',
    //volunteer1id: 'Volunteer 1 ID',
    //volunteer2id: 'Volunteer 2 ID',
    //fundraisingnotes: 'Fundraising Notes',
    //firstclass: 'First Class',
    //lastclass: 'Last Class',
    //updated: 'Updated',
    //loaded: 'Loaded',
    //buddy: 'Buddy',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    actions: 'Actions',
//    wbhsOldBoy: 'WBHS Old Boy',
    unsubscribed: 'Unsubscribed',
    isLinked: 'Linked to a Member',
    recordType: 'Record Type'};
  displayedColumns: string[] = [];
  search = '';
  searchFirstYear = '';
  searchYear = new FormControl();
  filteredOptions: Observable<string[]>;
  years = [];
  sortby = "";
  sortdirection = '';
  set query(query: string) {
    this.search = (query && query.trim()) || '';
    this.userService.setCurrentSearch(this.search);
    //this.getData();
  }

  get query(): string { return this.search; }

  /*
  displayedColumns: string[] = [ 'id',
    'validated',
    'deceased',
    'deceasedate',
    'alumnimember',
    'label',
    'capacitycategory',
    'relationshipcategory',
    'gender',
    'title',
    'initials',
    'firstname',
    'middlenames',
    'lastname',
    'salutation',
    'maidenname',
    'birthdate',
    'address1',
    'address2',
    'suburb',
    'town_city',
    'province_state',
    'postal_code',
    'country',
    'hometelephone',
    'mobiletelephone',
    'faxnumber',
    'emailaddress',
    'employer',
    'profession_jobtitle',
    'qualificationlevel',
    'careernotes',
    'worktelephone',
    'preferredcontactmethod',
    'secondcontactmethod',
    'workaddress1',
    'workaddress2',
    'worksuburb',
    'worktown_city',
    'workprovince_state',
    'workpostalcode',
    'workcountry',
    'housename',
    'firstyear',
    'lastyear',
    'border',
    'fromyear',
    'toyear',
    'organisationalnotes',
    'maritalstatus',
    'parent_partnertitle',
    'parent_partnerinitials',
    'parent_partnerfirstname',
    'parent_partnerlastname',
    'partnerinterests',
    'childrensnames',
    'volunteer1id',
    'volunteer2id',
    'fundraisingnotes',
    'firstclass',
    'lastclass',
    'updated',
    'loaded',
    'buddy',
    'createdAt',
    'updatedAt',
    'actions'];
  */
  dataSource = new MatTableDataSource<Oldboy>();
  mask = [/[^A-Za-z0-9]+/];

  public length = 0;
  public retrieved = 0;
  public loading = false;

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('table', {read: ElementRef}) table: ElementRef;
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('searchInputYear') searchInputYear: ElementRef;

  constructor(public dialog: MatDialog,
              public snackBar: MatSnackBar,
              private oldboysService: OldboysService,
              private userService: UserService,
              private publicService: PublicService) { }

  ngOnInit() {
    const user = this.userService.getCurrentUser();
    console.log(user);
    this.filters = (user && user.data && user.data['filters']) || { 'None': {}};
    if (!('None' in this.filters)) {
      this.filters['None'] = {};
    }
    this.filterName = (user && user.data && user.data['filterName']) || 'None';
    this.filterNames = Object.keys(this.filters);
    //localStorage.setItem('oldboys_sortby', this.sortby);
    //localStorage.setItem('oldboys_sortdirection', this.sortdirection);
    var temp_sortby = localStorage.getItem('oldboys_sortby')
    if (temp_sortby){
      this.sortby = temp_sortby;
    } else {
      this.sortby = ''
    }
    var temp_sortdirection = localStorage.getItem('oldboys_sortdirection')
    if (temp_sortdirection && (temp_sortdirection === "asc" || temp_sortdirection === "desc")){
      this.sortdirection = temp_sortdirection;
    } else {
      this.sortdirection = ''
    }
    this.dataSource.sort = this.sort;
    this.publicService.getYears().subscribe(years => {
      this.years = years;
      this.filteredOptions = this.searchYear.valueChanges.pipe(
        startWith(''),
        map(value => this.filterYears(value))
      );
      // const search = this.userService.getCurrentSearch();
      // if (search) {
      //   this.search = search;
      // }
      const searchFirstYear = this.userService.getCurrentSearchFirstYear();
      if (searchFirstYear) {
        this.searchFirstYear = searchFirstYear;
        this.searchYear.patchValue(this.searchFirstYear);
      }
      // if (search || searchFirstYear) {
      //   this.getData();
      // }
      this.displayedColumns = this.userService.getCurrentColumns();
    });
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  isLinked(value) {
    if(value && value.recordOwner){
      return true
    } else {
      return false
    }
  }

  isEmpty (value) {
    return (
      // null or undefined
      (value == null) ||

      // has length and it's zero
      (value.hasOwnProperty('length') && value.length === 0) ||

      // is an Object and has no keys
      (value.constructor === Object && Object.keys(value).length === 0)
    )
  }

  getData() {
    const sort = this.sort;
    const str = this.search.replace(/\s/g, '');
    const firstYear = this.searchFirstYear;

    this.loading = true;
    this.oldboysService.getOldboysCount(str, firstYear, this.filters[this.filterName]).subscribe(count => {
      if(count.status) {
        if(count.error && count.error.msg){
          this.openSnackBar(count.error.msg, 'Dismiss')
          this.dataSource.data = []
          this.length = 0
          this.loading = false;
        }
      } else {
        this.length = count;
        this.oldboysService.getOlboys(str, firstYear, this.sortby, this.sortdirection, this.filters[this.filterName]).subscribe(oldboys => {
          if (oldboys) {
            this.retrieved = oldboys.length;
          } else {
            this.retrieved = 0;
          }

          this.dataSource.data = oldboys;
          this.dataSource.sort = sort;
          this.loading = false;
          console.log(this.dataSource)
        });
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
    });
  }

  tableRefresh(oldboys) {
    console.log('table refreshed')
    const sort = this.sort;
    const str = this.search.replace(/\s/g, '');
    const firstYear = this.searchFirstYear;
    this.loading = true;


    this.dataSource.data = oldboys;
    this.dataSource.sort = sort;
    this.loading = false;
  }

  onPageChange(event) {
    //console.log(event);
    //console.log(this.paginator.pageIndex);
    //console.log(this.paginator.pageSize);
    this.getData();
  }

  onAdd() {
    const dialogRef = this.dialog.open(OldboyDialogComponent, {
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.oldboysService.createOldboy(result.data).subscribe(r => {
          this.oldboysService.bulkUpdateOldboyCategory(r.id,result.categoryData,[]).subscribe(u => {
            this.oldboysService.getOlboyCategories(r.id).subscribe(z => {
              r.Categories = z
              var oldboys = this.dataSource.data
              oldboys.push(r)
              this.tableRefresh(oldboys)
            })
          })
        });
      }
    });
  }

  contactEmail(email){
    // console.log('contactEmail',option)
    // console.log(this.oldBoyForm.value.emailaddress)
    // console.log(this.oldBoyForm.value.emailaddress2)
    // console.log(email)
    if (email){
      console.log("Attempted to contact user at " + email + ', if no pop up occured your browser may have blocked it.')
      document.location.href = "mailto:"+email+"?subject=Message from WBHSOBA";
    } else {
      console.log("There is no email address to contact!")
    }
  }

  canContactEmail(email){
    //console.log('canContactEmail',option)
    if (email){
      return true
    }
    return false
  }

  onEdit(oldboy) {
    const dialogRef = this.dialog.open(OldboyDialogComponent, {
      //width: '400px',
      data: { oldboy: oldboy }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        /*
        this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
          if (this.refresh) {
            this.refresh.emit();
          }
        });
        */
      }
    });
  }

  onDelete(oldboy) {
    const dialogRef = this.dialog.open(MessageBoxComponent, {
      //width: '400px',
      data: { title: 'Warning!', text: 'Do you really want to delete ' + oldboy.name + ' ?', answers: ['No', 'Yes']}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result === 'Yes') {
        /*
        this.dataService.deleteItem(item.id).subscribe(i => {
          if (this.refresh) {
            this.refresh.emit();
          }
        });
        */
      }
    });
  }

  onRefresh() {
    this.searchFirstYear = this.searchYear.value;
    this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
    this.getData();
  }

  onFilterChanged() {
    const user = this.userService.getCurrentUser();
    if (user) {
      const data = Object.assign({}, user.data || {});
      this.filterNames = Object.keys(this.filters);
      data.filters = this.filters;
      data.filterName = this.filterName;
      this.userService.putUserData(data).subscribe(res => {
        this.searchFirstYear = this.searchYear.value;
        this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
      });
    }
  }

  onColumns() {
    const dialogRef = this.dialog.open(ColumnsDialogComponent, {
      //height: '400px',
      //width: '600px',
      data: { availableColumns: this.availableColumns,
              selectedColumns: this.displayedColumns }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result;
        this.userService.setCurrentColumns(result);
        //this.getData();
      }
    });
  }

  onFilter() {
    const filterName = this.filterName;
    const filter = this.filters[this.filterName];
    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '600px',
      //maxHeight: '80%',
      data: { filterName: filterName, filter: filter }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result);
        if (result.delete) {
          if (result.filterName !== 'None') {
            delete this.filters[result.filterName];
            this.filterName = 'None';
          } else {
            this.filters['None'] = {};
            this.filterName = 'None';
          }
        } else {
          this.filters[result.filterName] = result.filter;
          this.filterName = result.filterName;
        }
        this.filterNames = Object.keys(this.filters);
        this.userService.putUserData({filters: this.filters, filterName: this.filterName}).subscribe(res => {
          //this.getData();
        });
        //console.log(this);
        /*
        this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
          if (this.refresh) {
            this.refresh.emit();
          }
        });
        */
      }
    });
  }

  onPrint() {
    const newWin = window.open('');
    const css = Array.from(document.getElementsByTagName('style')).reduce(function(acc, current){ return acc + current.outerHTML; }, '');
    newWin.document.write('<html><head>' + css + '</head><body>' + this.table.nativeElement.outerHTML + '</body></html>');
    newWin.print();
    newWin.close();
  }

  onCellClicked(event) {
    console.log(event)
    const dialogRef = this.dialog.open(OldboyDialogComponent, {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: '100%',
      data: { oldboy: event }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.delete) {
        const dialogRefDel = this.dialog.open(MessageBoxComponent, {
          //width: '400px',
          data: { title: 'Warning!', text: 'Do you really want to delete ' + result.data.firstname + ' ' + result.data.lastname + ' ?', answers: ['No', 'Yes']}
        });

        dialogRefDel.afterClosed().subscribe(resultDel => {
          console.log(resultDel);
          if (resultDel === 'Yes') {
            console.log(result);
            this.oldboysService.deleteOldboy(result.id).subscribe(i => {
              var oldboys = this.dataSource.data
              var tempOldboys = []
              for (var value in oldboys){
                if(oldboys[value].id !== result.id){
                  tempOldboys.push(oldboys[value])
                }
              }
              this.tableRefresh(tempOldboys)
            });
          }
        });
      } else if (result) {
        this.oldboysService.updateOldboy(event.id, result.data).subscribe(r => {
          this.oldboysService.bulkUpdateOldboyCategory(r.id,result.categoryData,result.categoriesToDelete).subscribe(u => {
            this.oldboysService.getOlboyCategories(r.id).subscribe(z => {
              r.Categories = z
              var oldboys = this.dataSource.data
              for (var value in oldboys){
                if(oldboys[value].id === result.id){
                  oldboys[value] = r
                }
              }
              this.tableRefresh(oldboys)
            })
          })
        });
      }
    });
  }

  sortData(event){
    this.sortby = event.active;
    if (event.direction && event.direction.length > 0) {
      this.sortdirection = event.direction;
    } else {
      this.sortdirection = null;
    }
    localStorage.setItem('oldboys_sortby', this.sortby);
    localStorage.setItem('oldboys_sortdirection', this.sortdirection);
    this.getData();
  }

  onExport() {

    const dialogRef = this.dialog.open(ExportDialogComponent, {
      //width: '400px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //this.exportAsExcelFile(this.dataSource.data, result.filename);
        if (result.target === 'pdf') {
          html2canvas(this.table.nativeElement).then(canvas => {
            // Few necessary setting options
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
            const pageWidth = pdf.internal.pageSize.getWidth();
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', (pageWidth-imgWidth)/2, position, imgWidth, imgHeight)
            pdf.save(result.filename + '.pdf'); // Generated PDF
          });
        } else {
          this.exportTableAsExcelFile(result.filename + '.xlsx');
        }

        /*
        this.dataService.updateItem(item.id, {name: result.name }).subscribe(p => {
          if (this.refresh) {
            this.refresh.emit();
          }
        });
        */
      }
    });
  }

  exportTableAsExcelFile(excelFileName: string): void {
    console.log(this.table.nativeElement);
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.table.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'WBHS Old Boys data');
    XLSX.writeFile(wb, excelFileName);
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new  Date().getTime() + EXCEL_EXTENSION);
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      this.searchInput.nativeElement.blur();
      this.onRefresh(); // Trigger search when Enter is pressed
    }
  }

  onYearKeydown(event) {
    if (event.key === 'Enter') {
      //console.log(this.searchYear.value);
      this.searchFirstYear = this.searchYear.value;
      this.userService.setCurrentSearchFirstYear(this.searchFirstYear);
      this.searchInputYear.nativeElement.blur();
      //this.getData();
    }
  }

  private filterYears(value: string): string[] {
    if (value) {
      const filterValue = value.toString();
      return this.years.filter(option => option && option.toString().indexOf(filterValue) === 0);
    } else {
      return this.years;
    }
  }

  getRecords(element) {
    var output_string = ''
    for (let sub_element in element.Categories){
      if (output_string !== ''){
        output_string = output_string + ', '
      }
      if (element.Categories[sub_element].name){
        output_string = output_string + element.Categories[sub_element].name
      } else if (element.Categories[sub_element].Category.name){
        output_string = output_string + element.Categories[sub_element].Category.name
      }
    }
    return output_string
  }

  getBooleanDisplayValue(val) {
    return (val === 'true' || val === true) ? 'yes' : 'no';
  }

}
