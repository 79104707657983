<h1 mat-dialog-title *ngIf="!id">Add new activity</h1>
<h1 mat-dialog-title *ngIf="id">Editing {{oldName}}</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="activity">

  <mat-form-field>
    <input type="text" matInput formControlName="name" placeholder="Name">
  </mat-form-field>
  <mat-checkbox formControlName="sport">Sport</mat-checkbox>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog(true)" *ngIf="id" color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial [disabled]="!isValid()" color="primary">Save</button>
</div>
