<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">contacts</mat-icon>Contacts
    </mat-card-title>
    <mat-card-subtitle>
      This is a member only functionality to access and issue contact requests
    </mat-card-subtitle>
    <mat-card-content>

    </mat-card-content>
  </mat-card>
</div>
