import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {UserService} from "../user.service";
import {MatSnackBar} from "@angular/material";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css']
})
export class AccountComponent implements OnInit {

  detailsForm = new FormGroup({
    username: new FormControl(this.userService.getCurrentUser().username),
    email: new FormControl(this.userService.getCurrentUser().email, [Validators.email]),
  });
  passwordForm = new FormGroup({
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    confirmNewPassword: new FormControl('')
  });

  constructor(private authService: AuthService,
              private userService: UserService,
              public snackBar: MatSnackBar) { }

  ngOnInit() {
    //const user = this.userService.getCurrentUser();
    //console.log(user);
    //this.detailsForm.setValue({username: user.user, email: user.email});
  }

  onSubmitDetails() {
    this.userService.updateDetails(this.detailsForm.value).subscribe(result => {
      console.log(result);
      if (result.success) {
        this.userService.retrieveCurrentUser().subscribe( user => {
          this.detailsForm.setValue({username: user.username, email: user.email});
          this.openSnackBar(result['msg'],'Dismiss');
        });
      } else {
        this.openSnackBar(result['error']['msg'],'Dismiss');
      }
    });
  }

  onSubmitPassword() {
    console.log(this.passwordForm.value);
    this.authService.changePassword(this.passwordForm.value.oldPassword,
                                    this.passwordForm.value.newPassword).subscribe( result => {
      //console.log(result);
      if (result['success']) {
        this.openSnackBar('Password changed successfully.','Dismiss');
      } else {
        this.openSnackBar(result['error']['msg'],'Dismiss');
      }
    });
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
    });
  }

}
