import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import {Service} from './service';

import { Observable } from 'rxjs';
import { catchError, tap, flatMap  } from 'rxjs/operators';
import {mergeMap, switchMap} from "rxjs/internal/operators";

@Injectable({
  providedIn: 'root'
})
export class UserService extends Service {
  endpoint = this.getEndpoint('users');
  constructor(private http: HttpClient) {
    super();
  }
  public retrieveCurrentUser(): Observable<any> {
    return this.http.get(this.endpoint + '/current').pipe(
      catchError(this.handleError('users', [])),
      tap(res => localStorage.setItem('currentUser', JSON.stringify(res)))
    );
  }
  public getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  public getCurrentFirstLastName() {
    const user = this.getCurrentUser();
    if (user && user.data && user.data.firstName && user.data.lastName) {
      return `${user.data.firstName} ${user.data.lastName}`;
    }
    if (user && user.username) {
      return user.username;
    }
    return 'Anonymous';
  }

  public getUsername() {
    const user = this.getCurrentUser();
    if (user) {
      return user['username']
    } else {
      return 'Anonymous'
    }
  }
  
  public getCurrentName() {
    const user = this.getCurrentUser();
    if (user) {
      if (user['data']){
        if(user['data'].firstName && user['data'].lastName){
          const un = user['username'] + ' - ' + user['data'].firstName + ' ' + user['data'].lastName
          return un ? un : user['email'];
        }
      }
      const un = user['username'];
      return un ? un : user['email'];
    }
    return 'Anonymous';
  }
  public getCurrentSearch() {
    return localStorage.getItem('currentSearch');
  }

  public setCurrentSearch(search) {
    localStorage.setItem('currentSearch', search);
  }

  public getCurrentSearchFirstYear() {
    return localStorage.getItem('currentSearchFirstYear');
  }

  public setCurrentSearchFirstYear(search) {
    localStorage.setItem('currentSearchFirstYear', search);
  }

  public getCurrentColumns() {
    const cs = localStorage.getItem('currentColumns');
    if (cs) {
      return JSON.parse(cs);
    } else {
      return [
        'firstname',
        'lastname',
        'firstyear',
        'lastyear',
        'town_city',
        'emailaddress',
        'deceased',
        'validated',
        'alumnimember',
        'isLinked',
      ];
    }
  }

  public setCurrentColumns(columns) {
    localStorage.setItem('currentColumns', JSON.stringify(columns));
  }

  public updateDetails(params): Observable<any> {
    return this.http.put(this.endpoint + '/current', params).pipe(
      catchError(this.handleError('users', []))
    );
  }

  public isContributor() {
    const user = this.getCurrentUser();
    return user ? (user['type'] === 'CONTRIBUTOR' || user['type'] === 'ADMIN' || user['type'] === 'YEAR-OWNER') : false;
  }

  public isAdmin() {
    const user = this.getCurrentUser();
    return user ? user['type'] === 'ADMIN' : false;
  }

  public isContributorOrAdmin() {
    return this.isContributor() || this.isAdmin();
  }

  public getUserDetails(id) {
    return this.http.get(this.endpoint + '/' + id).pipe(
      catchError(this.handleError('user', []))
    );
  }

  public putUserData(data): Observable<any> {
    return this.http.put(this.endpoint + '/data', {data: data } ).pipe(
      mergeMap(res => this.retrieveCurrentUser()),
      catchError(this.handleError('user', []))
    );
  }
}
