import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { Router } from '@angular/router';
import { UserService } from '../user.service';
import { SidebarService } from '../sidebar.service';
import { finalize } from 'rxjs/operators';
import { OldboyDialogComponent } from '../oldboy-dialog/oldboy-dialog.component';
import { MembershipRequestService } from '../membership-request.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {

  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;

  public username: string;
  public password: string;

  loading = false;

  @Output() loginSuccess = new EventEmitter();

  constructor(private auth: AuthService,
    private userService: UserService,
    private sidebarService: SidebarService,
    public snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private membershipRequestService: MembershipRequestService) { }

  ngOnInit() {
  }

  public onLogin() {
    console.log('onLogin');
    this.loading = true;
    this.auth.signIn(this.username, this.password)
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        if (res['success']) {
          console.log('success');
          this.userService.retrieveCurrentUser().subscribe(cu => {
            if (this.loginSuccess) {
              this.loginSuccess.emit();
            }

            if (!this.userService.isContributorOrAdmin()) {
              if (!cu.prospectId) {
                this.membershipRequestService.getUserActiveClaimRequests().subscribe(cr => {
                  if (this.membershipRequestService.isUserAwaitingClaim(cr)) {
                    this.router.navigate(['/home']);
                  } else {
                    setTimeout(() => this.dialog.open(this.dialogRef), 500);
                  }
                })
              } else {
                this.router.navigate(['/home']);
              }
            } else {
              this.router.navigate(['/home']);
            }
            // this.sidebarService.setSidebarOpenState(true);
          });
        } else {
          this.openSnackBar(res['error']['msg'], 'Dismiss');
        }
      });
  }
  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
    });
  }

  onResetPassword() {
    if (this.username) {
      this.auth.resetPassword(this.username).subscribe(res => {
        if (res['success']) {
          this.openSnackBar(res['msg'], 'Dismiss');
        } else {
          this.openSnackBar(res['error']['msg'], 'Dismiss');
        }
      });
    } else {
      this.openSnackBar('Please enter your username or email', 'Dismiss');
    }
  }

  onYes() {
    this.router.navigate(['/claimrecord']);
  }

  onNo() {
    this.router.navigate(['/home']);
  }
}
