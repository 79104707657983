import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {
  transform(value: string | Date, locale: string = 'en-uk'): string {
    if (!value) return '';

    let date: Date;

    // If the value is already a Date object, use it directly
    if (value instanceof Date) {
      date = value;
    } else {
      date = new Date(value); // Parse the date string as ISO 8601
    }

    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid Date';
    }

    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Detected Local Time Zone:', localTimeZone);

    // Use the passed locale and time zone to format the date
    return date.toLocaleDateString(locale, {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      timeZone: localTimeZone // Pass the desired time zone
    });
  }
}
