import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { Service } from './service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "@angular/router";
var AuthService = /** @class */ (function (_super) {
    tslib_1.__extends(AuthService, _super);
    function AuthService(http, router) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.router = router;
        _this.endpoint = _this.getEndpoint('auth');
        return _this;
    }
    AuthService.prototype.validateEmail = function (email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    AuthService.prototype.signIn = function (username, password) {
        var params = {};
        if (username) {
            if (this.validateEmail(username)) {
                params['email'] = username;
            }
            else {
                params['username'] = username;
            }
        }
        if (password) {
            params['password'] = password;
        }
        return this.http.post(this.endpoint + '/signin', params).pipe(catchError(this.handleError('signIn', [])), tap(function (res) { return res['success'] ? localStorage.setItem('token', res['token']) : localStorage.removeItem('token'); }));
    };
    AuthService.prototype.register = function (registrationRequest) {
        return this.http.post(this.endpoint + '/register', {
            email: registrationRequest.email,
            password: registrationRequest.password,
            data: {
                firstName: registrationRequest.firstName,
                lastName: registrationRequest.lastName,
                dateOfBirth: registrationRequest.dateOfBirth,
                streetAddress: registrationRequest.streetAddress,
                suburb: registrationRequest.suburb,
                town: registrationRequest.town,
                province: registrationRequest.province,
                postCode: registrationRequest.postCode,
                country: registrationRequest.country,
                homePhone: registrationRequest.homePhone,
                workPhone: registrationRequest.workPhone,
                mobilePhone: registrationRequest.mobilePhone,
                house: registrationRequest.house,
                fromYear: registrationRequest.fromYear,
                toYear: registrationRequest.toYear
            }
        }).pipe(catchError(this.handleError('register', [])));
    };
    AuthService.prototype.changePassword = function (oldPassword, newPassword) {
        return this.http.put(this.endpoint + '/password', {
            oldPassword: oldPassword,
            newPassword: newPassword
        }).pipe(catchError(this.handleError('change password', [])));
    };
    AuthService.prototype.resetPassword = function (username) {
        var params = {};
        if (username) {
            if (this.validateEmail(username)) {
                params['email'] = username;
            }
            else {
                params['username'] = username;
            }
        }
        return this.http.post(this.endpoint + '/password', params).pipe(catchError(this.handleError('reset password', [])));
    };
    AuthService.prototype.isSignedIn = function () {
        return localStorage.getItem('token');
    };
    AuthService.prototype.signOut = function () {
        localStorage.removeItem('token');
        localStorage.removeItem('currentUser');
        this.router.navigate(['/login']);
    };
    AuthService.ngInjectableDef = i0.defineInjectable({ factory: function AuthService_Factory() { return new AuthService(i0.inject(i1.HttpClient), i0.inject(i2.Router)); }, token: AuthService, providedIn: "root" });
    return AuthService;
}(Service));
export { AuthService };
