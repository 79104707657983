<mat-spinner *ngIf="loading"></mat-spinner>
<div *ngIf="!loading">
  <div class="signin-container" fxLayout="column">
    <p class="line">
      <label for="homePhone">Username or Email Address</label>
      <input name="username" [(ngModel)]="username">
    </p>
    <p class="line">
      <label for="homePhone">My Password</label>
      <input name="password" type="password" [(ngModel)]="password">
    </p>
    <div fxLayout="row" fxLayoutAlign="end">
      <p class="submit line">
        <button mat-button color="primary" (click)="onLogin()" fxFlex="">Login</button>
      </p>
    </div>
  </div>
</div>
<p id="nav">
  <a [routerLink]="['/register']">Register</a> | <a class="clickable" (click)="onResetPassword()">Lost your Password?</a>
</p>

<ng-template #dialogRef>
  <h1 mat-dialog-title>Record Claim</h1>
  <div mat-dialog-content>
    We notice you have not yet claimed your record in the WBHS Old Boys Database. Claiming your record will link your login details to your record and you will be able to keep your details up to date. Would you like to set it up now?
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close (click)="onNo()">No</button>
    <button mat-button mat-dialog-close (click)="onYes()">Yes</button>
  </div>
</ng-template>
