import * as tslib_1 from "tslib";
import { Observable } from 'rxjs';
import { Service } from './service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var SubscriptionsService = /** @class */ (function (_super) {
    tslib_1.__extends(SubscriptionsService, _super);
    function SubscriptionsService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('oldboys');
        return _this;
    }
    SubscriptionsService.prototype.getSubscriptions = function () {
        return this.http.get(this.endpoint + '/memberships').pipe(catchError(this.handleError('memberships', [])));
    };
    SubscriptionsService.prototype.Search = function (queryObject) {
        var path = "/memberships?paymentMethodId=" + queryObject.payment_method + "&subscriptionCategoryId=" + queryObject.subscription_category + "&paymentFromDate=" + queryObject.paymentFromDate + "&paymentToDate=" + queryObject.paymentToDate + "&name=" + queryObject.name + "&sortName=" + queryObject.sortName + "&sortDirection=" + queryObject.sortDirection + "&renewedToDate=" + queryObject.renewedToDate + "&renewedFromDate=" + queryObject.renewedFromDate;
        return this.http.get(this.endpoint + path).pipe(catchError(this.handleError('filter', [])));
    };
    SubscriptionsService.prototype.searchDonations = function (queryObject) {
        var path = "/donations?paymentAreaId=" + queryObject.payment_area + "&paymentProjectId=" + queryObject.payment_project + "&paymentFromDate=" + queryObject.paymentFromDate + "&paymentToDate=" + queryObject.paymentToDate + "&name=" + queryObject.name + "&sortName=" + queryObject.sortName + "&sortDirection=" + queryObject.sortDirection + "&renewedToDate=" + queryObject.renewedToDate + "&renewedFromDate=" + queryObject.renewedFromDate + "&paymentGreaterThan=" + queryObject.paymentGreaterThan;
        return this.http.get(this.endpoint + path).pipe(catchError(this.handleError('filter', [])));
    };
    SubscriptionsService.ngInjectableDef = i0.defineInjectable({ factory: function SubscriptionsService_Factory() { return new SubscriptionsService(i0.inject(i1.HttpClient)); }, token: SubscriptionsService, providedIn: "root" });
    return SubscriptionsService;
}(Service));
export { SubscriptionsService };
