import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Service } from './service';
import { Membership } from './oldboys.service';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionsService extends Service {

  endpoint = this.getEndpoint('oldboys');

  constructor(private http: HttpClient) {
    super();
  }

  getSubscriptions(): Observable<Membership[]> {
    return this.http.get( this.endpoint + '/memberships').pipe(
      catchError(this.handleError('memberships', []))
    );
  }

  Search(queryObject:any):Observable<any>{
 
    let path= `/memberships?paymentMethodId=${queryObject.payment_method}&subscriptionCategoryId=${queryObject.subscription_category}&paymentFromDate=${queryObject.paymentFromDate}&paymentToDate=${queryObject.paymentToDate}&name=${queryObject.name}&sortName=${queryObject.sortName}&sortDirection=${queryObject.sortDirection}&renewedToDate=${queryObject.renewedToDate}&renewedFromDate=${queryObject.renewedFromDate}`;
    return this.http.get(this.endpoint+path).pipe(
      catchError(this.handleError('filter', []))
    )
  }

  searchDonations(queryObject:any):Observable<any>{
 
    let path= `/donations?paymentAreaId=${queryObject.payment_area}&paymentProjectId=${queryObject.payment_project}&paymentFromDate=${queryObject.paymentFromDate}&paymentToDate=${queryObject.paymentToDate}&name=${queryObject.name}&sortName=${queryObject.sortName}&sortDirection=${queryObject.sortDirection}&renewedToDate=${queryObject.renewedToDate}&renewedFromDate=${queryObject.renewedFromDate}&paymentGreaterThan=${queryObject.paymentGreaterThan}`;
    return this.http.get(this.endpoint+path).pipe(
      catchError(this.handleError('filter', []))
    )
  }
}
