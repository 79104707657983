<div id="oldboy-dialog-container">
  <mat-toolbar [color]="['primary']" mat-dialog-title fxLayout="row">
    <div fxFlex="95"><div class="logo"><img src="assets/wbhs-logo.svg"></div></div>
    <div fxFlex><button mat-mini-fab (click)="closeDialog()">X</button></div>
  </mat-toolbar>
  <div mat-dialog-content class="oldboy-dialog-content" fxLayout="column">
    <form [formGroup]="oldBoyForm">
      <div fxLayout="column">

        <mat-card>
          <mat-card-content>
            <div fxLayout="row"  fxLayoutGap="5px" fxLayoutAlign="start center">
              <div  *ngIf="creating" fxFlex="7 0 auto"><h1 class="mat-h1 big">New</h1></div>
              <div  *ngIf="!creating" fxFlex="7 0 auto"><h1 class="mat-h1 big">{{oldBoyForm.get('firstname').value + ' ' + ' ' + oldBoyForm.get('lastname').value + ' ' + (oldBoyForm.get('firstyear').value || '') }}</h1></div>
            </div>
          </mat-card-content>
        </mat-card>


        <mat-card>
          <mat-card-title>
            Personal
          </mat-card-title>
          <mat-card-content>

            <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

              <div class="formheader">
                <h3 class="mat-h3 formlabel" >Name</h3>
              </div>

              <mat-form-field   class="namefield">
                <input matInput  placeholder="First" formControlName="firstname" (keydown)="onKeydown($event)" cdkFocusInitial>
              </mat-form-field>
              <mat-form-field  class="namefield">
                <input matInput  placeholder="Last" formControlName="lastname" (keydown)="onKeydown($event)">
              </mat-form-field>
              <div fxFlexFill></div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>
            Contact Details
          </mat-card-title>

          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Email Address</h3>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput formControlName="emailaddress"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Street Address</h3>
                </div>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Street Address Line 1" formControlName="address1"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="addressfield">
                  <input matInput placeholder="Street Address Line 2" formControlName="address2"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Suburb</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="suburb"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Town/City</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="town_city"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Province/State</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="province_state"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Postal Code</h3>
                </div>
                <mat-form-field class="yearfield">
                  <input matInput formControlName="postalcode"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <div class="formheader2">
                  <h3 class="mat-h3 formlabel" >Country</h3>
                </div>
                <mat-form-field class="namefield">
                  <input matInput formControlName="country"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Telephone</h3>
                </div>
                <mat-form-field class="telephonefield">
                  <input matInput placeholder="Home" formControlName="hometelephone"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="telephonefield">
                  <input matInput placeholder="Mobile" formControlName="mobiletelephone"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>
            School Information
          </mat-card-title>
          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Attended</h3>
                </div>
                <mat-form-field class="yearfield">
                  <input matInput placeholder="First Year" formControlName="firstyear"  (keydown)="onKeydown($event)">
                </mat-form-field>
                <mat-form-field class="yearfield">
                  <input matInput placeholder="Last Year" formControlName="lastyear"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >House</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="housename"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >School Notes</h3>
                </div>
                  <mat-form-field class="notesfield">
                    <textarea matInput formControlName="organisationalnotes" cdkTextareaAutosize cdkAutosizeMinRows=2 cdkAutosizeMaxRows=5></textarea>
                  </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-title>Work & Career</mat-card-title>
          <mat-card-content>
            <div fxLayout="column">

              <div fxLayout="row" fxLayoutGap="5px" class="formrow  first-formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Profession/Job Title</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="profession_jobtitle"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

              <div fxLayout="row" fxLayoutGap="5px" class="formrow">
                <div class="formheader">
                  <h3 class="mat-h3 formlabel" >Employer</h3>
                </div>
                <mat-form-field class="housefield">
                  <input matInput formControlName="employer"  (keydown)="onKeydown($event)">
                </mat-form-field>
              </div>

            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </form>
  </div>
  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button (click)="closeDialog()"  color="primary">Cancel</button>
    <button mat-raised-button (click)="submitDialog()" [disabled]="false" type="submit" color="primary">Submit</button>
  </div>
</div>
