import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
  selector: 'app-category-edit-dialog',
  templateUrl: './category-edit-dialog.component.html',
  styleUrls: ['./category-edit-dialog.component.css']
})
export class CategoryEditDialogComponent implements OnInit {
  id = null;
  oldName = null;
  category = new FormGroup({
    name: new FormControl()
  });
  constructor(public dialogRef: MatDialogRef<CategoryEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.category) {
      this.id = data.category.id;
      this.category.patchValue(data.category);
      this.oldName = data.category.name;
    }
  }

  ngOnInit() {
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (remove): void {
    if  (remove) {
      this.dialogRef.close({delete: true, id: this.id});
    } else {
      const result = this.category.value;
      if (this.id) {
        result.id = this.id;
      }
      this.dialogRef.close(result);
    }
  }

  isValid() {
    return this.category.get('name').value;
  }
}
