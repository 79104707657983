<h1 mat-dialog-title *ngIf="!id">Add new activity</h1>
<h1 mat-dialog-title *ngIf="id">Editing {{activity.get('name').value}}</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="activity">

  <mat-form-field>
    <input type="text" placeholder="Name"  matInput formControlName="name" [matAutocomplete]="auto">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let activity of filteredOptions | async" [value]="activity.name">{{activity.name}}</mat-option>
  </mat-autocomplete>

  <mat-form-field>
    <input matInput placeholder="Position/role" formControlName="position_role"  (keydown)="onKeydown($event)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Years involved" formControlName="years_involved"  (keydown)="onKeydown($event)">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Group/team" formControlName="group_team"  (keydown)="onKeydown($event)">
  </mat-form-field>
  <mat-form-field>
    <textarea matInput placeholder="Notes" formControlName="notes"></textarea>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog(true)" *ngIf="id" color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial [disabled]="!isValid()" color="primary">Save</button>
</div>
