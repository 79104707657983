import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from "@angular/flex-layout";
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule} from "@angular/cdk/drag-drop";

import {
  MAT_DATE_LOCALE,
  MatAutocompleteModule,
  MatButtonModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatExpansionModule,
  MatNativeDateModule,
  MatProgressSpinnerModule, MatRadioModule,
  MatSortModule, MatTooltipModule
} from "@angular/material";
import { MatCardModule } from "@angular/material";
import { MatCheckboxModule } from "@angular/material";
import { MatFormFieldModule } from "@angular/material";
import { MatGridListModule } from '@angular/material';
import { MatIconModule } from "@angular/material";
import { MatInputModule} from "@angular/material";
import { MatListModule } from "@angular/material";
import { MatMenuModule } from "@angular/material";
import { MatOptionModule } from "@angular/material";
import { MatPaginatorModule } from "@angular/material";
import { MatSelectModule } from "@angular/material";
import { MatSidenavModule } from "@angular/material";
import { MatSlideToggleModule } from "@angular/material";
import { MatSnackBarModule } from '@angular/material';
import { MatTableModule } from "@angular/material";
import { MatTabsModule } from "@angular/material";
import { MatToolbarModule } from "@angular/material";

import {DateAdapter, MAT_DATE_FORMATS} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import * as moment from 'moment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
/*
export const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
*/

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { SigninComponent } from './signin/signin.component';
import { HomeComponent } from './home/home.component';
import { JwtInterceptor } from './jwt-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { AccountComponent } from './account/account.component';
import { AccessComponent } from './access/access.component';
import { YearsComponent } from './years/years.component';
import { ReportingComponent } from './reporting/reporting.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ProspectsComponent } from './prospects/prospects.component';
import { ReportsComponent } from './reports/reports.component';
import { PaymentsComponent } from './payments/payments.component';
import { KeywordsComponent } from './keywords/keywords.component';
import { CommitteesComponent } from './committees/committees.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { CampaignsComponent } from './campaigns/campaigns.component';
import { BankingComponent } from './banking/banking.component';
import { OldboysComponent } from './oldboys/oldboys.component';
import { OldboyDialogComponent } from './oldboy-dialog/oldboy-dialog.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { ColumnsDialogComponent } from './columns-dialog/columns-dialog.component';
import { ExportDialogComponent } from './export-dialog/export-dialog.component';
import { PrintDialogComponent } from './print-dialog/print-dialog.component';
import { DisableControlDirective } from './disable-control.directive';
import { CreateUserDialogComponent } from './create-user-dialog/create-user-dialog.component';
import { ActivityDialogComponent } from './activity-dialog/activity-dialog.component';
import { CategoryDialogComponent } from './category-dialog/category-dialog.component';
import { MembershipDialogComponent } from './membership-dialog/membership-dialog.component';
import { KeywordDialogComponent } from './keyword-dialog/keyword-dialog.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { ActivityEditDialogComponent } from './activity-edit-dialog/activity-edit-dialog.component';
import { CategoryEditDialogComponent } from './category-edit-dialog/category-edit-dialog.component';
import { KeywordEditDialogComponent } from './keyword-edit-dialog/keyword-edit-dialog.component';
import { RegisterComponent } from './register/register.component';
import { RequestsComponent } from './requests/requests.component';
import { LayoutComponent } from './layout/layout.component';
import { SubmitInfoDialogComponent } from './submit-info-dialog/submit-info-dialog.component';
import { ClaimrecordComponent } from './claimrecord/claimrecord.component';
import { UnlinkDialogComponent } from './unlink-dialog/unlink-dialog.component';
import { NoteDialogComponent } from './note-dialog/note-dialog.component';
import { UserEditDialogComponent } from './user-edit-dialog/user-edit-dialog.component';
import { SubscriptionsReportComponent } from './subscriptions-report/subscriptions-report.component';
import { SubscriptionFilterComponent } from './subscription-filter/subscription-filter.component';
import { SendEmailDialogComponent } from './send-email-dialog/send-email-dialog.component';
import { SubscriptionCategoryEditDialogComponent } from './subscription-category-edit-dialog/subscription-category-edit-dialog.component';
import { PaymentMethodEditDialogComponent } from './payment-method-edit-dialog/payment-method-edit-dialog.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { FormatDatePipe } from './formatDate';
import { DonationDialogComponent } from './donation-dialog/donation-dialog.component'; 
import { PaymentAreaEditDialogComponent } from './payment-area-edit-dialog/payment-area-edit-dialog.component';
import { PaymentProjectEditDialogComponent } from './payment-project-edit-dialog/payment-project-edit-dialog.component';
import { DonationsReportModule } from './donations-report/donations-report.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignupComponent,
    SigninComponent,
    HomeComponent,
    AccountComponent,
    AccessComponent,
    YearsComponent,
    ReportingComponent,
    ContactsComponent,
    ProspectsComponent,
    ReportsComponent,
    PaymentsComponent,
    KeywordsComponent,
    CommitteesComponent,
    VolunteersComponent,
    CampaignsComponent,
    BankingComponent,
    OldboysComponent,
    OldboyDialogComponent,
    MessageBoxComponent,
    FilterDialogComponent,
    ColumnsDialogComponent,
    ExportDialogComponent,
    PrintDialogComponent,
    DisableControlDirective,
    CreateUserDialogComponent,
    ActivityDialogComponent,
    CategoryDialogComponent,
    MembershipDialogComponent,
    KeywordDialogComponent,
    MaintenanceComponent,
    ActivityEditDialogComponent,
    CategoryEditDialogComponent,
    KeywordEditDialogComponent,
    RegisterComponent,
    RequestsComponent,
    LayoutComponent,
    SubmitInfoDialogComponent,
    ClaimrecordComponent,
    UnlinkDialogComponent,
    NoteDialogComponent,
    UserEditDialogComponent,
    SubscriptionsReportComponent,
    SubscriptionFilterComponent,
    SendEmailDialogComponent,
    SubscriptionCategoryEditDialogComponent,
    PaymentMethodEditDialogComponent,
    PaymentReportComponent,
    FormatDatePipe,
    DonationDialogComponent,
    PaymentAreaEditDialogComponent,
    PaymentProjectEditDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    DragDropModule,
    AppRoutingModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatSortModule,
    ReactiveFormsModule,
    TextMaskModule,
    DonationsReportModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-NZ'},
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    //{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  entryComponents: [
    MessageBoxComponent,
    FilterDialogComponent,
    ExportDialogComponent,
    ColumnsDialogComponent,
    PrintDialogComponent,
    OldboyDialogComponent,
    CreateUserDialogComponent,
    ActivityDialogComponent,
    CategoryDialogComponent,
    MembershipDialogComponent,
    DonationDialogComponent,
    KeywordDialogComponent,
    ActivityEditDialogComponent,
    CategoryEditDialogComponent,
    KeywordEditDialogComponent,
    SubmitInfoDialogComponent,
    UnlinkDialogComponent,
    NoteDialogComponent,
    UserEditDialogComponent,
    SubscriptionFilterComponent,
    SendEmailDialogComponent,
    SubscriptionCategoryEditDialogComponent,
    PaymentMethodEditDialogComponent,
    PaymentAreaEditDialogComponent,
    PaymentProjectEditDialogComponent
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
