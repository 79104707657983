<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">label</mat-icon>Keywords overview and management
    </mat-card-title>
    <mat-card-subtitle>
      This is a page where keywords can be searched, managed and added by Contributors and Admins
    </mat-card-subtitle>
    <mat-card-content>

    </mat-card-content>
  </mat-card>
</div>
