import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { OldboysService, Donation} from "../oldboys.service";

@Component({
  selector: 'app-donation-dialog',
  templateUrl: './donation-dialog.component.html',
  styleUrls: ['./donation-dialog.component.css']
})
export class DonationDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('paymentDateInput') paymentDateInput: ElementRef;
  @ViewChild('receiptNumberInput') receiptNumberInput: ElementRef;

  
  id = null;
  EditReceiptNumber: boolean = false;
  IsAllowSendReceipt: boolean = false;
  receiptNumber: string;
  prospect_id = null;
  donation = new FormGroup({
    id: new FormControl(),
    payment_date: new FormControl(),
    payment_area: new FormControl(null, Validators.required),
    payment_project: new FormControl(null),
    paid: new FormControl(null, Validators.required),
    payment_method: new FormControl(null, Validators.required),
    comment: new FormControl(),
  });


  donationCopy: any;

  paymentAreas: string[] = [];
  paymentProjects: string[] = [];
  paymentMethods: string[] = [];

  constructor(
    public dialogRef: MatDialogRef<DonationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private oldboysService: OldboysService, // Inject the OldboysService
    private cdr: ChangeDetectorRef, // Inject ChangeDetectorRef
    private zone: NgZone // Inject NgZone
  ) {
    if (data.prospect_id) {
      this.prospect_id = data.prospect_id;
      this.getmaxid();
    }

    if (data.donation) {
      this.id = data.donation.id;
      this.donation.patchValue({
        ...data.donation,
        payment_date: data.donation.payment_date || null // Ensure payment_date has a default value
      });
      this.donation.controls.payment_method.patchValue(data.donation.payment_method_id);
      this.donation.controls.payment_area.patchValue(data.donation.payment_area_id);
      this.donation.controls.payment_project.patchValue(data.donation.payment_project_id);
      this.receiptNumber = data.donation.receipt_number;
      this.donationCopy = { receipt_number: this.receiptNumber, ...this.donation.value };
    }
  }

  ngOnInit() {
    this.loadPaymentAreas();
    this.loadPaymentProjects();
    this.loadPaymentMethods();
  }

  ngAfterViewInit() {
    this.makePaymentDateFocus()
  }

  

  makePaymentDateFocus(makeReciptSeleted:boolean =false){
    this.zone.run(() => {
      this.cdr.detectChanges(); // Trigger change detection
      setTimeout(() => {
        if (this.id && !makeReciptSeleted) {
          // Editing existing record
          if (this.paymentDateInput) {
            this.paymentDateInput.nativeElement.focus();
          }
        }
        else if(this.id && !makeReciptSeleted){
          if (this.receiptNumberInput) {
            this.receiptNumberInput.nativeElement.focus();
          }
        } else {
          // Creating new record
          if (this.receiptNumberInput) {
            this.receiptNumberInput.nativeElement.focus();
          }
        }
      }, 500);
    });
  }
  
  onSubcription_Type_change(category) {
    console.log(category);
    if (category == undefined) {
      this.IsAllowSendReceipt = false;
      return;
    }
    if (category.allowSendReceipt) {
      this.IsAllowSendReceipt = true;
    } else if (category.length == 0 || category.allowSendReceipt == null || category.allowSendReceipt == false) {
      this.IsAllowSendReceipt = false;
    }
  }

  formatCurrency() {
    const paidControl = this.donation.get('paid');
    if (paidControl.value != null) {
      const formattedValue = Number.parseFloat(paidControl.value).toFixed(2);
      paidControl.setValue(formattedValue, { emitEvent: false }); // Update the value without emitting an event
    }
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  hasObjectChanged(original, modified) {
    // Check if both are null or undefined
    if (original == null && modified == null) {
      return false;
    }
  
    // Check if one is null or undefined and the other is not
    if (original == null || modified == null) {
      return true;
    }
    // Check if both are objects
    if (typeof original !== 'object' || typeof modified !== 'object') {
      return original !== modified; // Compare values directly if not objects
    }

    const keys = Object.keys(original);
    const modifiedKeys = Object.keys(modified);
  
    if (keys.length !== modifiedKeys.length) {
      return true;
    }

    // Check all keys in the original object
    for (let key of keys) {
      if (!this.hasObjectChanged(original[key], modified[key])) {
        // Recursively check nested properties
        continue;
      }
      return true; // Found a change
    }

    return false; // No changes found
  }

  public submitDialog(remove): void {
    if (this.donation.invalid) {
      this.donation.controls.payment_area.markAsTouched();
      this.donation.controls.payment_project.markAsTouched();
      this.donation.controls.payment_method.markAsTouched();
      this.donation.controls.paid.markAsTouched();
      console.log(this.donation);
      return;
    } else {
      if (remove) {
        this.dialogRef.close({ delete: true, id: this.id });
      } else {
        const donationData: Donation = this.donation.value;
        if (this.id) {
          donationData.id = this.id; // Set the ID if editing an existing membership
        }
        donationData.receipt_number = this.receiptNumber

        if (!this.hasObjectChanged(this.donation.value, this.donationCopy) && this.IsAllowSendReceipt && this.id) {
          this.IsAllowSendReceipt = false;
        }
        donationData.payment_area_id = this.donation.controls.payment_area.value;
        donationData.payment_project_id = this.donation.controls.payment_project.value;
        donationData.payment_method_id = this.donation.controls.payment_method.value
        donationData.payment_date = this.donation.controls.payment_date.value; // Ensure payment_date is included

        // Add allowSendReceipt to the result object
        const result = {
          ...donationData,
          allowSendReceipt: this.IsAllowSendReceipt
        };
        
        // Remove API call to prevent duplicate donations
        // The parent component (oldboy-dialog) will handle the API call
        this.dialogRef.close(result);
      }
    }
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  onPayment_Area_change(area) {
    console.log(area);
    if (area == undefined) {
      this.IsAllowSendReceipt = false;
      return;
    }
    if (area.allowSendReceipt) {
      this.IsAllowSendReceipt = true;
    } else if (area.length == 0 || area.allowSendReceipt == null || area.allowSendReceipt == false) {
      this.IsAllowSendReceipt = false;
    }
  }

  loadPaymentAreas() {
    this.oldboysService.getPaymentAreas().subscribe(areas => {
      this.paymentAreas = areas.filter((data: any) => data.deleted != true && data.active === true);
      let selectedarea = this.paymentAreas.filter((data: any) => data.id == this.donation.controls.payment_area.value)
      this.onPayment_Area_change(selectedarea[0]);
    });
  }

  onPayment_Project_change(project) {
    console.log(project);
    if (project == undefined) {
      this.IsAllowSendReceipt = false;
      return;
    }
    if (project.allowSendReceipt) {
      this.IsAllowSendReceipt = true;
    } else if (project.length == 0 || project.allowSendReceipt == null || project.allowSendReceipt == false) {
      this.IsAllowSendReceipt = false;
    }
  }

  loadPaymentProjects() {
    this.oldboysService.getPaymentProjects().subscribe(projects => {
      this.paymentProjects = projects.filter((data: any) => data.deleted != true  && data.active === true);
      let selectedproject = this.paymentProjects.filter((data: any) => data.id == this.donation.controls.payment_project.value)
      this.onPayment_Project_change(selectedproject[0]);
    });
  }

  loadPaymentMethods() {
    this.oldboysService.getPaymentsMethods().subscribe(methods => {
      this.paymentMethods = methods.filter((data: any) => data.deleted != true);
    });
  }

  getmaxid() {
    this.oldboysService.getmaxid_donations().subscribe((res: any) => {
      this.receiptNumber = res.id + 1;
      this.onClickEditReceiptNumber()
    });
  }

  onClickEditReceiptNumber() {
    this.EditReceiptNumber = !this.EditReceiptNumber;
    console.log(this.EditReceiptNumber)
    this.makePaymentDateFocus(true)
  }

  public deleteDonation(): void {
    // Clear the validation for the 'paid' field temporarily for delete action
    this.donation.get('paid').clearValidators();
    this.donation.get('paid').updateValueAndValidity(); // Update the validation status
    
    // Close the dialog with delete action and id
    this.dialogRef.close({ delete: true, id: this.id });
  }
}
