<h1 mat-dialog-title *ngIf="!id">Donation Payment</h1>
<h1 mat-dialog-title *ngIf="id">Edit Donation Payment</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="donation">

  <div *ngIf=" prospect_id || id" style="margin-bottom:20px">
    <label>Receipt number: </label>
    
    <span *ngIf="!EditReceiptNumber">{{ receiptNumber }} <button class="editReceiptNumberButton" (click)="onClickEditReceiptNumber()"  mat-icon-button color="accent"  type="button" matTooltip="Edit Receipt number "><mat-icon class="noteMatIcon">edit</mat-icon></button></span>
    <mat-form-field *ngIf="EditReceiptNumber" class="receiptNumberfield">
      <input matInput #receiptNumberInput  placeholder="" [(ngModel)]="receiptNumber" [ngModelOptions]="{standalone: true}" (keydown)="onKeydown($event)" >
    </mat-form-field>
    <!-- <ng-container **ngIf="!hideCross">
      <button *ngIf="EditReceiptNumber && !prospect_id " class="editReceiptNumberButton" (click)="onClickEditReceiptNumber()"  mat-icon-button color="accent"  type="button" ><mat-icon>highlight_off</mat-icon></button>

    </ng-container> -->
  </div>

  <mat-form-field>
    <input matInput #paymentDateInput [matDatepicker]="picker2" placeholder="Payment date" formControlName="payment_date" (keydown)="onKeydown($event)">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
    
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="payment_area" placeholder="Payment Area" >
      <mat-option *ngFor="let area of paymentAreas" [value]="area.id" (click)="onPayment_Area_change(area)">{{ area.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="payment_project" placeholder="Payment Project" >
      <mat-option *ngFor="let project of paymentProjects" [value]="project.id" (click)="onPayment_Project_change(project)">{{ project.name }}</mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field>
    <input type="number" matInput formControlName="paid" placeholder="Amount paid" (blur)="formatCurrency()">
    <mat-error *ngIf="donation.get('paid').invalid && donation.get('paid').touched">
      Amount is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-select formControlName="payment_method" placeholder="Payment Method">
      <mat-option *ngFor="let method of paymentMethods" [value]="method.id">{{ method.name }}</mat-option>
    </mat-select>
    <mat-error *ngIf="donation.get('payment_method').invalid && donation.get('payment_method').touched">
      Payment Method is required.
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <textarea matInput formControlName="comment" placeholder="Comment" (keydown)="onKeydown($event)" rows="4"></textarea>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="deleteDonation()" *ngIf="id" color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial  color="primary">Save</button>
</div>
