import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-submit-info-dialog',
  templateUrl: './submit-info-dialog.component.html',
  styleUrls: ['./submit-info-dialog.component.css']
})
export class SubmitInfoDialogComponent implements OnInit {

  oldBoyForm = new FormGroup({
    id: new FormControl(),
    firstname: new FormControl(),
    lastname: new FormControl(),
    firstyear: new FormControl(),
    lastyear: new FormControl(),
    housename: new FormControl(),
    address1: new FormControl(),
    address2: new FormControl(),
    suburb: new FormControl(),
    town_city: new FormControl(),
    province_state: new FormControl(),
    postalcode: new FormControl(),
    country: new FormControl(),
    hometelephone: new FormControl(),
    mobiletelephone: new FormControl(),
    faxnumber: new FormControl(),
    emailaddress: new FormControl(),
    employer: new FormControl(),
    profession_jobtitle: new FormControl(),
    organisationalnotes: new FormControl()
  });

  creating = true;

  constructor(public dialogRef: MatDialogRef<SubmitInfoDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.oldBoyForm.patchValue(data);
    if (data.id) {
      this.creating = false;
    }
  }

  ngOnInit() {
  }

  onKeydown(event) {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (): void {
    this.dialogRef.close(this.oldBoyForm.value);
  }

}
