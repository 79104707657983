import {Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ElementRef } from '@angular/core';
import {MatDialog, MatSnackBar, MatPaginator, MatDialogRef, MatTableDataSource, MAT_DIALOG_DATA, MatChipInputEvent} from '@angular/material';
import { MatSort } from '@angular/material';
import {FormGroup, FormControl, Validators, FormArray, FormBuilder} from '@angular/forms';
import {Oldboy, Note, Activity, OldboysService, Category, Membership, Keyword, Donation} from "../oldboys.service";
import {ActivityDialogComponent} from "../activity-dialog/activity-dialog.component";
import {CategoryDialogComponent} from "../category-dialog/category-dialog.component";
import {MembershipDialogComponent} from "../membership-dialog/membership-dialog.component";
import {UserService} from "../user.service";
import {KeywordDialogComponent} from "../keyword-dialog/keyword-dialog.component";
import { UnlinkDialogComponent } from '../unlink-dialog/unlink-dialog.component';
import {NoteDialogComponent} from '../note-dialog/note-dialog.component'
import { SendEmailDialogComponent } from '../send-email-dialog/send-email-dialog.component'
import { MessageBoxComponent } from '../message-box/message-box.component';
import { DonationDialogComponent } from '../donation-dialog/donation-dialog.component';

@Component({
  selector: 'app-oldboy-dialog',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './oldboy-dialog.component.html',
  styleUrls: ['./oldboy-dialog.component.css']
})
export class OldboyDialogComponent implements OnInit {

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild("Record_Type_Card") RecordTypeCardRef: ElementRef;
  @ViewChild(MatPaginator) notePaginator: MatPaginator;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  addOnBlur = true;
  deleteFlag: boolean = false; // Define deleteFlag here

  notesDataSource = new MatTableDataSource<Note>();
  notesDisplayedColumns = ['noteText',
  'updatedFull',
  'modify']

  activitiesDataSource = new MatTableDataSource<Activity>();
  activitiesDisplayedColumns = [ 'name',
    'years_involved',
    'position_role',
    'group_team',
    'sport',
    'notes'];

  cloading = false;
  categoriesToDelete = []
  categoriesDataSource = new MatTableDataSource<Category>();
  categoriesDisplayedColumns = [ 'name',
    'from',
    'to',
    'notes'];

  keywordsDataSource = new MatTableDataSource<Keyword>();
  keywordsDisplayedColumns = [ 'name'];

  membershipsDataSource = new MatTableDataSource<Membership>();
  membershipsDisplayedColumns = [ 
    'receipt_number',
    'payment_date',
    'subscription_category',
    'paid',
    'payment_method',
    'renewed_to',
    'comment'    
    ];

    donationsDataSource = new MatTableDataSource<Donation>();
    donationsDisplayedColumns = [ 
      'receipt_number',
      'payment_date',
      'payment_area',
      'payment_project',
      'paid',
      'payment_method',
      'comment'    
      ];

  subscriptionCategories: any[] = [];
  paymentAreas: any[] = [];
  paymentProjects: any[] = [];
  paymentMethods: any[] = [];

  creating = true;
  boarder = false;
  deceased = false;
  id: string = null;
  updatedById = null;
  updatedByUser = null;
  updated = null;
  updatedAt = null;
  disableSave: boolean = false;
  linkedAcc = false;
  isAdmin = false;

  keywords = this.fb.array([]);

  oldBoyForm = new FormGroup({
    gender: new FormControl('Male'),
    title: new FormControl('Mr'),
    initials: new FormControl(),
    firstname: new FormControl(),
    middlenames: new FormControl(),
    lastname: new FormControl(),
    salutation: new FormControl(),
    maidenname: new FormControl(),
    birthdate: new FormControl(),
    validated: new FormControl(false),
    oldboy: new FormControl(false),
    deceased: new FormControl(false),
    deceasedate: new FormControl(),
    volunteer1id: new FormControl(),
    volunteer2id: new FormControl(),
    address1: new FormControl(),
    address2: new FormControl(),
    suburb: new FormControl(),
    town_city: new FormControl(),
    province_state: new FormControl(),
    postalcode: new FormControl(),
    country: new FormControl(),
    hometelephone: new FormControl(),
    mobiletelephone: new FormControl(),
    emailaddress: new FormControl(),
    emailaddress2: new FormControl(),
    faxnumber: new FormControl(),
    employer: new FormControl(),
    profession_jobtitle: new FormControl(),
    workaddress1: new FormControl(),
    workaddress2: new FormControl(),
    worksuburb: new FormControl(),
    worktown_city: new FormControl(),
    workprovince_state: new FormControl(),
    workpostalcode: new FormControl(),
    workcountry: new FormControl(),
    worktelephone: new FormControl(),
    preferredcontactmethod: new FormControl('None'),
    secondcontactmethod: new FormControl('None'),
    qualificationlevel: new FormControl(),
    careernotes: new FormControl(),
    maritalstatus: new FormControl('N/A'),
    parent_partnertitle: new FormControl('Ms'),
    parent_partnerinitials: new FormControl(),
    parent_partnerfirstname: new FormControl(),
    parent_partnerlastname: new FormControl(),
    partnerinterests: new FormControl(),
    childrensnames: new FormControl(),
    firstyear: new FormControl(),
    lastyear: new FormControl(),
    firstclass: new FormControl('N/A'),
    lastclass: new FormControl('N/A'),
    housename: new FormControl(),
    border: new FormControl(),
    fromyear: new FormControl({ disabled: true}),
    toyear: new FormControl({ disabled: true}),
    organisationalnotes: new FormControl(),
    fundraisingnotes: new FormControl(),
    buddy: new FormControl(),
    updatedBy: new FormControl(),
    alumnimember: new FormControl(false),
    unsubscribed: new FormControl(),
    capacitycategory: new FormControl(),
    relationshipcategory: new FormControl()
  });

  constructor(public dialogRef: MatDialogRef<OldboyDialogComponent>,
              private fb: FormBuilder,
              public dialog: MatDialog,
              public snackBar: MatSnackBar,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private oldboysService: OldboysService,
              private usersService: UserService) {
    if (data.oldboy) {
      if (data.oldboy.capacitycategory){
        data.oldboy.capacitycategory = data.oldboy.capacitycategory.trim();
      }
      this.oldBoyForm.patchValue(data.oldboy);
      this.boarder = data.oldboy.border || false;
      this.deceased = data.oldboy.deceased || false;
      this.id = data.oldboy.id;
      this.creating = false;
      this.updatedById = data.oldboy.updatedBy;
      this.updated = data.oldboy.updated;
      this.updatedAt = data.oldboy.updatedAt;
      if (data.oldboy.keywords && data.oldboy.keywords.length) {
        data.oldboy.keywords.forEach(k => this.keywords.push(this.fb.control(k.trim())));
      }
    }

    if (data.disableSave) {
      this.disableSave = true;
    }
  }

  ngOnInit() {

    this.oldboysService.getSubscriptionCategories().subscribe(categories => {
      this.subscriptionCategories = categories;
    });

    this.oldboysService.getPaymentAreas().subscribe(areas => {
      this.paymentAreas = areas;
    });

    this.oldboysService.getPaymentProjects().subscribe(projects => {
      this.paymentProjects = projects;
    });
    
    this.oldboysService.getPaymentsMethods().subscribe(methods => {
      this.paymentMethods = methods;
    });
    if (this.creating === false && this.id) {
      //this.notesDataSource.paginator = this.notePaginator;
      this.getNotes()
      this.oldboysService.getOlboyActivities(this.id).subscribe(activities => {
        //console.log(activities);
        this.activitiesDataSource.data = activities;
      });
      this.oldboysService.getOlboyCategories(this.id).subscribe(categories => {
        //console.log(activities);
        this.categoriesDataSource.data = categories;
      });
      this.oldboysService.getOlboyMemberships(this.id).subscribe(memberships => {
        //console.log(activities);
        memberships.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

        this.membershipsDataSource.data = memberships;
      });
      this.oldboysService.getOlboyDonations(this.id).subscribe(donations => {
        //console.log(activities);
        donations.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

        this.donationsDataSource.data = donations;
      });
      this.oldboysService.getOldboyKeywords(this.id).subscribe(keywords => {
        this.keywordsDataSource.data = keywords;
      });
      if(this.usersService.isAdmin()){
        this.isAdmin = true;
      }
      this.oldboysService.isLinked(this.id).subscribe(response => {
        if (response){
          if(response.val){
            if(response.val === true){
              this.linkedAcc = true;
            }
          }
        }
      });
      if(this.updatedById) {
        this.usersService.getUserDetails(this.updatedById).subscribe(user => {
          console.log('user',user)
          if (user.prospect_name){
            this.updatedByUser = user.prospect_name
          } else {
            if (user.name){
              this.updatedByUser = user.name
            } else {
            this.updatedByUser = user.username
            }
          }
        });
      }
    }
  }

  public closeDialog (): void {
      this.dialogRef.close();
  }

  public submitDialog(remove: boolean): void {
    // Handle existing logic for capacitycategory and relationshipcategory
    if (this.oldBoyForm.value.capacitycategory === "DELETE") {
      this.oldBoyForm.value.capacitycategory = null;
    }
    if (this.oldBoyForm.value.relationshipcategory === "DELETE") {
      this.oldBoyForm.value.relationshipcategory = null;
    }
  
    // If delete action is triggered, set deleteFlag to true
    if (remove) {
      this.deleteFlag = true; // Set the delete flag to true when delete is triggered
    }

    // Prepare the result object for save/update/delete
    const result = {
          delete: remove,
          data: this.oldBoyForm.value,
          id: this.id,
          categoryData: this.categoriesDataSource.data,
          categoriesToDelete: this.categoriesToDelete
          };      
          result.data.border = result.data.boarder;
          result.data.keywords = this.keywords.value;
  
    // For delete action, bypass validation and close the dialog with delete action
    if (this.deleteFlag && remove) {
      this.dialogRef.close(result);
      return; // Exit here to skip the rest of the logic
    }
  
    // Proceed with validation for saving/updating
    // Mark the fields as touched to trigger validation errors if the form is invalid
    // Marko
    // Optimized solution for validation, can add other controls that need to be checked infuture for validation
    if (this.oldBoyForm && this.oldBoyForm.controls) {
      ['subscription_category', 'payment_method', 'paid'].forEach((key) => {
      const control = this.oldBoyForm.get(key);
      // if the control exists and is not touched, mark the control as touched to trigger validation
      if (control && !control.touched) {       
       control.markAsTouched();
      }
    });
    }

  
    // If the form is invalid, prevent save action
    if (this.oldBoyForm.invalid) {
      //console.log invalid controls - for debugging purposes so it easier to identify which controls are invalid
      console.log('Form is Invalid, Invalid controls:', this.getInvalidControls());
      return;
    }
  

    result.data.border = result.data.boarder;
    result.data.keywords = this.keywords.value;
  
    // Proceed with save/update if the form is valid
    if (this.categoriesDataSource.filteredData.length > 0 || result.delete) {
      this.dialogRef.close(result);
    } else {
      // Scroll to the Record Type Card if it's required and not filled
      this.RecordTypeCardRef.nativeElement.scrollIntoView({ behavior: "smooth", block: "center" });
      this.openSnackBar("This record must be assigned a Record Type before it can be saved.", 'Dismiss');
    }
  }
  
  
  
  public unlinkDialog() {
    const result = {data: this.oldBoyForm.value, id: this.id}
    result.data.border = result.data.boarder;
    result.data.keywords = this.keywords.value;
    const dialogRef = this.dialog.open(UnlinkDialogComponent, {
      width: '600px',
      data: { result }
    });
    dialogRef.afterClosed().subscribe(unlink => {
      if (unlink) {
        if (unlink === true){
          this.linkedAcc = false;
        }
      }
    });
  }

  onAddActivity() {
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
        if (result.id === null){
          delete result.id;
        }
        this.oldboysService.createOldboyActivity(this.id, result).subscribe(activity => {
          this.oldboysService.getOlboyActivities(this.id).subscribe(activities => {
            //console.log(activities);
            this.activitiesDataSource.data = activities;
          });
        });
      }
    });
  }

  onActivityRowClicked(event){
    const dialogRef = this.dialog.open(ActivityDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { activity: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          this.oldboysService.deleteOldboyActivity(result.id).subscribe(activity => {
            this.oldboysService.getOlboyActivities(this.id).subscribe(activities => {
              this.activitiesDataSource.data = activities;
            });
          });
        } else {
          this.oldboysService.updateOldboyActivity(result).subscribe(activity => {
            this.oldboysService.getOlboyActivities(this.id).subscribe(activities => {
              this.activitiesDataSource.data = activities;
            });
          });
        }
      }
    });
  }

  onAddCategory() {
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && !result.delete) {
        var categories = this.categoriesDataSource.data
        result.Category = {name: result.name}
        result.pseudoid = categories.length + 1
        categories.push(result)
        this.categoryTableRefresh(categories)
      }
    });
  }

  onCategoryRowClicked(event){
    const dialogRef = this.dialog.open(CategoryDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { category: event }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          if (result.id){
            var categories = this.categoriesDataSource.data
            var outputcategories = []
            for (var value in categories){
              if(categories[value].id !== result.id){
                outputcategories.push(categories[value])
              } else {
                this.categoriesToDelete.push(categories[value])
              }
            }
            this.categoryTableRefresh(outputcategories)
          } else {
            var categories = this.categoriesDataSource.data
            var outputcategories = []
            for (var value in categories){
              if(categories[value].pseudoid !== result.pseudoid){
                outputcategories.push(categories[value])
              }
            }
            this.categoryTableRefresh(outputcategories)
          }
        } else {
          if (result.id){
            var categories = this.categoriesDataSource.data
            for (var value in categories){
              if(categories[value].id === result.id){
                for (var key in result){
                  categories[value][key] = result[key]
                }
              }
            }
            this.categoryTableRefresh(categories)
          } else {
            var categories = this.categoriesDataSource.data
            for (var value in categories){
              if(categories[value].pseudoid === result.pseudoid){
                for (var key in result){
                  categories[value][key] = result[key]
                }
              }
            }
            this.categoryTableRefresh(categories)
          }
        }
      }
    });
  }

  categoryTableRefresh(categories) {
    console.log('refreshing category table')
    //console.log('categories',categories)
    const sort = this.sort;
    this.cloading = true;

    if(!categories[0]){
      this.categoriesDataSource.data = []
    } else {
      this.categoriesDataSource.data = categories;
    }
    this.categoriesDataSource.sort = sort;
    this.cloading = false;
  }

  onAddKeyword() {
    const dialogRef = this.dialog.open(KeywordDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.createOldboyKeyword(this.id, result).subscribe(keyword => {
          this.oldboysService.getOldboyKeywords(this.id).subscribe(ks => {
            this.keywordsDataSource.data = ks;
          });
        });
      }
    });
  }

  onKeywordRowClicked(event){
    const dialogRef = this.dialog.open(KeywordDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { keyword: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          this.oldboysService.deleteOldboyKeyword(result.id).subscribe(keyword => {
            this.oldboysService.getOldboyKeywords(this.id).subscribe(ks => {
              this.keywordsDataSource.data = ks;
            });
          });
        } else {
          this.oldboysService.updateOldboyKeyword(result).subscribe(keyword => {
            this.oldboysService.getOldboyKeywords(this.id).subscribe(ks => {
              this.keywordsDataSource.data = ks;
            });
          });
        }
      }
    });
  }

  oldNotesExist(){
    if (this.oldBoyForm.get('organisationalnotes').value){
      return true
    } else {
      return false
    }
  }

  onNoteRowClicked(row){
    console.log(row)
  }

  getCatName(element) {
    if (element && element.name) {
      return element.name
    } else if (element && element.Category && element.Category.name){
      return element.Category.name
    } else {
      return ''
    }
  }

  getCatFrom(element) {
    if (element && element.from){
      return element.from
    } else {
      return ''
    }
  }

  getCatTo(element) {
    if (element && element.to){
      return element.to
    } else {
      return ''
    }
  }

  getCatNotes(element) {
    if (element && element.notes){
      return element.notes
    } else {
      return ''
    }
  }

  getNotes(){
    //console.log('getNotes('+ this.id + ')')
    this.oldboysService.getOldboyNotes(this.id).subscribe(notes =>{
      notes.sort((a, b) => (a.updatedAt < b.updatedAt) ? 1 : ((b.updatedAt < a.updatedAt) ? -1 : 0));
      this.notesDataSource.data = notes
      //console.log(notes)
    })
  }

  addNote(){
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      //height: '400px',
      width: '600px',
      data: {noteText: ''}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('add note result:', result)
      if (result && result.noteText && result.noteText != '') {
        //console.log('addnote('+result.noteText+')')
        this.oldboysService.addOldboyNotes(this.id, result.noteText).subscribe(response => {
          //console.log(response)
          this.getNotes()
        })
      }
    })
  }

  updateNote(row){
    //console.log('updateNote{'+ this.id + ')')
    //console.log(row)
    const dialogRef = this.dialog.open(NoteDialogComponent, {
      //height: '400px',
      width: '600px',
      data: {noteText: row.noteText}
    });
    dialogRef.afterClosed().subscribe(result => {
      //console.log('add note result:', result)
      if (result && result.noteText && result.noteText != '') {
        //console.log('addnote('+result.noteText+')')
        this.oldboysService.updateOldboyNotes(this.id, result.noteText, row.id).subscribe(response => {
          //console.log(response)
          this.getNotes()
        })
      }
    })
  }

  deleteNote(row){
    //console.log('updateNote{'+ this.id + ')')
    this.oldboysService.deleteOldboyNotes(this.id, row.id).subscribe(response => {
      //console.log(response)
      this.getNotes()
    })
  }

  contactEmail(option){
    // console.log('contactEmail',option)
    // console.log(this.oldBoyForm.value.emailaddress)
    // console.log(this.oldBoyForm.value.emailaddress2)
    var email = null
    if (option === 1){
      email = this.oldBoyForm.value.emailaddress
    } else {
      email = this.oldBoyForm.value.emailaddress2
    }
    // console.log(email)
    if (email){
      console.log("Attempted to contact user at " + email + ', if no pop up occured your browser may have blocked it.')
      document.location.href = "mailto:"+email;
    } else {
      console.log("There is no email address to contact!")
    }
  }

  canContactEmail(option){
    //console.log('canContactEmail',option)
    if (this.oldBoyForm.value.emailaddress && option === 1){
      return true
    }
    if (this.oldBoyForm.value.emailaddress2 && option === 2){
      return true
    }
    return false
  }

  addKeyword(event: MatChipInputEvent): void {
    let input = event.input;
    let value = event.value;

    // Add our requirement
    if ((value || '').trim()) {
      this.keywords.push(this.fb.control(value.trim()));
      //console.log(keywords);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeKeyword(index: number): void {
    if (index >= 0) {
      this.keywords.removeAt(index);
    }
  }

  onAddMembership() {
    console.log(this);
    const dialogRef = this.dialog.open(MembershipDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { prospect_id: this.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let allowSendReceipt = result.allowSendReceipt;
        delete result.allowSendReceipt;
        this.oldboysService.updateOldboyMembership(this.id, result).subscribe(membership => {
          this.oldboysService.getOlboyMemberships(this.id).subscribe(memberships => {
            //console.log(activities);
            memberships.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());

            this.membershipsDataSource.data = memberships;
             if(allowSendReceipt){
              this.openSendEmailDialog(this.id, membership, this.oldBoyForm.value.emailaddress); // Open the send email dialog

             }
          });
        });
      }
    });
  }

  onAddDonation() {
    console.log(this);
    const dialogRef = this.dialog.open(DonationDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { prospect_id: this.id }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result); // Debug log
        const allowSendReceipt = result.allowSendReceipt;
        delete result.allowSendReceipt;
        this.oldboysService.updateOldboyDonation(this.id, result).subscribe(donation => {
          console.log('Donation saved:', donation); // Debug log
          if (allowSendReceipt) {
            this.openSendEmailDialog(this.id, donation, this.oldBoyForm.value.emailaddress, true); // Open the send email dialog for donation
          }
          this.oldboysService.getOlboyDonations(this.id).subscribe(donations => {
            donations.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());
            this.donationsDataSource.data = donations;
          });
        });
      }
    });
  }


  
  onMembershipRowClicked(event) {
    const dialogRef = this.dialog.open(MembershipDialogComponent, {
      width: '600px',
      data: { membership: event }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          // Show delete confirmation dialog before proceeding with deletion
          const confirmDialogRef = this.dialog.open(MessageBoxComponent, {
            width: '400px',
            data: {
              title: 'Confirm Deletion',
              text: 'Do you really want to delete this receipt?',
              answers: ['Ok', 'Cancel']
            }
          });
  
          confirmDialogRef.afterClosed().subscribe(confirmResult => {
            if (confirmResult === 'Ok') {
              this.oldboysService.deleteOldboyMembership(this.id, result.id).subscribe(membership => {
                this.oldboysService.getOlboyMemberships(this.id).subscribe(memberships => {
                  memberships.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());
                  this.membershipsDataSource.data = memberships;
                });
              });
            }
          });
        } else {
          // Handle update/save logic if not deleting
          let allowSendReceipt = result.allowSendReceipt;
          delete result.allowSendReceipt;
          this.oldboysService.updateOldboyMembership(this.id, result).subscribe(membership => {
            this.oldboysService.getOlboyMemberships(this.id).subscribe(memberships => {
              memberships.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());
              this.membershipsDataSource.data = memberships;
              if (allowSendReceipt) {
                this.openSendEmailDialog(this.id, membership, this.oldBoyForm.value.emailaddress); // Open the send email dialog
              }
            });
          });
        }
      }
    });
  }

  onDonationRowClicked(event) {
    const dialogRef = this.dialog.open(DonationDialogComponent, {
      width: '600px',
      data: { donation: event }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          // Show delete confirmation dialog before proceeding with deletion
          const confirmDialogRef = this.dialog.open(MessageBoxComponent, {
            width: '400px',
            data: {
              title: 'Confirm Deletion',
              text: 'Do you really want to delete this receipt?',
              answers: ['Ok', 'Cancel']
            }
          });
  
          confirmDialogRef.afterClosed().subscribe(confirmResult => {
            if (confirmResult === 'Ok') {
              this.oldboysService.deleteOldboyDonation(this.id, result.id).subscribe(donation => {
                this.oldboysService.getOlboyDonations(this.id).subscribe(donations => {
                  donations.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());
                  this.donationsDataSource.data = donations;
                });
              });
            }
          });
        } else {
          // Handle update/save logic if not deleting
          let allowSendReceipt = result.allowSendReceipt;
          delete result.allowSendReceipt;
          this.oldboysService.updateOldboyDonation(this.id, result).subscribe(donation => {
            this.oldboysService.getOlboyDonations(this.id).subscribe(donations => {
              donations.sort((a, b) => new Date(b.payment_date).getTime() - new Date(a.payment_date).getTime());
              this.donationsDataSource.data = donations;
              if (allowSendReceipt) {
                this.openSendEmailDialog(this.id, donation, this.oldBoyForm.value.emailaddress, true); // Open the send email dialog for donation
              }
            });
          });
        }
      }
    });
  }
  
  
    
    
  
  
   

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
    });
  }

  isContributorOrAdmin() {
    return this.usersService.isContributorOrAdmin();
  }

  openSendEmailDialog(prospect_id, data, email, isDonation = false): void {
    const dialogRef = this.dialog.open(SendEmailDialogComponent, {
      width: '400px',
      data: {
        prospect_id: prospect_id,
        email: email,
        type: isDonation ? 'donation' : 'membership',
        donation: isDonation ? data : null,
        membership: isDonation ? null : data
      }
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The SendEmailDialog was closed', result);
      // Handle the email sending result if needed
    });
  }

  getSubscriptionCategoryName(id) {
    let category = this.subscriptionCategories.find(cat => cat.id === id);
    return category ? category.name : '-';
  }

  getPaymentAreaName(id) {
    let area = this.paymentAreas.find(cat => cat.id === id);
    return area ? area.name : '-';
  }

  getPaymentProjectName(id) {
    let project = this.paymentProjects.find(cat => cat.id === id);
    return project ? project.name : '-';
  }
  
  getPaymentMethodName(id) {
    let method = this.paymentMethods.find(meth => meth.id === id);
    return method ? method.name : '-';
  }

  // Get invalid controls for debugging purposes when form is invalid.
  getInvalidControls() {
    const invalid = [];
    const controls = this.oldBoyForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
            invalid.push({ control: name, errors: controls[name].errors });
      }
    }
    return invalid;
  }
  
}
