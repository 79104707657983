import {Component, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormControl } from '@angular/forms'; // Add this line
import { OldboysService } from '../oldboys.service';

@Component({
  selector: 'app-send-email-dialog',
  templateUrl: './send-email-dialog.component.html',
  styleUrls: ['./send-email-dialog.component.css']
})
export class SendEmailDialogComponent {
  email = new FormControl('');
  showEmail = false;

  constructor(
    public dialogRef: MatDialogRef<SendEmailDialogComponent>,
    private oldboysService: OldboysService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.email.setValue(data.email);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  confirm(): void {
    this.showEmail = true;
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public sendEmail(): void {
    // Implement your send email logic here
    console.log('Sending email to:', this.email.value);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('Sending timezone from client:', timeZone);

    if (this.data.type === 'donation') {
      console.log('Sending donation email to:', this.data.donation);
      this.oldboysService.sendEmailOldboyDonation(
        this.data.prospect_id,
        this.data.donation,
        this.email.value,
        timeZone
      ).subscribe(response => {
        if (response.success === true) {
          console.log('Email Sent!');
          this.dialogRef.close({ sent: true, email: this.email.value });
        } else {
          console.log(response);
        }
      });
    } else {
      // Default to membership email
      console.log('Sending membership email to:', this.data.membership);
      this.oldboysService.sendEmailOldboyMembership(
        this.data.prospect_id,
        this.data.membership,
        this.email.value,
        timeZone
      ).subscribe(response => {
        if (response.success === true) {
          console.log('Email Sent!');
          this.dialogRef.close({ sent: true, email: this.email.value });
        } else {
          console.log(response);
        }
      });
    }
  }
}
