import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var SidebarService = /** @class */ (function () {
    function SidebarService() {
        this.sidebarOpenStateSource = new Subject();
        this.sidebarOpenState = this.sidebarOpenStateSource.asObservable();
    }
    SidebarService.prototype.setSidebarOpenState = function (open) {
        this.sidebarOpenStateSource.next(open);
    };
    SidebarService.ngInjectableDef = i0.defineInjectable({ factory: function SidebarService_Factory() { return new SidebarService(); }, token: SidebarService, providedIn: "root" });
    return SidebarService;
}());
export { SidebarService };
