<div id="oldboy-dialog-container">
  <mat-toolbar class="mat-dialog-title-main" [color]="['primary']" mat-dialog-title fxLayout="row">
    <div fxFlex="95" fxLayout="row" fxLayoutAlign="space-between">
      <div class="logo">
        <img src="assets/wbhs-logo.svg">
      </div>
      <div mat-dialog-actions class="mat-dialog-actions-2" fxLayout="row" fxLayoutAlign="start center">
        <button mat-raised-button class="button2" (click)="closeDialog()"  color="white">Cancel</button>
        <!-- <button mat-raised-button class="button2" (click)="submitDialog(true)" [disabled]="false" color="warn" *ngIf="id">Delete</button> -->
        <button mat-raised-button class="button2" (click)="submitDialog(false)" [disabled]="false" type="submit" color="white">Save</button>
      </div>
    </div>
  </mat-toolbar>
  <div class="oldboy-title-divider"></div>
  <div mat-dialog-content class="oldboy-dialog-content" fxLayout="column">
    <div fxLayout="column">

        <mat-card>
          <mat-card-title>
            Account Details
          </mat-card-title>
          <mat-card-content>
            <div class="flex-wrapper">
              <div class="leftcolumn">
                <!-- account details -->
                <form [formGroup]="idForm">
                  <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                    <div class="formheader">
                      <h3 class="mat-h3 formlabel" >Linked Record</h3>
                    </div>

                    <mat-form-field   class="namefield">
                      <input matInput disable="true" placeholder="Record Name" formControlName="prospectName" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="namefield">
                      <input matInput disable="true" placeholder="Years Attended" formControlName="yearsAttended" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <div fxFlexFill></div>
                  </div>
                </form>

                <form [formGroup]="userForm">
                  <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                    <div class="formheader">
                      <h3 class="mat-h3 formlabel" >Name</h3>
                    </div>

                    <mat-form-field   class="namefield">
                      <input matInput placeholder="Username" formControlName="username" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="emailfield">
                      <input matInput placeholder="Email" formControlName="email" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <div fxFlexFill></div>
                  </div>
                </form>
              </div>

            </div>
          </mat-card-content>
        </mat-card>

      <form [formGroup]="dataForm">
        
        <mat-card>
          <mat-card-title>
            User Data
          </mat-card-title>
          <mat-card-content>
            <div class="flex-wrapper">
              <div class="leftcolumn">
                <!-- name fields -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Name</h3>
                  </div>
                  <mat-form-field   class="namefield">
                    <input matInput placeholder="First Name" formControlName="firstName" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <mat-form-field   class="namefield">
                    <input matInput placeholder="Middle Name" formControlName="middleName" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <mat-form-field   class="namefield">
                    <input matInput placeholder="Last Name" formControlName="lastName" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Date of Birth</h3>
                  </div>
                  <mat-form-field   class="namefield">
                    <input matInput type="date" name="DateOfBirth" formControlName="dateofbirth">
                  </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <!-- year range -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Years Attended</h3>
                  </div>
                  <mat-form-field   class="namefield">
                    <input matInput placeholder="Year From" formControlName="fromYear" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <mat-form-field   class="namefield">
                    <input matInput placeholder="Year To" formControlName="toYear" (keydown)="onKeydown($event)" cdkFocusInitial>
                  </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <!-- address fields -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">

                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Address</h3>
                  </div>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Street Address" formControlName="streetAddress" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Suburb" formControlName="suburb" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Town / City" formControlName="town" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <!-- address fields 2 -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">
                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Address</h3>
                  </div>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Province / State" formControlName="province" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Postal Code / ZIP" formControlName="postCode" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Country" formControlName="country" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <!-- Phone Numbers -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">
                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >Phone Numbers</h3>
                  </div>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Home" formControlName="homePhone" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Mobile" formControlName="mobilePhone" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="Work" formControlName="workPhone" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                  <div fxFlexFill></div>
                </div>

                <!-- house -->
                <div fxLayout="row" fxLayoutGap="5px" class="formrow first-formrow">
                  <div class="formheader">
                    <h3 class="mat-h3 formlabel" >House / Whanau</h3>
                  </div>
                    <mat-form-field   class="addressfield">
                      <input matInput placeholder="House / Whanau" formControlName="house" (keydown)="onKeydown($event)" cdkFocusInitial>
                    </mat-form-field>
                  <div fxFlexFill></div>
                </div>

              </div>

            </div>
          </mat-card-content>
        </mat-card>
      </form>
    </div>


  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="start center">
    <button mat-raised-button (click)="closeDialog()"  color="primary">Cancel</button>
    <!-- <button mat-raised-button (click)="submitDialog(true)" [disabled]="false" color="warn" *ngIf="id">Delete</button> -->
    <button mat-raised-button (click)="submitDialog(false)" [disabled]="false" type="submit" color="primary" >Save</button>
  </div>
</div>
