import * as tslib_1 from "tslib";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Service } from './service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var OldboysService = /** @class */ (function (_super) {
    tslib_1.__extends(OldboysService, _super);
    function OldboysService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('oldboys');
        return _this;
    }
    OldboysService.prototype.getOlboys = function (search, searchFirstYear, sortby, sortdirection, filter) {
        var params = new HttpParams();
        if (search && search.length > 0) {
            params = params.set('search', search);
        }
        console.log(filter);
        if (filter) {
            var use_1 = {};
            var values_1 = {};
            Object.keys(filter).forEach(function (keyword) {
                if (keyword.startsWith('use')) {
                    if (filter[keyword] === true) {
                        use_1[keyword.slice(3).toLowerCase()] = true;
                    }
                }
                else {
                    values_1[keyword] = filter[keyword];
                }
            });
            Object.keys(values_1).forEach(function (keyword) {
                if (use_1[keyword.toLowerCase()] === true) {
                    if (keyword.toLowerCase() === 'validated' ||
                        keyword.toLowerCase() === 'wbhsoldboy' ||
                        keyword.toLowerCase() === 'deceased') {
                        var val = filter[keyword];
                        if (val === null) {
                            params = params.set(keyword.toLowerCase(), 'false');
                        }
                        else {
                            params = params.set(keyword.toLowerCase(), filter[keyword]);
                        }
                    }
                    else if (keyword.toLowerCase() === 'email') {
                        if (filter['anyEmail']) {
                            params = params.set('anyEmail', 'true');
                        }
                        else {
                            params = params.set(keyword.toLowerCase(), filter[keyword]);
                        }
                    }
                    else {
                        params = params.set(keyword.toLowerCase(), filter[keyword]);
                    }
                }
            });
        }
        if (searchFirstYear && searchFirstYear > 0) {
            params = params.set('firstyear', searchFirstYear);
        }
        if (sortby) {
            params = params.set('sortby', sortby);
            if (sortdirection) {
                params = params.set('sortdirection', sortdirection);
            }
        }
        return this.http.get(this.endpoint, { params: params }).pipe(catchError(this.handleError('oldboys', [])));
    };
    OldboysService.prototype.getOldBoy = function (id) {
        return this.http.get(this.endpoint + '/' + id);
    };
    OldboysService.prototype.getOldboysCount = function (search, searchFirstYear, filter) {
        var params = new HttpParams();
        var useParams = false;
        if (search && search.length > 0) {
            params = params.set('search', search);
            useParams = true;
        }
        if (searchFirstYear && searchFirstYear > 0) {
            params = params.set('firstyear', searchFirstYear);
            useParams = true;
        }
        if (filter) {
            var use_2 = {};
            var values_2 = {};
            Object.keys(filter).forEach(function (keyword) {
                if (keyword.startsWith('use')) {
                    if (filter[keyword] === true) {
                        use_2[keyword.slice(3).toLowerCase()] = true;
                    }
                }
                else {
                    values_2[keyword] = filter[keyword];
                }
            });
            Object.keys(values_2).forEach(function (keyword) {
                if (use_2[keyword.toLowerCase()] === true) {
                    if (keyword.toLowerCase() === 'validated' ||
                        keyword.toLowerCase() === 'wbhsoldboy' ||
                        keyword.toLowerCase() === 'deceased') {
                        var val = filter[keyword];
                        if (val === null) {
                            params = params.set(keyword.toLowerCase(), 'false');
                        }
                        else {
                            params = params.set(keyword.toLowerCase(), filter[keyword]);
                        }
                    }
                    else if (keyword.toLowerCase() === 'email') {
                        if (filter['anyEmail']) {
                            params = params.set('anyEmail', 'true');
                        }
                        else {
                            params = params.set(keyword.toLowerCase(), filter[keyword]);
                        }
                    }
                    else {
                        params = params.set(keyword.toLowerCase(), filter[keyword]);
                    }
                    useParams = true;
                }
            });
        }
        if (useParams) {
            return this.http.get(this.endpoint + '/count', { params: params }).pipe(map(function (t) { return t['count']; }), catchError(this.handleError('count_oldboys', [])));
        }
        else {
            return this.http.get(this.endpoint + '/count').pipe(map(function (t) { return t['count']; }), catchError(this.handleError('count_oldboys', [])));
        }
    };
    OldboysService.prototype.createOldboy = function (params) {
        return this.http.post(this.endpoint, params).pipe(catchError(this.handleError('create_oldboy', [])));
    };
    OldboysService.prototype.updateOldboy = function (id, params) {
        console.log(params);
        return this.http.put(this.endpoint + '/' + id, params).pipe(catchError(this.handleError('update_oldboy', [])));
    };
    // unlinking
    OldboysService.prototype.unlinkOldboy = function (id, params) {
        return this.http.put(this.endpoint + '/' + id + '/prospect', params).pipe(catchError(this.handleError('unlink_oldboy', [])));
    };
    OldboysService.prototype.isLinked = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/linked-status').pipe(catchError(this.handleError('isLinked', [])));
    };
    //notes
    OldboysService.prototype.getOldboyNotes = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/notes').pipe(catchError(this.handleError('oldboy_notes', [])));
    };
    OldboysService.prototype.addOldboyNotes = function (id, noteText) {
        return this.http.post(this.endpoint + '/' + id + '/notes', { noteText: noteText }).pipe(catchError(this.handleError('oldboy_notes', [])));
    };
    OldboysService.prototype.updateOldboyNotes = function (id, noteText, noteId) {
        return this.http.put(this.endpoint + '/' + id + '/notes/' + noteId, { noteText: noteText }).pipe(catchError(this.handleError('oldboy_notes', [])));
    };
    OldboysService.prototype.deleteOldboyNotes = function (id, noteId) {
        return this.http.delete(this.endpoint + '/' + id + '/notes/' + noteId).pipe(catchError(this.handleError('oldboy_notes', [])));
    };
    // Activities
    OldboysService.prototype.getOlboyActivities = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/activities').pipe(catchError(this.handleError('oldboy_activities', [])));
    };
    OldboysService.prototype.createOldboyActivity = function (id, activity) {
        return this.http.post(this.endpoint + '/' + id + '/activities', activity).pipe(catchError(this.handleError('update_activity', [])));
    };
    OldboysService.prototype.updateOldboyActivity = function (activity) {
        return this.http.put(this.endpoint + '/oldboyactivities', activity).pipe(catchError(this.handleError('update_activity', [])));
    };
    OldboysService.prototype.deleteOldboyActivity = function (id) {
        return this.http.delete(this.endpoint + '/oldboyactivities/' + id).pipe(catchError(this.handleError('delete_activity', [])));
    };
    OldboysService.prototype.getActivities = function () {
        return this.http.get(this.endpoint + '/activities').pipe(catchError(this.handleError('activities', [])));
    };
    OldboysService.prototype.addActivity = function (params) {
        return this.http.post(this.endpoint + '/activities', params).pipe(catchError(this.handleError('activities', [])));
    };
    OldboysService.prototype.updateActivity = function (activity) {
        return this.http.put(this.endpoint + '/activities', activity).pipe(catchError(this.handleError('update_activity', [])));
    };
    OldboysService.prototype.deleteActivity = function (id) {
        return this.http.delete(this.endpoint + '/activities/' + id).pipe(catchError(this.handleError('delete_activity', [])));
    };
    // Categories
    OldboysService.prototype.getOlboyCategories = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/categories').pipe(catchError(this.handleError('oldboy_categories', [])));
    };
    OldboysService.prototype.createOldboyCategory = function (id, category) {
        return this.http.post(this.endpoint + '/' + id + '/categories', category).pipe(catchError(this.handleError('create_category', [])));
    };
    OldboysService.prototype.updateOldboyCategory = function (category) {
        return this.http.put(this.endpoint + '/oldboycategories', category).pipe(catchError(this.handleError('update_category', [])));
    };
    OldboysService.prototype.bulkUpdateOldboyCategory = function (id, categories, toDel) {
        return this.http.put(this.endpoint + '/' + id + '/categories/bulkupdate', { data: categories, toDel: toDel }).pipe(catchError(this.handleError('bulk_update_categories', [])));
    };
    OldboysService.prototype.deleteOldboyCategory = function (id) {
        return this.http.delete(this.endpoint + '/oldboycategories/' + id).pipe(catchError(this.handleError('delete_category', [])));
    };
    OldboysService.prototype.getCategories = function () {
        return this.http.get(this.endpoint + '/categories').pipe(catchError(this.handleError('categories', [])));
    };
    OldboysService.prototype.addCategory = function (params) {
        return this.http.post(this.endpoint + '/categories', params).pipe(catchError(this.handleError('categories', [])));
    };
    OldboysService.prototype.updateCategory = function (category) {
        return this.http.put(this.endpoint + '/categories', category).pipe(catchError(this.handleError('update_category', [])));
    };
    OldboysService.prototype.deleteCategory = function (id) {
        return this.http.delete(this.endpoint + '/categories/' + id).pipe(catchError(this.handleError('delete_category', [])));
    };
    OldboysService.prototype.deleteSubscriptionCategory = function (data) {
        return this.http.put(this.endpoint + '/subscriptioncategories', data).pipe(catchError(this.handleError('delete_SubscriptionCategory', [])));
    };
    OldboysService.prototype.getActiveSubscriptionCategory = function () {
        return this.http.get(this.endpoint + '/subscriptioncategories?ignoreDeleted=true').pipe(catchError(this.handleError('get_ActiveSubscriptionCategory', [])));
    };
    OldboysService.prototype.deletePaymentMethod = function (data) {
        return this.http.put(this.endpoint + '/paymentmethods', data).pipe(catchError(this.handleError('delete_PaymentMethod', [])));
    };
    OldboysService.prototype.getActivePaymentMethod = function () {
        return this.http.get(this.endpoint + '/paymentmethods?ignoreDeleted=true').pipe(catchError(this.handleError('get_ActivePaymentMethod', [])));
    };
    OldboysService.prototype.deletePaymentArea = function (data) {
        return this.http.put(this.endpoint + '/paymentareas', data).pipe(catchError(this.handleError('delete_PaymentArea', [])));
    };
    OldboysService.prototype.getActivePaymentArea = function () {
        return this.http.get(this.endpoint + '/paymentareas?ignoreDeleted=true').pipe(catchError(this.handleError('get_ActivePaymentArea', [])));
    };
    OldboysService.prototype.deletePaymentProject = function (data) {
        return this.http.put(this.endpoint + '/paymentprojects', data).pipe(catchError(this.handleError('delete_PaymentProject', [])));
    };
    OldboysService.prototype.getActivePaymentProject = function () {
        return this.http.get(this.endpoint + '/paymentprojects?ignoreDeleted=true').pipe(catchError(this.handleError('get_ActivePaymentProject', [])));
    };
    // Keywords
    OldboysService.prototype.getOldboyKeywords = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/keywords').pipe(catchError(this.handleError('oldboy_keywords', [])));
    };
    OldboysService.prototype.createOldboyKeyword = function (id, keyword) {
        return this.http.post(this.endpoint + '/' + id + '/keywords', keyword).pipe(catchError(this.handleError('create_keyword', [])));
    };
    OldboysService.prototype.updateOldboyKeyword = function (keyword) {
        return this.http.put(this.endpoint + '/oldboykeywords', keyword).pipe(catchError(this.handleError('update_keyword', [])));
    };
    OldboysService.prototype.deleteOldboyKeyword = function (id) {
        return this.http.delete(this.endpoint + '/oldboykeywords/' + id).pipe(catchError(this.handleError('delete_keyword', [])));
    };
    OldboysService.prototype.getKeywords = function () {
        return this.http.get(this.endpoint + '/keywords').pipe(catchError(this.handleError('keywords', [])));
    };
    OldboysService.prototype.addKeyword = function (params) {
        return this.http.post(this.endpoint + '/keywords', params).pipe(catchError(this.handleError('keywords', [])));
    };
    OldboysService.prototype.updateKeyword = function (keyword) {
        return this.http.put(this.endpoint + '/keywords', keyword).pipe(catchError(this.handleError('update_keyword', [])));
    };
    OldboysService.prototype.deleteKeyword = function (id) {
        return this.http.delete(this.endpoint + '/keywords/' + id).pipe(catchError(this.handleError('delete_keyword', [])));
    };
    OldboysService.prototype.getSubscriptionCategories = function () {
        return this.http.get(this.endpoint + '/subscriptioncategories').pipe(catchError(this.handleError('subscriptioncategories', [])));
    };
    OldboysService.prototype.addSubscriptionCategory = function (params) {
        return this.http.post(this.endpoint + '/subscriptioncategories', params).pipe(catchError(this.handleError('subscriptioncategories', [])));
    };
    OldboysService.prototype.updateSubscriptionCategory = function (sc) {
        return this.http.put(this.endpoint + '/subscriptioncategories', sc).pipe(catchError(this.handleError('update_subscriptioncategory', [])));
    };
    OldboysService.prototype.getmaxid = function () {
        return this.http.get(this.endpoint + '/memberships/getmaxid').pipe(catchError(this.handleError('paymentmethods', [])));
    };
    OldboysService.prototype.getmaxid_donations = function () {
        return this.http.get(this.endpoint + '/donations/getmaxid').pipe(catchError(this.handleError('getmaxid_donations', [])));
    };
    OldboysService.prototype.getPaymentsMethods = function () {
        return this.http.get(this.endpoint + '/paymentmethods').pipe(catchError(this.handleError('paymentmethods', [])));
    };
    OldboysService.prototype.addPaymentMethod = function (params) {
        return this.http.post(this.endpoint + '/paymentmethods', params).pipe(catchError(this.handleError('paymentmethods', [])));
    };
    OldboysService.prototype.updatePaymentMethod = function (pm) {
        return this.http.put(this.endpoint + '/paymentmethods', pm).pipe(catchError(this.handleError('update_paymentmethod', [])));
    };
    OldboysService.prototype.getMembershipCategories = function () {
        return this.http.get(this.endpoint + '/membershipcategories').pipe(catchError(this.handleError('membershipcategories', [])));
    };
    OldboysService.prototype.getOlboyMemberships = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/memberships').pipe(catchError(this.handleError('oldboy_memberships', [])));
    };
    OldboysService.prototype.updateOldboyMembership = function (id, membership) {
        if (membership.id) {
            return this.http.put(this.endpoint + '/' + id + '/memberships/' + membership.id, membership).pipe(catchError(this.handleError('update_membership', [])));
        }
        else {
            return this.http.post(this.endpoint + '/' + id + '/memberships', membership).pipe(catchError(this.handleError('update_membership', [])));
        }
    };
    OldboysService.prototype.deleteOldboyMembership = function (id, membershipId) {
        return this.http.delete(this.endpoint + '/' + id + '/memberships/' + membershipId).pipe(catchError(this.handleError('delete_membership', [])));
    };
    OldboysService.prototype.getPaymentAreas = function () {
        return this.http.get(this.endpoint + '/paymentareas').pipe(catchError(this.handleError('paymentareas', [])));
    };
    OldboysService.prototype.addPaymentArea = function (params) {
        return this.http.post(this.endpoint + '/paymentareas', params).pipe(catchError(this.handleError('paymentareas', [])));
    };
    OldboysService.prototype.updatePaymentArea = function (sc) {
        return this.http.put(this.endpoint + '/paymentareas', sc).pipe(catchError(this.handleError('update_paymentarea', [])));
    };
    OldboysService.prototype.getPaymentProjects = function () {
        return this.http.get(this.endpoint + '/paymentprojects').pipe(catchError(this.handleError('paymentprojects', [])));
    };
    OldboysService.prototype.addPaymentProject = function (params) {
        return this.http.post(this.endpoint + '/paymentprojects', params).pipe(catchError(this.handleError('paymentprojects', [])));
    };
    OldboysService.prototype.updatePaymentProject = function (sc) {
        return this.http.put(this.endpoint + '/paymentprojects', sc).pipe(catchError(this.handleError('update_paymentproject', [])));
    };
    OldboysService.prototype.getOlboyDonations = function (id) {
        return this.http.get(this.endpoint + '/' + id + '/donations').pipe(catchError(this.handleError('oldboy_donations', [])));
    };
    OldboysService.prototype.updateOldboyDonation = function (id, donation) {
        if (donation.id) {
            return this.http.put(this.endpoint + '/' + id + '/donations/' + donation.id, donation).pipe(catchError(this.handleError('update_donation', [])));
        }
        else {
            return this.http.post(this.endpoint + '/' + id + '/donations', donation).pipe(catchError(this.handleError('update_donation', [])));
        }
    };
    OldboysService.prototype.deleteOldboyDonation = function (id, donationId) {
        return this.http.delete(this.endpoint + '/' + id + '/donations/' + donationId).pipe(catchError(this.handleError('delete_donation', [])));
    };
    OldboysService.prototype.deleteOldboy = function (id) {
        return this.http.delete(this.endpoint + '/' + id).pipe(catchError(this.handleError('delete_oldboy', [])));
    };
    OldboysService.prototype.sendEmailOldboyMembership = function (id, membership, emailAddress, timeZone) {
        return this.http.post(this.endpoint + '/' + id + '/membership-receipt-email/' + membership.id, { emailAddress: emailAddress, timeZone: timeZone }).pipe(catchError(this.handleError('sendEmailOldboyMembership', [])));
    };
    OldboysService.prototype.sendEmailOldboyDonation = function (id, donation, emailAddress, timeZone) {
        return this.http.post(this.endpoint + '/' + id + '/donation-receipt-email/' + donation.id, { emailAddress: emailAddress, timeZone: timeZone }).pipe(catchError(this.handleError('sendEmailOldboyDonation', [])));
    };
    OldboysService.ngInjectableDef = i0.defineInjectable({ factory: function OldboysService_Factory() { return new OldboysService(i0.inject(i1.HttpClient)); }, token: OldboysService, providedIn: "root" });
    return OldboysService;
}(Service));
export { OldboysService };
