import {Component, Inject, OnInit} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {OldboysService} from "../oldboys.service";
import {validate} from "codelyzer/walkerFactory/walkerFn";

function validateNotNone(c: FormControl) {
  return c.value !== 'None' ? null : {
    validateNotNone: {
      valid: false
    }
  };
}

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.css']
})
export class FilterDialogComponent implements OnInit {

  filter = new FormGroup({
    useFirstName: new FormControl(false),
    firstName: new FormControl(),
    useLastName: new FormControl(false),
    lastName: new FormControl(),
    useValidated: new FormControl(false),
    validated: new FormControl(false),
    useAlumnimember: new FormControl(false),
    alumnimember: new FormControl(false),
    useDeceased: new FormControl(false),
    deceased: new FormControl(false),
    townCity: new FormControl(),
    useTownCity: new FormControl(false),
    firstYearFrom: new FormControl(),
    useFirstYearFrom: new FormControl(false),
    firstYearTo: new FormControl(),
    useFirstYearTo: new FormControl(false),
    lastYearFrom: new FormControl(),
    useLastYearFrom: new FormControl(false),
    lastYearTo: new FormControl(),
    useLastYearTo: new FormControl(false),
    email: new FormControl(),
    anyEmail: new FormControl(false),
    useEmail: new FormControl(false),
    membershipCategory: new FormControl(),
    useMembershipCategory: new FormControl(false),
    renewedToDate: new FormControl(),
    useRenewedToDate: new FormControl(false),
    capacitycategory: new FormControl(),
    useCapacitycategory: new FormControl(false),
    relationshipcategory: new FormControl(),
    useRelationshipcategory: new FormControl(false),
    category: new FormControl(),
    useCategory: new FormControl(false),
    unsubscribed: new FormControl(false),
    useUnsubscribed: new FormControl(false),
    keyword: new FormControl(),
    useKeyword: new FormControl(false)});

  filterName = new FormControl('', [ Validators.minLength(3),
                                     validateNotNone,
                                     Validators.required ]);

  categories = [];
  keywords = [];
  membershipcategories = [];

  capacitycategories = [
    'A',
    'B',
    'C',
    'D'
  ];

  relationshipcategories = [
    'High',
    'Medium',
    'Low',
  ];

  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,
              private oldBoysService: OldboysService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    console.log(this.data);
    this.filter.patchValue(this.data['filter']);
    let name = this.data['filterName'];
    if (name === 'None') {
      name = '';
    }
    this.filterName.patchValue(name);
    this.oldBoysService.getCategories().subscribe(categories => {
      this.categories = categories.map(a => a.name).sort();
      this.oldBoysService.getKeywords().subscribe(keywords => {
        this.keywords = keywords.map(a => a.name).sort();
        this.oldBoysService.getMembershipCategories().subscribe(cats => {
          console.log(cats);
          this.membershipcategories = cats;
        });
      });
    });
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (del): void {

    const result = {  filter: this.filter.value,
                      filterName: this.filterName.value,
                      delete: del};

    //console.log(result);
    this.dialogRef.close(result);
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

}
