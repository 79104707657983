import { MatDialog } from '@angular/material';
import { ExportDialogComponent } from '../export-dialog/export-dialog.component';
import { SubscriptionsReportComponent } from '../subscriptions-report/subscriptions-report.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SubscriptionsService } from '../reports.service';
import { SubscriptionFilterComponent } from '../subscription-filter/subscription-filter.component';


import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { Component, OnInit, ViewChild } from '@angular/core';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
@Component({
  selector: 'app-payment-report',
  templateUrl: './payment-report.component.html',
  styleUrls: ['./payment-report.component.css']
})
export class PaymentReportComponent implements OnInit {

  loading = true;
  @ViewChild(SubscriptionsReportComponent) subscriptionsReportComponent: SubscriptionsReportComponent;

  constructor(
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private subscriptionsService: SubscriptionsService
  ) { }

  ngOnInit() {
    // Any initialization logic
  }

  onFilter(): void {
    const dialogRef = this.dialog.open(SubscriptionFilterComponent, {
      width: '250px'
    });
  
    dialogRef.afterClosed().subscribe(selectedFilters => {
      if (selectedFilters) {
        // Pass the filters to the SubscriptionsReportComponent
        this.subscriptionsReportComponent.applyFilters(selectedFilters);
      }
    });
  }
  onExport() {
    const dialogRef = this.dialog.open(ExportDialogComponent, {
      //width: '400px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.target === 'pdf') {
          html2canvas(this.subscriptionsReportComponent.getTableElement().nativeElement).then(canvas => {
            var imgWidth = 208;
            var pageHeight = 295;
            var imgHeight = canvas.height * imgWidth / canvas.width;
            var heightLeft = imgHeight;
            const contentDataURL = canvas.toDataURL('image/png')
            let pdf = new jspdf('l', 'mm', 'a4'); // A4 size page of PDF
            const pageWidth = pdf.internal.pageSize.getWidth();
            var position = 0;
            pdf.addImage(contentDataURL, 'PNG', (pageWidth-imgWidth)/2, position, imgWidth, imgHeight)
            pdf.save(result.filename + '.pdf'); // Generated PDF
          });
        } else {
          this.exportTableAsExcelFile(result.filename + '.xlsx');
        }
      }
    });
  }

  exportTableAsExcelFile(excelFileName: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.subscriptionsReportComponent.getTableElement().nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Subscriptions Data');
    XLSX.writeFile(wb, excelFileName);
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }




}
