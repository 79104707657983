<mat-card class="table-card">
  <mat-card-title>
    <div fxLayout="row">
      <div fxLayoutAlign="start center" fxFlex="7 0 auto" fxLayoutGap="10px">
        <mat-icon inline="true">poll</mat-icon>
        <div>Donations Report - {{currentDate}}</div>
      </div>
      <div fxLayoutAlign="end center" fxFlex="3 0 auto" fxLayoutGap="2px">
        <button mat-mini-fab matTooltip="Specify filter" color="accent"
          (click)="onFilter()"><mat-icon>filter_list</mat-icon></button>
        <button mat-mini-fab color="accent" (click)="onExport()"
          matTooltip="Export to CSV or PDF"><mat-icon>import_export</mat-icon></button>
      </div>
    </div>
  </mat-card-title>
  <mat-card-header>
    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="12px">
      <div fxLayoutAlign="center center" fxFlex="6 0 auto" fxLayoutGap="12px">
        <div class="searchBar">
          <div class="flex">
            <div>
              <mat-icon>search</mat-icon>
              <mat-form-field class="search-bar">
                <input (keydown)="onKeydown($event)" placeholder="Name" matInput [(ngModel)]="name" #searchInput>
              </mat-form-field>
            </div>
            <mat-form-field>
              <input matInput [matDatepicker]="picker1" placeholder="From Payment Date" [(ngModel)]="paymentFromDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput [matDatepicker]="picker2" placeholder="To Payment Date" [(ngModel)]="paymentToDate"
                (keydown)="onKeydown($event)">
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="lowerSearchBar">
            <div>
              <mat-form-field>
                <mat-select placeholder="Payment Area" [(ngModel)]="paymentArea">
                  <mat-option [value]="">All Payment Areas</mat-option>
                  <mat-option *ngFor="let area of paymentAreas" [value]="area.id">{{area.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <mat-select placeholder="Payment Project" [(ngModel)]="paymentProject">
                  <mat-option [value]="">All Payment Projects</mat-option>
                  <mat-option *ngFor="let project of paymentProjects" [value]="project.id">{{project.name}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput type="number" placeholder="Payment Greater than" [(ngModel)]="paymentGreaterThan">
              </mat-form-field>
            </div>
            <button mat-raised-button color="primary" (click)="onRefresh()">Search</button>
          </div>
        </div>
      </div>
      <div fxFlex="4 0 auto"></div>
    </div>
  </mat-card-header>
  <mat-card-content>
    <mat-spinner *ngIf="loading"></mat-spinner>
    <div class="mat-elevation-z8" [class.hidden]="loading">
      <table mat-table [dataSource]="dataSource" matSort #table>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('name')">Name</th>
          <td mat-cell *matCellDef="let element">{{element.Prospect.firstname + ' ' + element.Prospect.lastname}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Year Column -->
        <ng-container matColumnDef="year">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('year')">Year</th>
          <td mat-cell *matCellDef="let element">{{element.Prospect.firstyear}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Payment Date Column -->
        <ng-container matColumnDef="paymentDate">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('paymentDate')">Payment Date</th>
          <td mat-cell *matCellDef="let element">{{element.payment_date }}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Receipt Number Column -->
        <ng-container matColumnDef="receiptNumber">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('receiptNumber')">Receipt Number</th>
          <td mat-cell *matCellDef="let element" class="center-align">{{element.id}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Area Column -->
        <ng-container matColumnDef="area">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('area')">Area</th>
          <td mat-cell *matCellDef="let element" class="center-align">{{element.PaymentArea?.name}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Project Column -->
        <ng-container matColumnDef="project">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('project')">Project</th>
          <td mat-cell *matCellDef="let element" class="center-align">{{element.PaymentProject?.name}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <!-- Amount Column -->
        <ng-container matColumnDef="amount">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('amount')">Amount</th>
          <td mat-cell *matCellDef="let element">{{element.paid | currency:'NZD':'symbol-narrow':'1.2-2'}}</td>
          <td mat-footer-cell *matFooterCellDef>
            <strong>Total: {{getTotalAmount() | currency:'NZD':'symbol-narrow':'1.2-2'}}</strong>
          </td>
        </ng-container>

        <!-- Note Column -->
        <ng-container matColumnDef="note">
          <th class="header" mat-header-cell *matHeaderCellDef mat-sort-header (click)="setSorting('note')">Note</th>
          <td mat-cell *matCellDef="let element">{{element.comment}}</td>
          <td mat-footer-cell *matFooterCellDef></td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
