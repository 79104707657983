import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { catchError, tap  } from 'rxjs/operators';

import {Service} from './service';


@Injectable({
  providedIn: 'root'
})
export class PublicService extends Service {
  endpoint = this.getEndpoint('public');
  constructor(private http: HttpClient) {
    super();
  }

  public getYears(): Observable<[number]> {
    return this.http.get(this.endpoint + '/years').pipe(
      catchError(this.handleError('years', []))
    );
  }

  public getYearStudents(year): Observable<[Student]> {
    return this.http.get( this.endpoint + '/years/' + year ).pipe(
      catchError(this.handleError('year students', []))
    );
  }

}

export interface Student {
  id: string;
  firstname: string;
  middlenames: string;
  lastname: string;
  firstyear: string;
  lastyear: string;
  validated: boolean;
  deceased: boolean;
  wbhsOldBoy: boolean;
  emailOnFile: boolean;
}
