import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';
import { MatSnackBar } from '@angular/material';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import {Router} from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {

  public email: string;
  public password: string;
  submitted = false;
  loading = false;

  form = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required, Validators.minLength(6)]),
    passwordConfirmation: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    dateOfBirth: new FormControl('', [Validators.required]),
    streetAddress: new FormControl(''),
    suburb: new FormControl(''),
    town: new FormControl(''),
    province: new FormControl(''),
    postCode: new FormControl(''),
    country: new FormControl(''),
    homePhone: new FormControl(''),
    workPhone: new FormControl(''),
    mobilePhone: new FormControl(''),
    house: new FormControl(''),
    fromYear: new FormControl('', [Validators.required]),
    toYear: new FormControl('', [Validators.required])
  },
    this.confirmPassword);

  constructor(private auth: AuthService, public snackBar: MatSnackBar, private router: Router,) { }

  ngOnInit() {
  }

  public onRegister() {
    this.submitted = true;

    if (this.form.invalid) {
      let errmsg = this.errors.join('\n');
      this.openSnackBar(errmsg, 'Dismiss');
      return;
    }

    this.loading = true;
    this.auth.register(this.form.value)
      .pipe(finalize(() => this.loading = false))
      .subscribe(res => {
        if (res['success']) {
          this.openSnackBar(res['msg'], 'Dismiss');
          this.onClear();
          this.submitted = false;
          this.router.navigate(['/login']);
        } else {
          this.openSnackBar(res['error']['msg'], 'Dismiss');
        }
        console.log(res);
      });
  }

  public onClear() {
    this.form.reset();
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 100000,
      panelClass : 'snackbar-style'
    });
  }

  get firstNameError() {
    return this.form.controls.firstName.hasError('required')
      ? 'First Name is missing.'
      : '';
  }

  get lastNameError() {
    return this.form.controls.lastName.hasError('required')
      ? 'Last Name is missing.'
      : '';
  }

  get dateOfBirthError() {
    return this.form.controls.dateOfBirth.hasError('required')
      ? 'Date of Birth is missing.'
      : '';
  }

  get emailError() {
    return this.form.controls.email.hasError('email')
      ? 'Email is not valid.'
      : this.form.controls.email.hasError('required')
        ? 'Email is missing.'
        : '';
  }

  get passwordError() {
    return this.form.controls.password.hasError('minlength')
      ? 'Password is not at least 8 characters long.'
      : this.form.controls.password.hasError('required')
        ? 'Password is missing.'
        : '';
  }

  get confirmPasswordError() {
    return this.form.controls.passwordConfirmation.hasError('mismatch')
      ? 'Passwords do not match.'
      : this.form.controls.passwordConfirmation.hasError('required')
        ? 'Password confirmation is missing.'
        : '';
  }

  get fromYearError() {
    return this.form.controls.fromYear.hasError('required') ? 'From Year is missing' : '';
  }

  get toYearError() {
    return this.form.controls.toYear.hasError('required') ? 'To Year is missing' : '';
  }

  get errors() {
    const errors = [];

    if (this.emailError) {
      errors.push(this.emailError);
    }

    if (this.passwordError) {
      errors.push(this.passwordError);
    }

    if (this.confirmPasswordError) {
      errors.push(this.confirmPasswordError);
    }

    if (this.firstNameError) {
      errors.push(this.firstNameError);
    }

    if (this.lastNameError) {
      errors.push(this.lastNameError);
    }

    if (this.dateOfBirthError) {
      errors.push(this.dateOfBirthError);
    }

    if (this.fromYearError) {
      errors.push(this.fromYearError);
    }

    if (this.toYearError) {
      errors.push(this.toYearError);
    }

    return errors;
  }

  private confirmPassword(group: FormGroup) {
    const valid = group.controls.password.value === group.controls.passwordConfirmation.value;

    if (!valid) {
      group.controls.passwordConfirmation.setErrors({ mismatch: true });
    }

    return valid ? null : { mismatch: true };
  }
}
