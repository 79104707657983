import { UserService } from "./user.service";
import * as i0 from "@angular/core";
import * as i1 from "./user.service";
var AdminGuard = /** @class */ (function () {
    function AdminGuard(userService) {
        this.userService = userService;
    }
    AdminGuard.prototype.canActivate = function (next, state) {
        return this.userService.isAdmin();
    };
    AdminGuard.ngInjectableDef = i0.defineInjectable({ factory: function AdminGuard_Factory() { return new AdminGuard(i0.inject(i1.UserService)); }, token: AdminGuard, providedIn: "root" });
    return AdminGuard;
}());
export { AdminGuard };
