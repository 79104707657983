import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth.service';
import { UserService } from '../user.service';
import { SidebarService } from '../sidebar.service';
import { OldboysService } from '../oldboys.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { OldboyDialogComponent } from '../oldboy-dialog/oldboy-dialog.component';
import {SubmitInfoDialogComponent} from '../submit-info-dialog/submit-info-dialog.component';
import {MembershipRequestService} from '../membership-request.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {
  title = 'WBHS Alumni';
  subscription: Subscription;
  current: any;

  @ViewChild('sidenav') public navigation;
  @ViewChild('manageDetailsDialogRef') manageDetailsDialogRef: TemplateRef<any>;

  constructor(
    private auth: AuthService,
    private user: UserService,
    private sidebar: SidebarService,
    private oldBoyService: OldboysService,
    public snackBar: MatSnackBar,
    private membershipRequestService: MembershipRequestService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar) {
    this.subscription = sidebar.sidebarOpenState.subscribe(
      state => {
        if (state) {
          this.navigation.open();
        } else {
          this.navigation.close();
        }
      });
  }

  ngOnInit() {
    if (this.isSignedIn()) {
      this.navigation.open();
      this.current = this.user.getCurrentUser();
    } else {
      this.navigation.close();
    }
  }

  isSignedIn() {
    return this.auth.isSignedIn();
  }

  isContributor() {
    return this.user.isContributor();
  }

  isAdmin() {
    return this.user.isAdmin();
  }

  signOut() {
    this.auth.signOut();
  }

  get name(): string {
    return this.user.getUsername();
  }

  get firstLastName(): string {
    return this.user.getCurrentFirstLastName();
  }

  get initials(): string {
    const names = this.user.getCurrentFirstLastName().split(" ");
    var outstring = "";
    for (const element of names){
      outstring = outstring + element[0]
    }
    return outstring.toUpperCase()
  }

  onLoginSuccess() {
    console.log('app on login success');
    if (this.isSignedIn()) {
      this.navigation.open();
    } else {
      this.navigation.close();
    }
  }

  manageDetails() {
    this.dialog.open(this.manageDetailsDialogRef).afterClosed()
      .subscribe(result => {
        if (result) {
          const currUser = this.user.getCurrentUser();
          const dialogOldBoy = this.dialog.open(SubmitInfoDialogComponent, {
            maxHeight: '100vh',
            height: '100%',
            data: {
              id: currUser.prospectId,
              emailaddress: currUser.email,
              firstname : currUser.data.firstName,
              lastname : currUser.data.lastName,
              birthdate : currUser.data.dateOfBirth,
              firstyear : currUser.data.fromYear,
              lastyear : currUser.data.toYear,
              country: currUser.data.country,
              town_city: currUser.data.town,
              housename: currUser.data.house,
              suburb: currUser.data.suburb,
              postalcode: currUser.data.postcode,
              province_state: currUser.data.province,
              hometelephone: currUser.data.homePhone,
              worktelephone: currUser.data.workPhone,
              mobiletelephone: currUser.data.mobilePhone,
              address1: currUser.data.streetAddress,
            }
          });

          dialogOldBoy.afterClosed().subscribe(result1 => {
            if (result1) {
              this.membershipRequestService.createInformationSubmissionRequest(result1)
                .subscribe((res) => {
                  this.snackBar.open(res.msg, 'Dismiss', {
                    duration: 100000,
                  });
                });
            }
          });
        }
      });
  }

  get requestsLinkTitle(): string {
    if (this.isAdmin() || this.isContributor()) {
      return "Requests";
    } else {
      return "My Requests";
    }
  }
}
