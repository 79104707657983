import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Service } from './service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var PublicService = /** @class */ (function (_super) {
    tslib_1.__extends(PublicService, _super);
    function PublicService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('public');
        return _this;
    }
    PublicService.prototype.getYears = function () {
        return this.http.get(this.endpoint + '/years').pipe(catchError(this.handleError('years', [])));
    };
    PublicService.prototype.getYearStudents = function (year) {
        return this.http.get(this.endpoint + '/years/' + year).pipe(catchError(this.handleError('year students', [])));
    };
    PublicService.ngInjectableDef = i0.defineInjectable({ factory: function PublicService_Factory() { return new PublicService(i0.inject(i1.HttpClient)); }, token: PublicService, providedIn: "root" });
    return PublicService;
}(Service));
export { PublicService };
