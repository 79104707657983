<mat-toolbar color="primary">
  <span>
    <button mat-icon-button (click)="sidenav.toggle()">
      <img src="assets/wbhs-logo.svg">
    </button>
  </span>
  <span *ngIf="isSignedIn()"><button mat-flat-button color="primary" [routerLink]="['home']"
      class="toolbar-button">Whangarei Boys High School Old Boys' Association</button></span>
  <span *ngIf="!isSignedIn()"><button mat-flat-button color="primary" [routerLink]="['years']"
      class="toolbar-button">Whangarei Boys High School Old Boys' Association</button></span>

  <!-- This fills the remaining space of the current row -->
  <span class="example-fill-remaining-space"></span>

  <span>
    <div *ngIf="isSignedIn()" class="truncate" >
      <button *ngIf="isSignedIn()" mat-mini-fab color="accent" [matMenuTriggerFor]="userMenu">{{initials}}</button>
      <div class="nameSpan">{{firstLastName}}</div>
      <mat-menu #userMenu="matMenu">
        <div fxFlex="5" fxLayout="column" fxLayoutAlign="flex-start">
          <div class="smallerdiv">Username:</div>
          <div class="smalldiv" inline="true"><b>{{name}}</b></div>
        </div>
        <mat-divider></mat-divider>
        <button mat-menu-item [routerLink]="['/account']">
          <mat-icon>account_circle</mat-icon> Account
        </button>
        <button mat-menu-item (click)="signOut()">
          <mat-icon>exit_to_app</mat-icon>Sign out
        </button>
      </mat-menu>
      <button *ngIf="!isSignedIn()" mat-flat-button color="primary" [routerLink]="['/login']">Login</button>
    </div>
  </span>
</mat-toolbar>

<mat-sidenav-container class="main-container">
  <mat-sidenav #sidenav mode="side">
    <button *ngIf="isSignedIn() && isContributor()" mat-menu-item [routerLink]="['/oldboys']"
            [routerLinkActive]="['active']">
      <mat-icon>person</mat-icon> Old Boys
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn()" mat-menu-item [routerLink]="['/requests']"
            [routerLinkActive]="['active']">
      <mat-icon>card_membership</mat-icon> Requests
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button mat-menu-item [routerLink]="['/years']" [routerLinkActive]="['active']" *ngIf="isSignedIn()">
      <mat-icon>event</mat-icon> Year lists
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn() && isAdmin()" mat-menu-item [routerLink]="['/access']" [routerLinkActive]="['active']">
      <mat-icon>lock</mat-icon> Access management
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn() && isAdmin()" mat-menu-item [routerLink]="['/maintenance']" [routerLinkActive]="['active']">
      <mat-icon>settings_suggest</mat-icon> Maintenance
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn() && isAdmin()" mat-menu-item [routerLink]="['/reports']" [routerLinkActive]="['active']">
      <mat-icon>poll</mat-icon> Reports
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <a href="http://wbhsoba.co.nz/membership/" style="text-decoration: none;" target="_blank">
      <button mat-menu-item [routerLinkActive]="['active']" *ngIf="isSignedIn()">
        <mat-icon>link</mat-icon> OBA Memberships page
      </button>
    </a>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn()" mat-menu-item [routerLink]="['/account']" [routerLinkActive]="['active']">
      <mat-icon>account_circle</mat-icon> Account settings
    </button>
    <mat-divider *ngIf="isSignedIn()"></mat-divider>
    <button *ngIf="isSignedIn()" mat-menu-item (click)="signOut()">
      <mat-icon>exit_to_app</mat-icon>Logout
    </button>
  </mat-sidenav>

  <mat-sidenav-content>
    <main>
      <router-outlet>
      </router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template let-data #manageDetailsDialogRef>
  <h1 mat-dialog-title>Update Your Details</h1>
  <div mat-dialog-content>
    This will submit a request to update your information to the Administrator. Do you wish to proceed?
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">Yes</button>
    <button mat-button [mat-dialog-close]="false">No</button>
  </div>
</ng-template>
