import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private sidebarOpenStateSource = new Subject<boolean>();
  constructor() { }

  sidebarOpenState = this.sidebarOpenStateSource.asObservable();

  setSidebarOpenState(open) {
    this.sidebarOpenStateSource.next(open);
  }
}
