<h1 mat-dialog-title>Define Filter</h1>
<mat-form-field class="line">
  <input type="text" matInput [formControl]="filterName" placeholder="Filter Name">
  <mat-error *ngIf="filterName.hasError('minLength') && !filterName.hasError('required')">
    Please enter a valid filter name (longer than 3 letters)
  </mat-error>
  <mat-error *ngIf="filterName.hasError('validateNotNone')">
    Filter <strong>None</strong> cannot be modified
  </mat-error>
</mat-form-field>
<div mat-dialog-content fxLayout="column"  [formGroup]="filter">
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-form-field class="line">
      <input type="text" matInput formControlName="firstName" placeholder="First Name">
    </mat-form-field>
    <mat-slide-toggle formControlName="useFirstName"></mat-slide-toggle>
    <mat-form-field  class="line">
      <input type="text" matInput formControlName="lastName" placeholder="Last Name">
    </mat-form-field>
    <mat-slide-toggle formControlName="useLastName"></mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <input type="text" matInput formControlName="townCity" placeholder="Town/City">
    </mat-form-field>
    <mat-slide-toggle formControlName="useTownCity"></mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <input type="text" matInput formControlName="email" placeholder="Email">
    </mat-form-field>
    <div>
      <mat-checkbox  formControlName="anyEmail">Any non-empty</mat-checkbox>
    </div>
    <mat-slide-toggle formControlName="useEmail"></mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-checkbox  class="line" formControlName="validated">Validated</mat-checkbox>
    <mat-slide-toggle formControlName="useValidated"></mat-slide-toggle>
    <mat-checkbox   class="line" formControlName="deceased">Deceased</mat-checkbox>
    <mat-slide-toggle formControlName="useDeceased"></mat-slide-toggle>
  </div>
  <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="12px">
    <mat-checkbox  class="line" formControlName="alumnimember">OBA Member</mat-checkbox>
    <mat-slide-toggle formControlName="useAlumnimember"></mat-slide-toggle>
    <mat-checkbox  class="line" formControlName="unsubscribed">Unsubscribed</mat-checkbox>
    <mat-slide-toggle formControlName="useUnsubscribed"></mat-slide-toggle>
  </div>
  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field   class="line">
      <input type="number" min="1900" max="2500" matInput formControlName="firstYearFrom" placeholder="First Year From">
    </mat-form-field>
    <mat-slide-toggle formControlName="useFirstYearFrom"></mat-slide-toggle>
    <mat-form-field   class="line">
      <input type="number" min="1900" max="2500" matInput formControlName="firstYearTo" placeholder="First Year To">
    </mat-form-field>
    <mat-slide-toggle formControlName="useFirstYearTo"></mat-slide-toggle>
  </div>

  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field   class="line">
      <input type="number" min="1900" max="2500" matInput formControlName="lastYearFrom" placeholder="Last Year From">
    </mat-form-field>
    <mat-slide-toggle formControlName="useLastYearFrom"></mat-slide-toggle>
    <mat-form-field   class="line">
      <input type="number" min="1900" max="2500" matInput formControlName="lastYearTo" placeholder="Last Year To">
    </mat-form-field>
    <mat-slide-toggle formControlName="useLastYearTo"></mat-slide-toggle>
  </div>
  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <mat-label>OBA Membership Category</mat-label>
      <mat-select formControlName="membershipCategory">
        <mat-option *ngFor="let cat of membershipcategories" [value]="cat">{{cat}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useMembershipCategory"></mat-slide-toggle>
    <mat-form-field>
      <mat-label>Renewed To Date</mat-label>
      <input matInput [matDatepicker]="picker" formControlName="renewedToDate"  (keydown)="onKeydown($event)">
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
    <mat-slide-toggle formControlName="useRenewedToDate"></mat-slide-toggle>
  </div>
  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <mat-label>Record Type</mat-label>
      <mat-select formControlName="category">
        <mat-option *ngFor="let category of categories" [value]="category">{{category}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useCategory"></mat-slide-toggle>
    <mat-form-field  class="line">
      <mat-label>Keyword</mat-label>
      <mat-select formControlName="keyword">
        <mat-option *ngFor="let keyword of keywords" [value]="keyword">{{keyword}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useKeyword"></mat-slide-toggle>
  </div>
  <div fxLayout="row"  fxLayoutAlign="start center"  fxLayoutGap="12px">
    <mat-form-field  class="line">
      <mat-label>Capacity</mat-label>
      <mat-select formControlName="capacitycategory">
        <mat-option *ngFor="let category of capacitycategories" [value]="category">{{category}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useCapacitycategory"></mat-slide-toggle>
    <mat-form-field  class="line">
      <mat-label>Relationship</mat-label>
      <mat-select formControlName="relationshipcategory">
        <mat-option *ngFor="let relationship of relationshipcategories" [value]="relationship">{{relationship}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-slide-toggle formControlName="useRelationshipcategory"></mat-slide-toggle>
  </div>

  </div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog(true)"  color="warn" [disabled]="filterName.invalid">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial [disabled]="filterName.invalid" color="primary">Apply</button>
</div>
