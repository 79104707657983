import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import {AuthService} from '../auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  @Output() loginSuccess = new EventEmitter();

  constructor(
    private auth: AuthService,
    private router: Router) { }

  ngOnInit() {
    if (this.auth.isSignedIn) {
      console.log('navigating to home from login');
      this.router.navigate(['/home']);
    }
  }
  onLoginSuccess() {
    if (this.loginSuccess) {
      console.log('onLoginSuccess in  login');
      this.loginSuccess.emit();
    }
  }
}
