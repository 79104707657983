/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-box.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/platform";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/material/dialog";
import * as i8 from "@angular/common";
import * as i9 from "./message-box.component";
var styles_MessageBoxComponent = [i0.styles];
var RenderType_MessageBoxComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageBoxComponent, data: {} });
export { RenderType_MessageBoxComponent as RenderType_MessageBoxComponent };
function View_MessageBoxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["mat-button", ""]], [[8, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectedAnswer(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i3.MatButton, [i1.ElementRef, i4.Platform, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i1.ɵted(2, 0, ["", ""]))], null, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); var currVal_2 = _v.context.$implicit; _ck(_v, 2, 0, currVal_2); }); }
export function View_MessageBoxComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "h1", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i1.ɵdid(1, 81920, null, 0, i7.MatDialogTitle, [[2, i7.MatDialogRef], i1.ElementRef, i7.MatDialog], null, null), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "mat-dialog-content"], ["mat-dialog-content", ""]], null, null, null, null, null)), i1.ɵdid(4, 16384, null, 0, i7.MatDialogContent, [], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "mat-dialog-actions"], ["mat-dialog-actions", ""]], null, null, null, null, null)), i1.ɵdid(8, 16384, null, 0, i7.MatDialogActions, [], null, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBoxComponent_1)), i1.ɵdid(10, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_3 = _co.answers; _ck(_v, 10, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.text; _ck(_v, 6, 0, currVal_2); }); }
export function View_MessageBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-message-box", [], null, null, null, View_MessageBoxComponent_0, RenderType_MessageBoxComponent)), i1.ɵdid(1, 114688, null, 0, i9.MessageBoxComponent, [i7.MatDialogRef, i7.MAT_DIALOG_DATA], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MessageBoxComponentNgFactory = i1.ɵccf("app-message-box", i9.MessageBoxComponent, View_MessageBoxComponent_Host_0, {}, {}, []);
export { MessageBoxComponentNgFactory as MessageBoxComponentNgFactory };
