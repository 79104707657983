/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./register.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/flex-layout/flex";
import * as i3 from "@angular/flex-layout/core";
import * as i4 from "../signup/signup.component.ngfactory";
import * as i5 from "../signup/signup.component";
import * as i6 from "../auth.service";
import * as i7 from "@angular/material/snack-bar";
import * as i8 from "@angular/router";
import * as i9 from "./register.component";
var styles_RegisterComponent = [i0.styles];
var RenderType_RegisterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RegisterComponent, data: {} });
export { RenderType_RegisterComponent as RenderType_RegisterComponent };
export function View_RegisterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 13, "div", [["class", "main-background"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 12, "div", [["class", "main-div"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["fxLayout", "column"], ["fxLayoutAlign", "center center"]], null, null, null, null, null)), i1.ɵdid(3, 671744, null, 0, i2.DefaultLayoutDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutStyleBuilder], i3.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), i1.ɵdid(4, 671744, null, 0, i2.DefaultLayoutAlignDirective, [i1.ElementRef, i3.StyleUtils, [2, i2.LayoutAlignStyleBuilder], i3.MediaMarshaller], { fxLayoutAlign: [0, "fxLayoutAlign"] }, null), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "a", [["class", "text-center message"], ["href", "http://www.wbhsoba.co.nz/"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whangarei Boys High School Old Boys Association (Inc)"])), (_l()(), i1.ɵeld(8, 0, null, null, 3, "p", [["class", "message register"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Register your details with WBHSOBA."])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" All details will be treated as confidential and will not be shared."])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "app-signup", [], null, [[null, "loginSuccess"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("loginSuccess" === en)) {
        var pd_0 = (_co.onLoginSuccess() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SignupComponent_0, i4.RenderType_SignupComponent)), i1.ɵdid(13, 114688, null, 0, i5.SignupComponent, [i6.AuthService, i7.MatSnackBar, i8.Router], null, null)], function (_ck, _v) { var currVal_0 = "column"; _ck(_v, 3, 0, currVal_0); var currVal_1 = "center center"; _ck(_v, 4, 0, currVal_1); _ck(_v, 13, 0); }, null); }
export function View_RegisterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-register", [], null, null, null, View_RegisterComponent_0, RenderType_RegisterComponent)), i1.ɵdid(1, 114688, null, 0, i9.RegisterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegisterComponentNgFactory = i1.ɵccf("app-register", i9.RegisterComponent, View_RegisterComponent_Host_0, {}, {}, []);
export { RegisterComponentNgFactory as RegisterComponentNgFactory };
