import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";

@Component({
  selector: 'app-payment-area-edit-dialog',
  templateUrl: './payment-area-edit-dialog.component.html',
  styleUrls: ['./payment-area-edit-dialog.component.css']
})
export class PaymentAreaEditDialogComponent implements OnInit {
  id = null;
  oldName = null;
  paymentArea = new FormGroup({
    name: new FormControl(),
    allowSendReceipt: new FormControl(),
    active: new FormControl()
  });

  constructor(public dialogRef: MatDialogRef<PaymentAreaEditDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    if (data.area) {
      this.id = data.area.id;
      this.paymentArea.patchValue(data.area);
      this.oldName = data.area.name;
    }
  }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public submitDialog(remove): void {
    if (remove) {
      const result = this.paymentArea.value;
      if (this.id) {
        result.id = this.id;
      }
      result.deleted = true;
      this.dialogRef.close(result);
    } else {
      const result = this.paymentArea.value;
      if (this.id) {
        result.id = this.id;
      }
      this.dialogRef.close(result);
    }

    
  }

  isValid() {
    return this.paymentArea.get('name').value;
  }
}
