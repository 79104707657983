import { Component, OnInit } from '@angular/core';
import {Activity, Category, Keyword, OldboysService} from "../oldboys.service";
import {MatDialog, MatTableDataSource} from "@angular/material";
import {KeywordEditDialogComponent} from "../keyword-edit-dialog/keyword-edit-dialog.component";
import {CategoryEditDialogComponent} from "../category-edit-dialog/category-edit-dialog.component";
import {ActivityEditDialogComponent} from "../activity-edit-dialog/activity-edit-dialog.component";
import { SubscriptionCategoryEditDialogComponent } from '../subscription-category-edit-dialog/subscription-category-edit-dialog.component';
import { PaymentMethodEditDialogComponent } from '../payment-method-edit-dialog/payment-method-edit-dialog.component';
import { PaymentAreaEditDialogComponent } from '../payment-area-edit-dialog/payment-area-edit-dialog.component';
import { PaymentProjectEditDialogComponent } from '../payment-project-edit-dialog/payment-project-edit-dialog.component';

@Component({
  selector: 'app-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})
export class MaintenanceComponent implements OnInit {

  categoriesDataSource = new MatTableDataSource<Category>();
  keywordsDataSource = new MatTableDataSource<Keyword>();
  activitiesDataSource = new MatTableDataSource<Activity>();
  subscriptionCategoriesDataSource = new MatTableDataSource<string>();
  paymentMethodsDataSource = new MatTableDataSource<string>();
  paymentAreasDataSource = new MatTableDataSource<string>();
  paymentProjectsDataSource = new MatTableDataSource<string>();


  categoriesDisplayedColumns: string[] = ['name'];
  keywordsDisplayedColumns: string[] = ['name'];
  activitiesDisplayedColumns: string[] = ['name'];
  subscriptionCategoriesDisplayedColumns: string[] = ['name', "allowSendReceipt"];
  paymentMethodsDisplayedColumns: string[] = ['name'];
  paymentAreasDisplayedColumns: string[] = ['name', "allowSendReceipt", 'active'];
  paymentProjectsDisplayedColumns: string[] = ['name', "allowSendReceipt", 'active'];

  loading = false;

  constructor(private oldboysService: OldboysService,
              public dialog: MatDialog) { }

  ngOnInit() {
    this.loading = true;

    this.oldboysService.getActivities().subscribe(activities => {
      this.activitiesDataSource.data = activities;
      this.oldboysService.getCategories().subscribe(categories => {
        this.categoriesDataSource.data = categories;
        this.oldboysService.getKeywords().subscribe(keywords => {
          this.keywordsDataSource.data = keywords;
          this.oldboysService.getActiveSubscriptionCategory().subscribe(scategories => {
            this.subscriptionCategoriesDataSource.data = scategories;
            this.oldboysService.getActivePaymentMethod().subscribe(methods => {
              this.paymentMethodsDataSource.data = methods;
              this.oldboysService.getActivePaymentArea().subscribe(areas => {
                this.paymentAreasDataSource.data = areas;
                this.oldboysService.getActivePaymentProject().subscribe(projects => {
                  this.paymentProjectsDataSource.data = projects;
                  this.loading = false;
                });
              });
            });
          });
        });
      });
    });
  }

  onAddKeyword() {
    const dialogRef = this.dialog.open(KeywordEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addKeyword(result).subscribe(keyword => {
          this.oldboysService.getKeywords().subscribe(ks => {
            this.keywordsDataSource.data = ks;
          });
        });
      }
    });
  }

  onKeywordRowClicked(event){
    const dialogRef = this.dialog.open(KeywordEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { keyword: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          this.oldboysService.deleteKeyword(result.id).subscribe(keyword => {
            this.oldboysService.getKeywords().subscribe(ks => {
              this.keywordsDataSource.data = ks;
            });
          });
        } else {
          this.oldboysService.updateKeyword(result).subscribe(keyword => {
            this.oldboysService.getKeywords().subscribe(ks => {
              this.keywordsDataSource.data = ks;
            });
          });
        }
      }
    });
  }

  onAddCategory() {
    const dialogRef = this.dialog.open(CategoryEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addCategory(result).subscribe(category => {
          this.oldboysService.getCategories().subscribe(ks => {
            this.categoriesDataSource.data = ks;
          });
        });
      }
    });
  }

  onCategoryRowClicked(event){
    const dialogRef = this.dialog.open(CategoryEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { category: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          this.oldboysService.deleteCategory(result.id).subscribe(category => {
            this.oldboysService.getCategories().subscribe(categories => {
              this.categoriesDataSource.data = categories;
            });
          });
        } else {
          this.oldboysService.updateCategory(result).subscribe(category => {
            this.oldboysService.getCategories().subscribe(categories => {
              this.categoriesDataSource.data = categories;
            });
          });
        }
      }
    });
  }

  onAddActivity() {
    const dialogRef = this.dialog.open(ActivityEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addActivity(result).subscribe(activity => {
          this.oldboysService.getActivities().subscribe(ks => {
            this.activitiesDataSource.data = ks;
          });
        });
      }
    });
  }

  onActivityRowClicked(event){
    const dialogRef = this.dialog.open(ActivityEditDialogComponent, {
      //height: '400px',
      width: '600px',
      data: { activity: event}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
          this.oldboysService.deleteActivity(result.id).subscribe(activity => {
            this.oldboysService.getActivities().subscribe(activities => {
              this.activitiesDataSource.data = activities;
            });
          });
        } else {
          this.oldboysService.updateActivity(result).subscribe(activity => {
            this.oldboysService.getActivities().subscribe(activities => {
              this.activitiesDataSource.data = activities;
            });
          });
        }
      }
    });
  }

  onAddSubscriptionCategory() {
    const dialogRef = this.dialog.open(SubscriptionCategoryEditDialogComponent, {
      width: '600px',
      data: {} // Pass any required data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addSubscriptionCategory(result).subscribe(sc => {
          this.oldboysService.getActiveSubscriptionCategory().subscribe(scs => {
            this.subscriptionCategoriesDataSource.data = scs;
          });
        });
      }
    });
  }

  onSubscriptionCategoryRowClicked(row) {
    const dialogRef = this.dialog.open(SubscriptionCategoryEditDialogComponent, {
      width: '600px',
      data: { category: row } // Pass the clicked row data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
      
          
          this.oldboysService.deleteSubscriptionCategory(result).subscribe(res => {
      
            this.oldboysService.getActiveSubscriptionCategory().subscribe(res => {
              this.subscriptionCategoriesDataSource.data = res;
             
            });
          });
        } else {
          this.oldboysService.updateSubscriptionCategory(result).subscribe(sc => {
            this.oldboysService.getActiveSubscriptionCategory().subscribe(scs => {
              this.subscriptionCategoriesDataSource.data = scs;
            });
          });
        }
      }
    });
  }

  onAddPaymentArea() {
    const dialogRef = this.dialog.open(PaymentAreaEditDialogComponent, {
      width: '600px',
      data: {} // Pass any required data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addPaymentArea(result).subscribe(sc => {
          this.oldboysService.getActivePaymentArea().subscribe(scs => {
            this.paymentAreasDataSource.data = scs;
          });
        });
      }
    });
  }

  onPaymentAreaRowClicked(row) {
    const dialogRef = this.dialog.open(PaymentAreaEditDialogComponent, {
      width: '600px',
      data: { area: row } // Pass the clicked row data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
      
          
          this.oldboysService.deletePaymentArea(result).subscribe(res => {
      
            this.oldboysService.getActivePaymentArea().subscribe(res => {
              this.paymentAreasDataSource.data = res;
             
            });
          });
        } else {
          this.oldboysService.updatePaymentArea(result).subscribe(sc => {
            this.oldboysService.getActivePaymentArea().subscribe(scs => {
              this.paymentAreasDataSource.data = scs;
            });
          });
        }
      }
    });
  }

  onAddPaymentProject() {
    const dialogRef = this.dialog.open(PaymentProjectEditDialogComponent, {
      width: '600px',
      data: {} // Pass any required data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addPaymentProject(result).subscribe(sc => {
          this.oldboysService.getActivePaymentProject().subscribe(scs => {
            this.paymentProjectsDataSource.data = scs;
          });
        });
      }
    });
  }

  onPaymentProjectRowClicked(row) {
    const dialogRef = this.dialog.open(PaymentProjectEditDialogComponent, {
      width: '600px',
      data: { project: row } // Pass the clicked row data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {
      
          
          this.oldboysService.deletePaymentProject(result).subscribe(res => {
      
            this.oldboysService.getActivePaymentProject().subscribe(res => {
              this.paymentProjectsDataSource.data = res;
             
            });
          });
        } else {
          this.oldboysService.updatePaymentProject(result).subscribe(sc => {
            this.oldboysService.getActivePaymentProject().subscribe(scs => {
              this.paymentProjectsDataSource.data = scs;
            });
          });
        }
      }
    });
  }

  onAddPaymentMethod() {
    const dialogRef = this.dialog.open(PaymentMethodEditDialogComponent, {
      width: '600px',
      data: {} // Pass any required data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.oldboysService.addPaymentMethod(result).subscribe(pm => {
          this.oldboysService.getActivePaymentMethod().subscribe(pms => {
            this.paymentMethodsDataSource.data = pms;
          });
        });
      }
    });
  }

  onPaymentMethodRowClicked(row) {
    const dialogRef = this.dialog.open(PaymentMethodEditDialogComponent, {
      width: '600px',
      data: { method: row } // Pass the clicked row data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.delete) {

          this.oldboysService.deletePaymentMethod(result).subscribe(res => {
            this.oldboysService.getActivePaymentMethod().subscribe(res => {
              this.paymentMethodsDataSource.data = res;
            });
          });
        } else {
          this.oldboysService.updatePaymentMethod(result).subscribe(pm => {
            this.oldboysService.getActivePaymentMethod().subscribe(pms => {
              this.paymentMethodsDataSource.data = pms;
            });
          });
        }
      }
    });
  }
}
