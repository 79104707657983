import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { Service } from './service';
import { catchError } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var AdminService = /** @class */ (function (_super) {
    tslib_1.__extends(AdminService, _super);
    function AdminService(http) {
        var _this = _super.call(this) || this;
        _this.http = http;
        _this.endpoint = _this.getEndpoint('admin');
        return _this;
    }
    AdminService.prototype.getUsers = function () {
        return this.http.get(this.endpoint + '/users').pipe(catchError(this.handleError('users', [])));
    };
    AdminService.prototype.updateDetails = function (id, params) {
        return this.http.put(this.endpoint + '/users/' + id, params).pipe(catchError(this.handleError('update user', [])));
    };
    AdminService.prototype.invite = function (name, email, type) {
        return this.http.post(this.endpoint + '/users', { name: name, email: email, type: type }).pipe(catchError(this.handleError('update user', [])));
    };
    AdminService.prototype.putUserDetails = function (id, data) {
        return this.http.put(this.endpoint + '/users/' + id + '/details', data).pipe(catchError(this.handleError('user', [])));
    };
    AdminService.prototype.deleteUser = function (id) {
        return this.http.delete(this.endpoint + '/users/' + id).pipe(catchError(this.handleError('user', [])));
    };
    AdminService.ngInjectableDef = i0.defineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.inject(i1.HttpClient)); }, token: AdminService, providedIn: "root" });
    return AdminService;
}(Service));
export { AdminService };
