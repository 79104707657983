<h1 mat-dialog-title *ngIf="!id">Add new keyword</h1>
<h1 mat-dialog-title *ngIf="id">Editing {{keyword.get('name').value}}</h1>
<div mat-dialog-content fxLayout="column" [formGroup]="keyword">

  <mat-form-field>
    <input type="text" matInput formControlName="name" [matAutocomplete]="auto" placeholder="Name">
  </mat-form-field>

  <mat-autocomplete #auto="matAutocomplete">
    <mat-option *ngFor="let keyword of filteredOptions | async" [value]="keyword.name">{{keyword.name}}</mat-option>
  </mat-autocomplete>

</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="closeDialog()" color="primary">Cancel</button>
  <button mat-raised-button (click)="submitDialog(true)" *ngIf="id"  color="warn">Delete</button>
  <button mat-raised-button (click)="submitDialog(false)" cdkFocusInitial [disabled]="!isValid()"  color="primary">Save</button>
</div>
