<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">list</mat-icon>Data maintenance
    </mat-card-title>
    <mat-card-subtitle>
      Maintain categories, keywords and activities
    </mat-card-subtitle>

    <mat-card-content fxLayout="column">

      <mat-tab-group>
        <mat-tab label="Categories">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="categoriesDataSource" *ngIf="!loading">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"  class="clickable"> {{element.name}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="categoriesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: categoriesDisplayedColumns;" (click)="onCategoryRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddCategory()"  type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Keywords">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="keywordsDataSource" *ngIf="!loading">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"  class="clickable"> {{element.name}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="keywordsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: keywordsDisplayedColumns;" (click)="onKeywordRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddKeyword()"  type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Activities">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="activitiesDataSource" *ngIf="!loading">

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element"  class="clickable"> {{element.name}} </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="activitiesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: activitiesDisplayedColumns;" (click)="onActivityRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddActivity()"  type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Subscription Type">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="subscriptionCategoriesDataSource" *ngIf="!loading">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element" class="clickable"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="allowSendReceipt">
                  <th mat-header-cell *matHeaderCellDef> Allow Send Receipt </th>
                  <td mat-cell *matCellDef="let element"> {{ element.allowSendReceipt ? 'Yes' : 'No' }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="subscriptionCategoriesDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: subscriptionCategoriesDisplayedColumns;" (click)="onSubscriptionCategoryRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddSubscriptionCategory()" type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>
        
        <mat-tab label="Payment Methods">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="paymentMethodsDataSource" *ngIf="!loading">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element" class="clickable"> {{element.name}} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="paymentMethodsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentMethodsDisplayedColumns;" (click)="onPaymentMethodRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddPaymentMethod()" type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Donation/Payment Area">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="paymentAreasDataSource" *ngIf="!loading">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element" class="clickable"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="allowSendReceipt">
                  <th mat-header-cell *matHeaderCellDef> Allow Send Receipt </th>
                  <td mat-cell *matCellDef="let element"> {{ element.allowSendReceipt ? 'Yes' : 'No' }} </td>
                </ng-container>
                <ng-container matColumnDef="active">
                  <th mat-header-cell *matHeaderCellDef> Active </th>
                  <td mat-cell *matCellDef="let element"> {{ element.active ? 'Yes' : 'No' }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="paymentAreasDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentAreasDisplayedColumns;" (click)="onPaymentAreaRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddPaymentArea()" type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>

        <mat-tab label="Donation/Payment Project">
          <div fxLayout="row">
            <div fxFlex="90%">
              <mat-spinner *ngIf="loading"></mat-spinner>
              <table mat-table [dataSource]="paymentProjectsDataSource" *ngIf="!loading">
                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Name </th>
                  <td mat-cell *matCellDef="let element" class="clickable"> {{element.name}} </td>
                </ng-container>
                <ng-container matColumnDef="allowSendReceipt">
                  <th mat-header-cell *matHeaderCellDef> Allow Send Receipt </th>
                  <td mat-cell *matCellDef="let element"> {{ element.allowSendReceipt ? 'Yes' : 'No' }} </td>
                </ng-container>
                <ng-container matColumnDef="active">
                  <th mat-header-cell *matHeaderCellDef> Active </th>
                  <td mat-cell *matCellDef="let element"> {{ element.active ? 'Yes' : 'No' }} </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="paymentProjectsDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: paymentProjectsDisplayedColumns;" (click)="onPaymentProjectRowClicked(row)"></tr>
              </table>
            </div>
            <div fxFlex="10%" fxLayoutAlign="end">
              <button mat-mini-fab color="accent" (click)="onAddPaymentProject()" type="button"><mat-icon>add</mat-icon></button>
            </div>
          </div>
        </mat-tab>

      </mat-tab-group>


    </mat-card-content>
  </mat-card>
</div>
