<div class="main-background">
  <div class="main-div">
    <div fxLayout="column" fxLayoutAlign="center center">
      <h1 class="text-center">
        <a class="text-center message" href="http://www.wbhsoba.co.nz/">Whangarei Boys High School Old Boys Association (Inc)</a>
      </h1>
      <p class="message register">Register your details with WBHSOBA.<br> All details will be treated as confidential and will not be shared.</p>
      <app-signup (loginSuccess)="onLoginSuccess()"></app-signup>
    </div>
  </div>
</div>
