<div fxLayout="column" fxLayoutAlign="center">
  <mat-card class="table-card">
    <mat-card-title>
      <mat-icon inline="true">settings</mat-icon>Report management
    </mat-card-title>
    <mat-card-subtitle>
      This is an admin only functionality to define and manage report
    </mat-card-subtitle>
    <mat-card-content>

    </mat-card-content>
  </mat-card>
</div>
