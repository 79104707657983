import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../user.service';
import {map, startWith} from 'rxjs/operators';
import {PublicService, Student} from '../public.service';
import {MatDialog, MatSnackBar, MatTableDataSource} from '@angular/material';
import {MembershipRequestService} from '../membership-request.service';
import {SubmitInfoDialogComponent} from '../submit-info-dialog/submit-info-dialog.component';

@Component({
  selector: 'app-claimrecord',
  templateUrl: './claimrecord.component.html',
  styleUrls: ['./claimrecord.component.css']
})
export class ClaimrecordComponent implements OnInit {

  loading = false;
  subTitle = '';
  isUserAwaitingClaim = false;
  isUserAwaitingSubmission = false;
  dataSource = new MatTableDataSource<Student>();
  displayedColumns: string[] = ['name', 'years', 'validated', 'wbhsOldBoy', 'deceased', 'emailOnFile', 'fill'];

  @ViewChild('dialogRef') dialogRef: TemplateRef<any>;
  @ViewChild('contactAdministratorDialogRef') contactAdministratorDialogRef: TemplateRef<any>;

  constructor(private userService: UserService,
              private publicService: PublicService,
              private membershipRequestService: MembershipRequestService,
              public snackBar: MatSnackBar,
              private dialog: MatDialog) { }

  ngOnInit() {
    const currUser = this.userService.getCurrentUser();

    this.userService.retrieveCurrentUser().subscribe(cu => {
      if (!cu.prospectId) {
        this.membershipRequestService.getUserActiveClaimRequests().subscribe(cr => {
          this.isUserAwaitingClaim = this.membershipRequestService.isUserAwaitingClaim(cr);
          this.isUserAwaitingSubmission = this.membershipRequestService.isUserAwaitingSubmission(cr);
          if (this.isUserAwaitingClaim) {
            this.subTitle = 'Your request for claiming your school record is being reviewed.';
          } else if (this.isUserAwaitingSubmission) {
            this.subTitle = 'You have contacted the administrator and your request is being reviewed.';
          } else {
            const startYear = (currUser.data && currUser.data.fromYear ? currUser.data.fromYear : '');
            if (startYear) {
              this.loading = true;
              this.publicService.getYearStudents(startYear).subscribe(students => {
                this.dataSource.data = students;
                this.subTitle = `We are displaying all school records for you start year (${startYear}). If you can find your record in the results please claim it by clicking on your name, otherwise click the Contact Administrator button on the right hand side.`;
                this.loading = false;
              });
            } else {
              this.loading = false;
            }
           }
        });
      }
    });
  }

  canClaimRecord(currRow) {
    if (currRow.mappedUser) {
      return false;
    }
    const currUser = this.userService.getCurrentUser();
    return (currUser && (!currUser.prospectId && !this.isUserAwaitingClaim));
  }

  claimIdentity(row) {
    this.dialog.open(this.dialogRef).afterClosed()
      .subscribe(result => {
        if (result) {
          this.membershipRequestService.createIdentityClaimRequest(row)
            .subscribe((res) => {
              this.snackBar.open(res.msg, 'Dismiss', {
                duration: 10000,
              });
              this.ngOnInit();
            });
        }
      });
  }

  contactAdministrator() {
    this.dialog.open(this.contactAdministratorDialogRef).afterClosed()
      .subscribe(result => {
        if (result) {
          const currUser = this.userService.getCurrentUser();
          const dialogOldBoy = this.dialog.open(SubmitInfoDialogComponent, {
            maxHeight: '100vh',
            height: '100%',
            data: {
              emailaddress: currUser.email,
              firstname : currUser.data.firstName,
              lastname : currUser.data.lastName,
              birthdate : currUser.data.dateOfBirth,
              firstyear : currUser.data.fromYear,
              lastyear : currUser.data.toYear,
              country: currUser.data.country,
              town_city: currUser.data.town,
              housename: currUser.data.house,
              suburb: currUser.data.suburb,
              postalcode: currUser.data.postcode,
              province_state: currUser.data.province,
              hometelephone: currUser.data.homePhone,
              worktelephone: currUser.data.workPhone,
              mobiletelephone: currUser.data.mobilePhone,
              address1: currUser.data.streetAddress,
            }
          });

          dialogOldBoy.afterClosed().subscribe(result1 => {
            if (result1) {
              this.membershipRequestService.createInformationSubmissionRequest(result1)
                .subscribe((res) => {
                  this.snackBar.open(res.msg, 'Dismiss', {
                    duration: 100000,
                  });
                });
            }
          });
        }
      });
  }

}
