// import { Component, OnInit } from '@angular/core';
// import { MatDialogRef } from '@angular/material/dialog';
// import { FormGroup, FormControl } from '@angular/forms';
import {Component, Inject, OnInit} from '@angular/core';

import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {OldboysService} from "../oldboys.service";
import {validate} from "codelyzer/walkerFactory/walkerFn";
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
function validateNotNone(c: FormControl) {
  return c.value !== 'None' ? null : {
    validateNotNone: {
      valid: false
    }
  };
}

@Component({
  selector: 'app-subscription-filter',
  templateUrl: './subscription-filter.component.html',
  styleUrls: ['./subscription-filter.component.css']
})
export class SubscriptionFilterComponent implements OnInit {

  // filterForm = new FormGroup({
  //   category: new FormControl(''),
  //   paymentMethod: new FormControl('')
  // });

  // constructor(public dialogRef: MatDialogRef<SubscriptionFilterComponent>) {}

  // ngOnInit() {
  // }

  // closeDialog(): void {
  //   this.dialogRef.close();
  // }

  // submitDialog(): void {
  //   const selectedFilters = {
  //     category: this.filterForm.value.category,
  //     paymentMethod: this.filterForm.value.paymentMethod
  //   };
  //   this.dialogRef.close(selectedFilters);
  // }
  subscriptionCategories: string[] = [];
  paymentMethods: string[] = [];
  
  filter = new FormGroup({
    useName: new FormControl(false),
    name: new FormControl(),
    usePayment_method: new FormControl(false),
    payment_method: new FormControl(),
    usePaymentFromDate: new FormControl(false),
    paymentFromDate: new FormControl(),
    usePaymentToDate: new FormControl(false),
    paymentToDate: new FormControl(),
    useSubscription_category: new FormControl(false),
    subscription_category: new FormControl(),


    
    
  });

  // filterName = new FormControl('', [ Validators.minLength(3),
  //                                    validateNotNone,
  //                                    Validators.required ]);

  categories = [];
  keywords = [];
  membershipcategories = [];

  capacitycategories = [
    'A',
    'B',
    'C',
    'D'
  ];

  relationshipcategories = [
    'High',
    'Medium',
    'Low',
  ];

  constructor(public dialogRef: MatDialogRef<FilterDialogComponent>,
              private oldBoysService: OldboysService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {

    
    console.log(this.data);
    // this.filter.patchValue(this.data['filter']);
    // let name = this.data['filterName'];
    // if (name === 'None') {
    //   name = '';
    // }
    // this.filterName.patchValue(name);
    this.oldBoysService.getCategories().subscribe(categories => {
      this.categories = categories.map(a => a.name).sort();
      this.oldBoysService.getKeywords().subscribe(keywords => {
        this.keywords = keywords.map(a => a.name).sort();
        this.oldBoysService.getMembershipCategories().subscribe(cats => {
          console.log(cats);
          this.membershipcategories = cats;
        });
      });
    });
  }

  public closeDialog (): void {
    this.dialogRef.close();
  }

  public submitDialog (del): void {

    const result = {  filter: this.filter.value,
                      // filterName: this.filterName.value,
                      // delete: del
                    };

    //console.log(result);
    this.dialogRef.close(result);
  }

  onKeydown(event) {
    if (event.key === "Enter") {
      event.target.blur();
      //console.log(event);
    }
  }

  loadSubscriptionCategories() {
    this.oldBoysService.getSubscriptionCategories().subscribe(categories => {
      this.subscriptionCategories = categories;
    });
  }

  loadPaymentMethods() {
    this.oldBoysService.getPaymentsMethods().subscribe(methods => {
      this.paymentMethods = methods;
    });
  }

}
